<div class="section-hunt-pix" id="section-hunt-pix">
  <div class="container mx-auto px-4 py-8">
    <div class="w-full">
      <div class="flex flex-row items-center md:justify-center">
        <span
          class="title-yellow font-libel bold text-center text-xl uppercase leading-6"
          >Use sua chance para ganhar prêmios na hora</span
        >
        <img
          class="exclamation"
          src="/assets/img/exclamacao.png"
          alt="Resgate seus códigos." />
      </div>
    </div>

    <div class="w-full flex flex row">
      <div class="flex flex-1 md:justify-center">
        <div class="pos flex flex-col justify-center items-center">
          <img src="/assets/img/slot-v2.png" alt="Jogue agora" />
        </div>
      </div>
      <div class="flex flex-1 flex-col justify-end items-center">
        <div
          class="flex flex-col items-center justify-center py-2 px-4 border border-dotted border-4 rounded-lg mt-4 mb-2 chances-box">
          <span class="bold">Você tem:</span>
          <div class="flex flex-row justify-center items-center -mt-2">
            <span class="mr-1 chances-text text-4xl font-extrabold">{{
              balance?.spins
            }}</span>
            <app-coin-icon sizeClass="w-8"></app-coin-icon>
          </div>
        </div>

        <button routerLink="/slot" class="btn-play" mat-button color="primary">
          JOGAR AGORA
        </button>
      </div>
    </div>
  </div>
</div>
