<div class="header">
  <div class="container mx-auto px-4 pt-2 pb-2">
    <div class="flex flex-row justify-between">
      <div class="flex flex-auto justify-start pl-8 flex-row">
        <app-logo sizeClass="w-20"></app-logo>
      </div>

      <div class="flex flex-row justify-end items-center">
        <div
          routerLink="/editar-perfil"
          class="flex flex-col items-center -mt-4">
          <span class="text-center text-sm font-semibold leading-3">{{
            seller?.displayName
          }}</span>
          <span class="text-center text-xs leading-3" *ngIf="account">{{
            account.name
          }}</span>
        </div>

        <button
          (click)="logout()"
          mat-icon-button
          class="logout-button p-0"
          aria-label="Sair">
          <mat-icon class="w-7 h-7">exit_to_app</mat-icon>
        </button>
      </div>
    </div>

    <div class="flex flex-row items-end justify-between -mt-4">
      <app-diamond-progress-bar></app-diamond-progress-bar>

      <div class="flex flex-col items-center chances-box">
        <span class="uppercase bold">Chances</span>
        <small class="uppercase bold text-xs">Caça Pix</small>

        <div class="flex flex-row justify-center items-center -mt-2">
          <span class="mr-1 text-4xl bold">{{ balance?.spins }}</span>
          <app-coin-icon sizeClass="w-7"></app-coin-icon>
        </div>
      </div>
    </div>
  </div>
</div>
