import { SlotSymbolEnum } from '../../../../graphql/generated';
import { SlotSymbols } from './slot-symbols';
import { Slot } from './slot';

export class SlotControl {
  private completed = 0;
  private animationTime = 2500;
  private audio!: HTMLAudioElement;
  private a!: Slot;
  private b!: Slot;
  private c!: Slot;

  load() {
    console.log('SlotControl::load()');
    this.audio = new Audio('static/media/slot.mp3');

    this.a = new Slot('#slot1', 30, 1);
    this.b = new Slot('#slot2', 45, 2);
    this.c = new Slot('#slot3', 70, 3);

    const done = () => this.completed++;

    this.a.onCompleted = done;
    this.b.onCompleted = done;
    this.c.onCompleted = done;
  }

  public onInit: () => void = () => {};

  public onCompleted: () => void = () => {};

  /**
   * Main spin method
   */
  spin(r1: number, r2: number, r3: number) {
    this.completed = 0;
    this.onInit();
    this.playSound();

    const intervalId = window.setInterval(() => {
      if (
        this.a.speed === 0 &&
        this.b.speed === 0 &&
        this.c.speed === 0 &&
        this.completed === 3
      ) {
        clearInterval(intervalId);
        this.onCompleted();
      }
    }, 300);

    // Set a timer to wait the start sound to finish
    window.setTimeout(() => {
      this.a.start();
      this.b.start();
      this.c.start();

      // Stop spinning after 5000 ms
      setTimeout(() => {
        this.a.stop(r1);
        this.b.stop(r2);
        this.c.stop(r3);
      }, this.animationTime);
    }, 1000);
  }

  spinSymbol(r1: SlotSymbolEnum, r2: SlotSymbolEnum, r3: SlotSymbolEnum) {
    this.spin(
      SlotSymbols.position(r1),
      SlotSymbols.position(r2),
      SlotSymbols.position(r3)
    );
  }

  /**
   * Set all reels to initial state
   */
  setInitialPos() {
    console.log('SlotControl::setInitialPos()');
    this.a.reset();
    this.b.reset();
    this.c.reset();
  }

  /**
   * Set all reels to desired state
   */
  setFinalPos(r1: number, r2: number, r3: number) {
    console.log('SlotControl::setFinalPos()', r1, r2, r3);
    this.a.finalPos(r1);
    this.b.finalPos(r2);
    this.c.finalPos(r3);
  }

  playSound() {
    // Stop and rewind the sound if it already happens to be playing.
    this.audio.pause();
    this.audio.currentTime = 0;

    // Play the sound.
    this.audio.play();
  }

  setSounds(con: boolean) {
    console.log('SlotControl::setSounds()', con);
    if (this.audio) {
      this.audio.volume = con ? 1 : 0;
    }
  }
}
