import { map, Subject, takeUntil, timer } from 'rxjs';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Event, NavigationStart, Router, RouterLink } from '@angular/router';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';
import { AppJackpot, JackpotStatusEnum } from '../../../graphql/generated';
import { TicketMachineComponent } from '../components/ticket-machine/ticket-machine.component';
import { TicketMachineControl } from '../components/ticket-machine/ticket-machine.control';
import { EmptyTicketMessageComponent } from '../components/empty-ticket-message/empty-ticket-message.component';
import { WinnerJackpotMessageComponent } from '../components/winner-jackpot-message/winner-jackpot-message.component';
import { SectionLastWinnerComponent } from '../components/section-last-winner/section-last-winner.component';
import { SectionTermsAndConditionsComponent } from '../components/section-terms-and-conditions/section-terms-and-conditions.component';
import { SoundSettingsComponent } from '../components/sound-settings/sound-settings.component';
import { AppStateService } from '../core/services/app-state.service';
import { ApiService } from '../core/services/api.service';
import { sleep } from '../core/utils/helpers';
import { GameControl } from './chest/game.control';
import { ToolbarComponent } from '../components/toolbar/toolbar.component';

declare const confetti: any;

@Component({
  selector: 'app-jackpot',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    RouterLink,
    TicketMachineComponent,
    EmptyTicketMessageComponent,
    WinnerJackpotMessageComponent,
    SectionLastWinnerComponent,
    SectionTermsAndConditionsComponent,
    SoundSettingsComponent,
    ToolbarComponent,
  ],
  templateUrl: './jackpot.component.html',
  styleUrls: ['./jackpot.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class JackpotComponent implements OnInit, OnDestroy {
  public showTicketMachine = true;
  public lossMessage = '';
  public tickets = 0;
  public loading = false;
  public playing = false;
  public isWinner = false;
  public amountWinner = 0;
  public amount: number | null = null;
  public startDate: Date | null = null;
  public pageState: 'OPEN' | 'CLOSED' | 'COMPLETED' = 'CLOSED';
  public buttonPlayText: string | null = 'JOGAR AGORA';

  private readonly audioTry = new Audio();
  private readonly audioWon = new Audio();
  private readonly audioLoss = new Audio();
  private readonly audioTicket = new Audio();
  private readonly unsubscribe$ = new Subject<void>();
  private readonly gameControl = new GameControl();
  private readonly ticketControl = new TicketMachineControl();

  constructor(
    private router: Router,
    private stateService: AppStateService,
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.audioTry.src = 'static/media/jackpot-try.mp3';
    this.audioTry.load();

    this.audioWon.src = 'static/media/jackpot-won.mp3';
    this.audioWon.load();

    this.audioLoss.src = 'assets/media/loss.mp3';
    this.audioLoss.load();

    this.audioTicket.src = 'assets/media/ticket.mp3';
    this.audioTicket.load();

    // this.tickets = this.stateService.balanceValue.tickets;
    // if (!this.tickets) {
    //     this._hiddenTicketMachine();
    //     this._hiddenChestAnimation();
    // }

    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: Event) => {
        if (
          event instanceof NavigationStart &&
          event.url !== '/jackpot' &&
          (this.playing || this.loading)
        ) {
          confetti.stop();
          window.location.href = event.url;
        }
      });

    this.stateService.settings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(settings => {
        this.audioTry.volume = settings.soundOn ? 1 : 0;
        this.audioWon.volume = settings.soundOn ? 1 : 0;
        this.audioLoss.volume = settings.soundOn ? 1 : 0;
        this.audioTicket.volume = settings.soundOn ? 1 : 0;
      });

    this.stateService.currentJackpot$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(jackpot => {
        if (!jackpot) {
          this.pageState = 'COMPLETED';
          return;
        }

        this.pageState =
          jackpot.status === JackpotStatusEnum.Rewarding ? 'OPEN' : 'CLOSED';

        this.buttonPlayText = 'JOGAR AGORA';

        this.amount = jackpot.amount;

        this.startDate = jackpot.endAt.iso ? new Date(jackpot.endAt.iso) : null;
      });

    this.ticketControl.setTickets(this.stateService.balanceValue.tickets);

    this.stateService.balance$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(balance => {
        console.log('Update balance ticket', balance.tickets);
        this.tickets = balance.tickets;
        if (!balance.tickets) {
          setTimeout(() => {
            this._hiddenTicketMachine();
            this._hiddenChestAnimation();
          }, 300);
        }
        // nao colocar o this.ticketControl.setTickets(balance.tickets) aqui pois a animação quebra
      });
    if (environment.enableDemoMode) {
      this._devMode();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  play() {
    this.buttonPlayText = 'CARREGANDO...';
    this.loading = true;

    this.apiService.spinJackpot().subscribe({
      next: result => {
        this.buttonPlayText = 'JOGANDO...';
        this.loading = false;
        this._initAnimations();

        if (result?.won) {
          this.amountWinner = result.amountPix || 0;
          this._playWon();
        } else {
          this._playLoss();
        }
      },
      error: e => {
        console.error('SPIN JACKPOT ERROR', e);
        this.loading = false;
        this.snackBar.open(e.message, '', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      },
    });
  }

  /**
   * Permite simular o jackpot em modo de desenvolvimento para testar todos os cenários
   */
  private _devMode() {
    const jackpot = { ...this.stateService.currentJackpotValue };
    if (!jackpot) {
      return;
    }

    jackpot.status = JackpotStatusEnum.Rewarding;
    jackpot.amount = 198.99;
    jackpot.endAt = { iso: '2024-08-10T10:30:00.000Z' };
    this.stateService.incrementBalance({ tickets: 2 }); // Adiciona +2 bilhetes para teste
    this.stateService.setCurrentJackpot(jackpot as AppJackpot);

    // sobrescreve o serviço de spinJackpot para simular resultados
    this.apiService.spinJackpot = () => {
      return timer(1000).pipe(
        map(() => ({ won: false, amountPix: 58.47 })) as any
      );
    };

    // SIMULAR sem jackpot cadastrado
    // this.stateService.setCurrentJackpot(null);
    // this.stateService.setPrevJackpot(null);
  }

  private _initAnimations() {
    this.playing = true;
    this.buttonPlayText = 'JOGANDO...';
    this.lossMessage = '';

    this.audioTicket
      .play()
      .then(() => sleep(500))
      .then(() => this.audioTry.play());

    this.ticketControl.start();

    this.stateService.incrementBalance({ tickets: -1 });
  }

  private _stopAnimationLoss() {
    this.gameControl.stop();
    this.audioTry.pause();
    this.playing = false;
    this.buttonPlayText = 'JOGAR AGORA';
  }

  private _stopAnimationWon() {
    this.gameControl.stopWin();
    this.audioWon.pause();
    this.playing = false;
    this.buttonPlayText = 'JOGAR AGORA';
  }

  private _playLoss() {
    setTimeout(() => {
      this.gameControl.playChestJumping();
    }, 300);

    setTimeout(() => {
      this.audioLoss.play();
      this._stopAnimationLoss();
      console.log('TICKETS', this.tickets);

      if (this.tickets > 1) {
        this.lossMessage =
          'Que pena, não foi desta vez! Você ainda tem bilhetes dourados, tente outra vez!';
      } else if (this.tickets === 1) {
        this.lossMessage =
          'Que pena, não foi desta vez! Você ainda tem um bilhete dourado, tente outra vez!';
      } else {
        this.lossMessage = '';
        this._hiddenTicketMachine();
        this._hiddenChestAnimation();
      }

      this.buttonPlayText = 'JOGAR AGORA!';
      this.loading = false;
    }, 2950);
  }

  private _playWon() {
    // INICIA A ANIMAÇÃO
    setTimeout(() => {
      this.gameControl.playWin();
    }, 300);

    // QUANDO O BAÚ ABRE
    setTimeout(() => {
      this._hiddenTicketMachine();
      this.audioWon.play();
      confetti.start();
      this.isWinner = true;
      this.loading = true;
    }, 2200);

    // ENCERRA ANIMAÇÃO DO BAÚ
    setTimeout(() => {
      this._stopAnimationWon();
    }, 4500);
  }

  private _hiddenTicketMachine() {
    this.showTicketMachine = false;
    const canvasBox = document.getElementById('boxTicketMachine');

    if (canvasBox) {
      canvasBox.style.visibility = 'hidden';
      canvasBox.style.height = '0';
    }
  }

  private _hiddenChestAnimation() {
    this.showTicketMachine = false;
    const chest = document.getElementById('boxChestAnimation');

    if (chest) {
      chest.style.visibility = 'hidden';
      chest.style.height = '0';
    }
  }
}
