<div class="play-component">
  <app-toolbar title="CAÇA Pix!">
    <app-sound-settings />
  </app-toolbar>

  <div
    [ngClass]="{
      visible: pageState !== 'UNAVAILABLE',
      'invisible h-0': pageState === 'UNAVAILABLE'
    }"
    class="mx-auto flex flex-col items-center play-container">
    <div class="p-4 pt-32" #scrollSlot>
      <span
        id="initGame"
        class="title-section text-center w-full max-w-80 text-center font-libel text-lg block"
        >Teste sua sorte e tenha a chance de ganhar prêmios através do Pix.
        Clique em <strong>JOGAR AGORA</strong>!</span
      >
    </div>

    <div class="app-slot-container flex flex-row items-center justify-center">
      <app-slot></app-slot>
    </div>

    <div
      class="flex flex-row justify-end items-end w-full px-4 -mt-8 -mb-2 z-10">
      <app-chances-tag [chances]="spins"></app-chances-tag>
    </div>

    <div class="flex flex-row items-end justify-center w-full h-12 mt-2">
      <button
        class="btn-play w-64 animate-bounce"
        [ngClass]="{ 'animate-bounce': pageState === 'READY' }"
        mat-button
        (click)="spin()"
        [disabled]="pageState !== 'READY'">
        <mat-icon *ngIf="pageState === 'LOADING'" class="animate-spin"
          >refresh
        </mat-icon>
        <mat-icon *ngIf="pageState === 'READY' || pageState === 'FINISH_RESULT'"
          >play_circle
        </mat-icon>
        <mat-icon
          *ngIf="pageState === 'SLOT' || pageState === 'WHEEL'"
          class="animate-spin"
          >casino
        </mat-icon>
        {{ buttonPlayText }}
      </button>
    </div>

    <div
      class="flex flex-row items-center justify-center h-16 w-full max-w-80 px-4 z-10 leading-4 relative">
      <div style="width: 33px !important; height: 33px; font-size: 33px">
        <mat-icon
          *ngIf="lossMessage"
          class="text-lg title-section w-10 h-10 mr-1"
          style="width: 33px !important; height: 33px; font-size: 33px">
          sentiment_dissatisfied
        </mat-icon>
      </div>
      <span
        *ngIf="lossMessage"
        class="title-section text-center font-libel text-lg"
        >{{ lossMessage }}</span
      >
    </div>

    <div class="h-48 mt-2 p-4" *ngIf="showLossMessage && result?.slotPix === 0">
      <app-loss-slot-message></app-loss-slot-message>
    </div>

    <div
      id="sorte-turbinada"
      style="transition: height 0.5s ease-in-out"
      class="px-4 pt-4 flex flex-col items-center justify-center invisible h-0 transition-all">
      <div class="h-40 mt-2">
        <app-win-slot-pix-message
          *ngIf="
            (pageState === 'WHEEL' || pageState === 'FINISH_RESULT') && result
          "
          [slotPix]="result.slotPix"></app-win-slot-pix-message>
      </div>

      <span class="title-section font-libel text-xl mb-1">SORTE TURBINADA</span>
      <span class="title-section text-center text-sm">
        Aqui você tem a chance de multiplicar seu prêmio!</span
      >
    </div>
    <app-wheel></app-wheel>
  </div>

  <app-unavailable-message
    *ngIf="pageState === 'UNAVAILABLE'"
    cdkTrapFocus></app-unavailable-message>

  <app-section-terms-and-conditions
    [showSection]="false"></app-section-terms-and-conditions>
  <span #scrollBottom></span>
</div>
