<div class="section-code-rescue" id="section-code-rescue">
  <div class="container mx-auto p-4 pb-8">
    <form [formGroup]="form" (ngSubmit)="checkSendCode()">
      <div class="w-full flex flex-row">
        <div class="flex flex-1 flex-col justify-center md:items-center">
          <div class="flex flex-row items-center mb-4">
            <span
              class="title-yellow font-libel text-center text-xl uppercase leading-5"
              >Resgate<br />seus códigos</span
            >
            <img
              class="exclamation"
              src="/assets/img/exclamacao.png"
              alt="Resgate seus códigos." />
          </div>
          <p class="section-description font-libel leading-6">
            Informe o código CV e ganhe prêmios! <br />
            São várias chances de ganhar.
            <button
              type="button"
              (click)="openInfoCvDialog()"
              class="info-cv rounded mt-1 ml-1">
              <mat-icon>info</mat-icon>
            </button>
          </p>
        </div>
        <div class="flex flex-1 md:justify-center">
          <div class="pos">
            <input
              class="code-input"
              matInput
              type="tel"
              formControlName="codeInput"
              placeholder="" />
            <button type="submit" class="pos-button">Clique aqui</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
