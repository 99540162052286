import { GameControlType } from './game.type';

declare const gameControl: GameControlType;

export class GameControl {
  play() {
    gameControl.play();
  }

  stop() {
    gameControl.stop();
  }
  stopWin() {
    gameControl.stopWin();
  }
  playChestJumping() {
    gameControl.playChestJumping();
  }

  playWin() {
    gameControl.playWin();
  }
}
