import { gql } from 'apollo-angular';

export const FRAGMENT_SELLER = gql`
  fragment SellerFrag on Seller {
    id
    name
    displayName
    displayCpf
    displayPhone
    status
    cpf
    phone
    email
    pixConfig {
      pixKey
      pixKeyKind
      createdAt {
        iso
      }
    }
    account {
      accountId
      name
      cnpj
    }
    balance {
      diamonds
      spins
      tickets
    }
    termSignature {
      version
      signedAt {
        iso
      }
    }
  }
`;

export const FRAGMENT_JACKPOT = gql`
  fragment AppJackpotFrag on AppJackpot {
    id
    name
    status
    amount
    endAt {
      iso
    }
  }
`;
