<form
  [formGroup]="form"
  (ngSubmit)="signIn()"
  class="w-full h-full flex flex-col px-8 pb-2 pt-16 items-center justify-start"
  *ngIf="!loading">
  <app-toolbar
    *ngIf="seller?.pixConfig?.pixKey"
    title="Editar perfil"></app-toolbar>

  <span
    *ngIf="!seller?.pixConfig?.pixKey"
    class="title-section font-libel text-2xl"
    >Completar dados</span
  >

  <div class="flex w-full flex-row justify-between items-center mt-4 mb-4">
    <app-logo sizeClass="w-16"></app-logo>
    <div class="flex flex-col pl-2">
      <span class="title-section text-center block text-sm"
        >Atualizando dados <br />
        do CPF:</span
      >
      <span class="title-section text-xl bold text-center mb-1">{{
        seller?.displayCpf
      }}</span>
    </div>
    <button
      (click)="logout()"
      mat-icon-button
      class="logout-button p-0"
      aria-label="Sair">
      <mat-icon class="w-7 h-7 title-section">exit_to_app</mat-icon>
    </button>
  </div>

  <mat-form-field>
    <mat-label>Nome</mat-label>
    <input formControlName="name" required matInput />
    <mat-error *ngIf="form.get('name')?.invalid">Informe seu nome.</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>WhatsApp</mat-label>
    <input
      prefix="+55"
      mask="(00) 00000 0000"
      formControlName="phone"
      type="tel"
      required
      matInput />
    <mat-error *ngIf="form.get('phone')?.invalid"
      >Informe seu WhatsApp.
    </mat-error>
  </mat-form-field>

  <div formGroupName="pixConfig" class="w-full">
    <mat-form-field>
      <mat-label>Tipo de chave Pix</mat-label>
      <mat-select
        formControlName="pixKeyKind"
        [required]="true"
        [disabled]="!!(seller && seller.pixConfig)"
        (selectionChange)="changePixType($event)">
        <mat-option [value]="PixKeyKindEnum.Cpf">CPF</mat-option>
        <mat-option [value]="PixKeyKindEnum.Email">E-mail</mat-option>
        <mat-option [value]="PixKeyKindEnum.Phone">Telefone</mat-option>
        <mat-option [value]="PixKeyKindEnum.Random">Aleatória</mat-option>
      </mat-select>
      <!--            <mat-error *ngIf="form.get('pixConfig.pixKeyKind')?.invalid"-->
      <!--            >Informe o tipo da sua chave Pix.-->
      <!--            </mat-error>-->
    </mat-form-field>

    <mat-form-field class="mb-8 sm:mb-4">
      <mat-label>Chave</mat-label>
      <input
        formControlName="pixKey"
        [mask]="pixKeyMask"
        [prefix]="pixKeyPrefix"
        [disabled]="!!(seller && seller.pixConfig)"
        matInput />
      <mat-hint class="title-section text-xs"
        >A chave Pix não pode ser modificada posteriormente, portanto, é crucial
        garantir que a chave esteja vinculada a uma conta em seu CPF.
      </mat-hint>
    </mat-form-field>
  </div>

  <button
    class="gold-button w-full rounded-3xl text-xl leading-9 font-libel mt-2"
    mat-button
    type="submit">
    Salvar
  </button>
</form>

<app-loading *ngIf="loading" message="Aguarde, salvando..."></app-loading>
