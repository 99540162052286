<div class="slot-container">
  <img class="slotBackImage" src="/static/img/outmachine3.png" />

  <div class="slotBlock">
    <div id="slot1" class="slot"></div>
    <div id="slot2" class="slot"></div>
    <div id="slot3" class="slot"></div>
    <div class="clear"></div>
  </div>
</div>
