import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PlayComponent } from './play/play.component';
import { JackpotComponent } from './jackpot/jackpot.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { StartSessionComponent } from './start-session/start-session.component';
import { SellerLoadGuard } from './core/guards/seller-load.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { ExtractComponent } from './extract/extract.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/inicio' },
  {
    path: 'login',
    component: SignUpComponent,
  },
  {
    path: 'start',
    component: StartSessionComponent,
  },
  {
    path: 'editar-perfil',
    component: EditProfileComponent,
    canActivate: [AuthGuard, SellerLoadGuard],
  },
  {
    path: 'inicio',
    component: HomeComponent,
    canActivate: [AuthGuard, SellerLoadGuard],
  },
  {
    path: 'extrato',
    component: ExtractComponent,
    canActivate: [AuthGuard, SellerLoadGuard],
  },
  {
    path: 'slot',
    component: PlayComponent,
    canActivate: [AuthGuard, SellerLoadGuard],
  },
  {
    path: 'jackpot',
    component: JackpotComponent,
    canActivate: [AuthGuard, SellerLoadGuard],
  },
];
