<h1 *ngIf="!data.hiddeCheckbox" mat-dialog-title class="text-green-600">
  Seja bem-vindo ao novo Caça Pix!
</h1>
<h1 *ngIf="data.hiddeCheckbox" mat-dialog-title class="text-green-600">
  Política de privacidade e Condições
</h1>
<div mat-dialog-content>
  <div class="px-4">
    <span *ngIf="data.hiddeCheckbox" class="block"
      >Você já concordou com nossos termos de privacidade e regulamento.
    </span>

    <div
      class="reading-box w-full overflow-y-scroll border border-gray-300 p-4">
      <h2 class="text-sm font-bold text-center text-black">
        <u>
          POLÍTICA DE PRIVACIDADE DE DADOS DA MERCADÃO DOS ÓCULOS SOL E GRAU
          FRANCHISING
        </u>
      </h2>

      <h3 class="text-center text-black mt-4 font-bold">1. ESCOPO</h3>
      <p class="text-gray-700 text-xs text-justify">
        O presente documento busca estabelecer um processo de governança,
        padrões, e controles relacionados à privacidade e proteção de dados de
        modo a garantir a implementação e manutenção de práticas de privacidade
        e proteção de dados.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        Este documento
        <u><b>é parte complementar da Política de Privacidade</b></u> da
        <b>MERCADÃO DOS ÓCULOS SOL E GRAU FRANCHISING LTDA.</b>, pessoa jurídica
        de direito privado, devidamente registrada no CNPJ/MF sob n°
        21.116.022/0001-91, com sede na Rua Dr. Aniz Buchalla, 251, Jardim
        Tarraf II, CEP nº 15.092-420, nesta cidade de São José do Rio Preto/SP.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">2. DEFINIÇÕES</h3>

      <div class="pl-8">
        <ul class="list-disc list-outside">
          <li class="text-gray-700 text-xs text-justify">
            <b>Autoridade Nacional de Proteção de Dados (ANPD):</b> <br />
            o órgão responsável pela fiscalização do cumprimento das disposições
            da LGPD no território brasileiro;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            <b>Controlador:</b> <br />
            a quem compete as decisões referentes ao Tratamento de Dados
            Pessoais, especialmente relativas às finalidades e aos meios desse
            tratamento;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            <b>Dado Pessoal:</b> <br />
            qualquer informação relacionada a pessoa natural identificada ou
            identificável, como: nome, CPF, RG, endereço residencial ou
            comercial, número de telefone fixo ou móvel, endereço de e-mail,
            dentre outros;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            <b>Dado Pessoal Sensível:</b> <br />
            o Dado Pessoal sobre origem racial ou étnica, convicção religiosa,
            opinião política, filiação a sindicato ou a organização de caráter
            religioso, filosófico ou político, dado referente à saúde ou à vida
            sexual, dado genético ou biométrico, quando vinculado a uma pessoa
            natural.
          </li>
          <li class="text-gray-700 text-xs text-justify">
            <b>Encarregado (DPO):</b> <br />
            a pessoa indicada pelo Controlador e Operador para atuar como canal
            de comunicação entre o Controlador, os Titulares e a Autoridade
            Nacional de Proteção de Dados (ANPD);
          </li>
          <li class="text-gray-700 text-xs text-justify">
            <b>Incidente de Segurança:</b> <br />
            qualquer acesso, aquisição, uso, modificação, divulgação, perda,
            destruição ou dano acidental, ilegal ou não autorizado que envolva
            Dados Pessoais;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            <b>LGPD:</b> <br />
            Lei Geral de Proteção de Dados (Lei Federal nº 13.709/2018);
          </li>
          <li class="text-gray-700 text-xs text-justify">
            <b>Operador:</b> <br />
            a Parte que trata Dados Pessoais de acordo com as instruções do
            Controlador.
          </li>
          <li class="text-gray-700 text-xs text-justify">
            <b>Relatório de Impacto à Proteção de Dados Pessoais:</b> <br />
            documentação do controlador que contém a descrição dos processos de
            tratamento de dados pessoais que podem gerar riscos às liberdades
            civis e aos direitos fundamentais, bem como medidas, salvaguardas e
            mecanismos de mitigação de risco;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            <b>Titular de Dados Pessoais:</b> <br />
            a pessoa natural a quem se referem os Dados Pessoais que são objeto
            de tratamento; e
          </li>
          <li class="text-gray-700 text-xs text-justify">
            <b>Tratamento de Dados Pessoais:</b> <br />
            qualquer operação ou conjunto de operações efetuadas com Dados
            Pessoais ou sobre conjuntos de Dados Pessoais, por meios
            automatizados ou não automatizados, tais como a coleta, o registro,
            a organização, a estruturação, a conservação, a adaptação ou
            alteração, a recuperação, a consulta, a utilização, a divulgação por
            transmissão, difusão ou qualquer outra forma de disponibilização, a
            comparação ou interconexão, a limitação, a eliminação ou a
            destruição.
          </li>
        </ul>
      </div>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        3. privacidade e proteção de dados
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        Nós da <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b>, nos
        preocupamos com a privacidade e proteção dos dados durante as atividades
        de tratamento de dados pessoais utilizados para viabilizar nossos
        negócios.
      </p>
      <p class="text-gray-700 text-xs text-justify">
        Para tal finalidade, possuímos uma estrutura organizacional com enfoque
        na Privacidade de Dados abrangendo todas os negócios e áreas da
        organização.
      </p>
      <p class="text-gray-700 text-xs text-justify">
        Foram definidos os papéis e responsabilidades sobre o tema para todos os
        colaboradores da
        <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> e implementados
        processos e tecnologias adequadas.
      </p>
      <p class="text-gray-700 text-xs text-justify">
        Durante os processos de tratamento de dados seguimos os princípios de
        privacidade, a legislação e as regulamentações sobre o tema e
        implementamos canais de relacionamento e contato com o público interno e
        externo com o objetivo de receber os pedidos de exercício de direito dos
        titulares.
      </p>
      <p class="text-gray-700 text-xs text-justify">
        Definimos um processo e metodologia para o tratamento de incidentes de
        privacidade e proteção de dados e possuímos estrutura e processo de
        identificação e monitoramento de riscos de privacidade e proteção de
        dados.
      </p>
      <p class="text-gray-700 text-xs text-justify">
        A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> ressalta que
        estabelece e mantém processos para gerenciar o desenvolvimento, entrega,
        monitoramento e auditoria contínua das políticas relacionadas às
        operações que tratam dados pessoais.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        4. papéis e responsabilidades
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        De modo a assegurar a observância da LGPD, a
        <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b>
        define um Programa de Privacidade de Dados com papeis e
        responsabilidades sobre o gerenciamento, operação, e monitoramento
        aplicado à toda a organização.
      </p>

      <div class="pl-8">
        <ul class="list-outside">
          <li class="text-gray-700 text-xs text-justify">
            <b>A) Encarregado (DPO):</b> <br />
            O papel do Encarregado compreende as seguintes responsabilidades:
            <div class="pl-8">
              <ol class="list-outside">
                <li>
                  (i) aceitar reclamações e comunicações dos titulares, prestar
                  esclarecimentos e adotar providências;
                </li>
                <li>
                  (ii) receber comunicações da autoridade nacional e adotar
                  providências;
                </li>
                <li>
                  (iii) orientar os funcionários e os contratados da entidade a
                  respeito das práticas a serem tomadas em relação à proteção de
                  dados pessoais; e
                </li>
                <li>
                  (iv) executar as demais atribuições determinadas pelo
                  controlador ou estabelecidas em normas complementares.
                </li>
              </ol>
            </div>
          </li>

          <li class="text-gray-700 text-xs text-justify mt-4">
            <b>B) Assistência Ao Encarregado (DPO):</b> <br />
            A
            <b
              >Mercadão dos Óculos Sol e Grau Franchising LTDA., nomeou e
              instituiu COMITÊ DE PRIVACIDADE</b
            >
            para trabalhar em conjunto com o ENCARREGADO (DPO) no
            desenvolvimento de suas atividades, facilitando a comunicação deste
            com os pontos focais e realizando atividades táticas e operacionais
            relacionadas ao Programa de Privacidade e Proteção de Dados da
            <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b>
            <br />
            <div class="pl-8">
              <ul class="list-outside">
                <li class="text-xs mt-2">
                  <u><b>PONTO FOCAL- GESTORES:</b></u> Os gestores de cada área
                  terão responsabilidades de promover o engajamento dos demais
                  colaboradores nas ações do Programa de Privacidade e Proteção
                  de Dados; monitorar o cumprimento das políticas e processos de
                  privacidade de proteção de dados em sua área; auxiliar na
                  identificação, mitigação e monitoramento dos riscos de
                  privacidade e proteção de dados relacionados ao negócio da
                  <b>Mercadão dos Óculos Sol e Grau Franchising LTDA</b>, sob
                  sua responsabilidade; e acompanhar o andamento dos planos de
                  ação relacionados a privacidade e proteção de dados dentro da
                  Empresa.
                </li>
                <li class="text-xs mt-2">
                  <u><b>PONTO FOCAL- COLABORADORES:</b></u> Cabe a todos os
                  colaboradores da Mercadão dos Óculos Sol e Grau Franchising
                  LTDA. o dever de respeitar e cumprir os termos de privacidade
                  integrantes do contrato de trabalho e demais documentos sobre
                  o tema privacidade e proteção de dados; e tratar dados
                  pessoais somente para as finalidades previamente definidas e
                  aprovada pelo Encarregado (DPO).
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        5. Avaliação do risco de privacIdade da Mercadão dos Óculos Sol e Grau
        Franchising LTDA
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> possui um
        processo de avaliação para identificação dos riscos de privacidade e
        proteção de dados em seus processos de negócio, monitorando os fatores
        que contribuem para aumentar o risco de privacidade da empresa. Para
        atingir esta finalidade, a
        <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> realiza uma
        avaliação de risco de privacidade e proteção de dados sempre que for
        notificada de uma alteração significativa nas atividades de tratamento
        de dados pessoais ou ambiente tecnológico diretamente envolvido no
        processo.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        6. gerenciamento de POLÍTICAS DE PRIVACIDADE
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> estabelece e
        mantém um conjunto de políticas, normas e procedimentos, para governar o
        Programa de Privacidade e reforçar o comportamento dos colaboradores
        alinhado às diretrizes de privacidade e proteção de dados da Mercadão
        dos Óculos Sol e Grau Franchising LTDA.
      </p>
      <p class="text-gray-700 text-xs text-justify">
        As Políticas de Privacidade da
        <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> informa de forma
        clara aos titulares de dados por ela tratados a forma como coleta, usa e
        divulga dados pessoais.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        7. TRANSFERENCIA INTERNACIONAL DE DADOS
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        Antes de transferir dados pessoais de titulares brasileiros para fora do
        Brasil como parte de uma atividade de processamento, a
        <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> se compromete a
        seguir as disposições legais, regulatória e de proteção de dados para
        proteger os interesses do titular do dado.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        8. HIPÓTESES DE TRANSFERÊNCIA INTERNACIONAL DE DADOS
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        Segundo a LGPD, é possível realizar a transferência de dados diante das
        seguintes hipóteses:
      </p>
      <div class="pl-8">
        <ul class="list-outside list-disc">
          <li class="text-gray-700 text-xs text-justify">
            para países ou organismos internacionais que proporcionem grau de
            proteção de dados pessoais adequado ao previsto na LGPD;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            quando o controlador oferecer e comprovar garantias de cumprimento
            dos princípios, dos direitos do titular e do regime de proteção de
            dados previstos na LGPD, na forma de:
            <div class="pl-8">
              <ul class="list-outside">
                <li>
                  a) cláusulas contratuais específicas para determinada
                  transferência;
                </li>
                <li>b) cláusulas-padrão contratuais;</li>
                <li>c) normas corporativas globais;</li>
                <li>
                  d) selos, certificados e códigos de conduta regularmente
                  emitidos;
                </li>
              </ul>
            </div>
          </li>

          <li class="text-gray-700 text-xs text-justify">
            quando a transferência for necessária para a cooperação jurídica
            internacional entre órgãos públicos de inteligência, de investigação
            e de persecução, de acordo com os instrumentos de direito
            internacional;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            quando a transferência for necessária para a proteção da vida ou da
            incolumidade física do titular ou de terceiro;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            quando a autoridade nacional autorizar a transferência;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            quando a transferência resultar em compromisso assumido em acordo de
            cooperação internacional;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            quando a transferência for necessária para a execução de política
            pública ou atribuição legal do serviço público, sendo dada
            publicidade nos termos do inciso I do caput do art. 23 da LGPD;
          </li>
          <li class="text-gray-700 text-xs text-justify">
            quando o titular tiver fornecido o seu consentimento específico e em
            destaque para a transferência, com informação prévia sobre o caráter
            internacional da operação, distinguindo claramente esta de outras
            finalidades; ou
          </li>
          <li class="text-gray-700 text-xs text-justify">
            quando necessário para atender as hipóteses previstas nos incisos
            II, V e VI do art. 7º da LGPD.
          </li>
        </ul>
      </div>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        9. GESTÃO DE CICLO DE VIDA DO DADO
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> coleta,
        processa e armazena as informações para suportar as atividades de
        tratamento de dados pessoais sob sua responsabilidade e mantém processos
        e controles sobre o gerenciamento do ciclo de vida de dados pessoais de
        acordo com as necessidades de negócio, legislações vigentes, requisitos
        regulatórios e com a LGPD.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        10. REGISTRO DAS ATIVIDADES DE PROCESSAMENTO
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        Para toda atividade de tratamento de dados pessoais, a
        <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b>
        manterá um registro, que incluirá informações a respeito das atividades
        de tratamento de dados pessoais e pessoais sensíveis; das finalidades de
        tratamento de dados; locais de armazenamento; compartilhamento ou
        recebimento de dados pessoais; tempo de armazenamento e descarte;
        transferências de dados para outros países, dentre outras informações
        relacionadas ao ciclo de vida dos dados pessoais.
      </p>

      <table
        border="1"
        class="text-gray-700 text-xs text-justify border-collapse table-auto w-full">
        <tr>
          <td
            class="border border-gray-600 bg-blue-200 text-black uppercase font-bold text-center text-xs">
            Direito de Confirmação da Existência de Tratamento
          </td>
          <td class="border border-gray-600 p-2 bg-green-200">
            Mediante solicitação e sem custo algum, a
            <b> Mercadão dos Óculos Sol e Grau Franchising LTDA. </b>
            fornece ao titular esclarecimentos sobre a existência de atividade
            de tratamento de dados de um respectivo titular que incluem:
            Confirmação de que os dados pessoais estão ou não sendo tratados
            pela empresa; descrição do propósito do tratamento; e descrição da
            fonte dos dados pessoais, se recolhidos de uma fonte diferente do
            titular.
          </td>
        </tr>
        <tr>
          <td
            class="border border-gray-600 bg-blue-200 text-black uppercase font-bold text-center text-xs">
            Direito de Acesso aos Dados
          </td>
          <td class="border border-gray-600 p-2 bg-green-200">
            Mediante solicitação e sem custo algum, a
            <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b>
            fornece ao titular acesso os dados por ela tratados, com as devidas
            garantias de segurança em vigor, que incluem: categorias de dados
            pessoais em questão e cópia dos dados em questão.
          </td>
        </tr>
        <tr>
          <td
            class="border border-gray-600 bg-blue-200 text-black uppercase font-bold text-center text-xs">
            Direito à Correção: Dados Incompletos, Inexatos ou Desatualizados;
            Anonimização, Bloqueio ou Eliminação, Excessivos ou Tratados em
            Desconformidade com a LGPD
          </td>
          <td class="border border-gray-600 p-2 bg-green-200">
            A pedido do titular, a
            <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> analisa os
            dados pessoais em questão quanto à precisão, relevância e
            integridade e corrige os dados pessoais passíveis de correção. Além
            disso, elimina, bloqueia e trata corretamente os dados pessoais
            destes.
          </td>
        </tr>
        <tr>
          <td
            class="border border-gray-600 bg-blue-200 text-black uppercase font-bold text-center text-xs">
            Direito de Portabilidade dos Dados a Outro Fornecedor de Serviço ou
            Produto
          </td>
          <td class="border border-gray-600 p-2 bg-green-200">
            A <b> Mercadão dos Óculos Sol e Grau Franchising LTDA. </b> fornece
            aos titulares um mecanismo para solicitar uma cópia de seus dados
            pessoais em um formato comumente legível por máquina para que ele
            possa providenciar a portabilidade dos dados para outro fornecedor
            de produtos ou serviços.
          </td>
        </tr>
        <tr>
          <td
            class="border border-gray-600 bg-blue-200 text-black uppercase font-bold text-center text-xs">
            Direito a Informação das Entidades Públicas e Privadas com as Quais
            o Controlador Compartilhou Dados
          </td>
          <td class="border border-gray-600 p-2 bg-green-200">
            Mediante solicitação, a
            <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> fornece ao
            titular esclarecimento sobre entidades públicas e privadas com as
            quais compartilhou dados.
          </td>
        </tr>
        <tr>
          <td
            class="border border-gray-600 bg-blue-200 text-black uppercase font-bold text-center text-xs">
            Direito a Informação sobre a Possibilidade de Não Fornecer
            Consentimento e sobre as Consequências da Negativa
          </td>
          <td class="border border-gray-600 p-2 bg-green-200">
            A <b> Mercadão dos Óculos Sol e Grau Franchising LTDA. </b> fornece
            aos titulares de dados a informação sobre a possibilidade de não
            fornecer o seu consentimento, documentando de forma clara quais as
            consequências da negativa para que o titular consiga tomar sua
            decisão de forma livre, informada e inequívoca.
          </td>
        </tr>
        <tr>
          <td
            class="border border-gray-600 bg-blue-200 text-black uppercase font-bold text-center text-xs">
            Direito a Revogação do Consentimento ou Paralisação do Uso do
            Legítimo Interesse
          </td>
          <td class="border border-gray-600 p-2 bg-green-200">
            Mediante solicitação, a
            <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b>
            restringirá o processamento dos dados pessoais de um titular de
            dados se:
            <div class="pl-8">
              <ul class="list-disc">
                <li>
                  Se a base legal utilizada para o processamento do dado pessoal
                  for o consentimento ou legítimo interesse.
                </li>
                <li>
                  Os dados pessoais em questão não são mais necessários para
                  finalidade do tratamento.
                </li>
                <li>
                  O titular dos dados se opôs ao processamento e não há razão
                  jurídica ou regulatória para o tratamento.
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td
            class="border border-gray-600 bg-blue-200 text-black uppercase font-bold text-center text-xs">
            Decisão Automatizada
          </td>
          <td class="border border-gray-600 p-2 bg-green-200">
            A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> fornece ao
            titular dos dados um mecanismo através do qual ele ou ela pode
            solicitar uma revisão de qualquer decisão automatizada.
          </td>
        </tr>
      </table>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        11. BASE JURÍDICA DA LGPD PARA O PROCESSAMENTO
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> estabelece e
        mantém procedimentos para avaliar e documentar as bases legais para o
        processamento de iniciativas em que os dados pessoais estejam presentes;
      </p>

      <div class="pl8">
        <ul class="list-outside list-disc">
          <li class="text-gray-700 text-xs text-left">
            A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b>
            identifica as seguintes bases legais do processamento de dados:
          </li>

          <li class="text-gray-700 text-xs text-left">
            Artigo 7º, LGPD: II- Para o cumprimento de obrigação legal ou
            regulatória pelo controlador;
          </li>
          <li class="text-gray-700 text-xs text-left">
            Artigo 7º, LGPD: V- quando necessário para execução de contrato ou
            de procedimentos preliminares relacionados a contrato do qual seja
            parte o titular, a pedido do titular de dados;
          </li>
          <li class="text-gray-700 text-xs text-left">
            Artigo 7º, LGPD: VI- para o exercício regular de direitos em
            processo judicial, administrativo ou arbitral, esse último nos
            termos da Lei 9.307/96.
          </li>

          <li class="text-gray-700 text-xs text-left">
            Artigo 11º, LGPD: II, “a”, para o cumprimento de obrigação legal ou
            regulatória pelo controlador;
          </li>
          <li class="text-gray-700 text-xs text-left">
            Artigo 11º, LGPD: II,”d”, exercício regular de direitos, inclusive
            em contrato e em processo judicial, administrativo e arbitral, este
            último nos termos da Lei 9.307/96.
          </li>

          <li class="text-gray-700 text-xs text-left">
            Quando a base legal escolhida for o Legítimo Interesse, a
            <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> conduzirá um
            Relatório de Impacto à Proteção à Dados Pessoais para documentar uma
            avaliação dos riscos de privacidade relacionados à atividade de
            processamento de dados.
          </li>
        </ul>
      </div>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        12. COMPARTILHAMENTO DOS DADOS
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        O compartilhamento com terceiros ocorrerá nas seguintes hipóteses:
      </p>
      <div class="pl-8">
        <ul class="list-outside list-disc">
          <li class="text-gray-700 text-xs text-left">
            Nas hipóteses em que o Titular de Dados consentir com a
            transferência;
          </li>
          <li class="text-gray-700 text-xs text-left">
            Quando a transferência for necessária em virtude do cumprimento de
            obrigações legais;
          </li>
          <li class="text-gray-700 text-xs text-left">
            Quando a transferência for necessária para o exercício regular de
            direitos em processos judiciais ou administrativos e arbitrais;
          </li>
          <li class="text-gray-700 text-xs text-left">
            Quando necessário para a execução de um contrato;
          </li>
          <li class="text-gray-700 text-xs text-left">
            Quando for necessário para atender interesses legítimos do Titular
            de Dados ou da
            <b>MERCADÃO DOS ÓCULOS SOL E GRAU FRANCHISING LTDA.</b>
          </li>
        </ul>
      </div>
      <p class="text-gray-700 text-xs text-justify">
        O Mercadão dos Óculos poderá compartilhar informações cadastrais e
        transacionais dos franqueados com a MARVIN PAGAMENTOS LTDA., sociedade
        empresária limitada, inscrita no CNPJ/ME. sob o nº 41.240.161/0001-30,
        com sede no Município e Estado de São Paulo, na Rua Doutor Renato Paes
        de Barros, 955, conjunto 132, Itaim Bibi, CEP 04.530-001, com a
        finalidade de consultar sua agenda de recebíveis perante as
        registradoras de recebíveis de cartão e prestar serviços de pagamento,
        na medida que o compartilhamento de informações seja necessário para a
        viabilização e fomento de parcerias inerentes a atividade, qualidade e
        aperfeiçoamento da prestação de serviços pelo Mercadão dos Óculos e/ou
        pela Marvin.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        13. DIREITOS DOS TITULARES DE DADOS
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> fornece
        mecanismos para que os titulares dos dados exerçam os seus direitos de
        acordo com as obrigações regulamentares e os compromissos
        organizacionais. A
        <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> mantém um
        recurso efetivo do Programa de Privacidade para receber, avaliar,
        documentar executar e responder às solicitações de direitos dos
        titulares de dados.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        14. GESTÃO DE REQUISIÇÃO
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        A Mercadão dos Óculos Sol e Grau Franchising LTDA. estabelece e mantém
        procedimentos e canais adequados para receber, avaliar, documentar,
        executar e responder a solicitações de direitos de titulares de dados
        relacionadas a dados pessoais armazenados ou processados pela
        <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> ou por terceiros
        em nome desta.
      </p>
      <p class="text-gray-700 text-xs text-justify">
        Antes de atender um pleito, a deverá realizar procedimentos para
        certificação que o requisitante realmente é o titular dos dados
        pessoais.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        15. RISCOS DE PRIVACIDADE NAS ATIVIDADES DE TRATAMENTO DE DADOS
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> estabelece e
        mantém procedimentos para identificar, avaliar, documentar, mitigar e
        monitorar riscos relacionados à privacidade. Ela identifica, monitora e
        mitiga riscos de privacidade desde a etapa de desenho e durante todo
        ciclo de vida da atividade de tratamento.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        16. MONITORAMENTO CONTÍNUO DE RISCOS (PRIVACY BY DESIGN E RELATÓRIO DE
        IMPACTO À PROTEÇÃO DE DADOS PESSOAIS)
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> estabelece e
        mantém um programa para o monitoramento contínuo do risco relacionado à
        privacidade.
      </p>
      <p class="text-gray-700 text-xs text-justify">
        As iniciativas são avaliadas periodicamente e com base no risco para
        validar a conformidade contínua com os requisitos legais, regulatórios e
        a LGPD, os mecanismos de entrega e consentimento de notificação são
        testados quanto à eficácia e alinhamento com os compromissos comerciais
        são testados periodicamente e os resultados das atividades de
        monitoramento contínuo são documentados, relatados e remediados sem
        atraso indevido.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        17. GESTÃO DE INCIDENTE DE PRIVACIDADE
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> mantém
        procedimentos atualizados para identificar, investigar, avaliar,
        documentar e remediar incidentes de privacidade e proteção de dados que
        envolvam dados pessoais. Além disso, mantém e atualiza procedimentos
        para notificar as partes relevantes, incluindo a ANPD, os titulares de
        dados afetados e outros terceiros relevantes, conforme estipulado pela
        LGPD.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        18. treinamento e conscientização
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        A <b>Mercadão dos Óculos Sol e Grau Franchising LTDA.</b> estabelece e
        mantém um programa de treinamento e conscientização sobre privacidade
        para garantir que seus colaboradores recebam treinamento adequado
        anualmente. O material deste treinamento é customizado para cada papel e
        responsabilidade declarado nesta política e faz parte do Programa de
        Privacidade e Proteção de dados da Empresa.
      </p>

      <h3 class="text-center text-black mt-4 font-bold uppercase">
        ESTE DOCUMENTO FOI ATUALIZADO EM: 23/11/2023
      </h3>
    </div>

    <!--    -------------------------------------------------------------------------------------    -->

    <div
      class="reading-box w-full overflow-y-scroll border border-gray-300 p-4 mt-4">
      <h2 class="text-sm font-bold text-center text-black">
        <u> REGULAMENTO DA CAMPANHA DE INCENTIVO FERAS DE VENDAS </u>
      </h2>

      <h3 class="text-center text-black mt-4 font-bold">
        1. DEFINIÇÕES PRELIMINARES
      </h3>
      <p class="text-gray-700 text-xs text-justify">
        1.2. ADMINISTRADOR: AMDO - ASSOCIACAO DOS FRANQUEADOS E FRANQUEADORA DO
        MERCADAO DOS OCULOS, empresa privada inscrita no CNPJ/MF sob o nº.
        27.281.086/0001- 24, com sede na RUA DOUTOR ANIZ BUCHALLA, 250 - SALA
        MARTE – JARDIM TARRAF II - SAO JOSE DO RIO PRETO/SP - CEP: 15092-420.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        1.3. O programa FERAS DE VENDAS é tão somente uma campanha de incentivo
        de vendas destinada à equipe de gerentes e vendedores do MERCADÃO DOS
        ÓCULOS. O programa tem como objetivo acompanhar e recompensar os
        colaboradores citados através de comprovação de performance.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">2. PARTICIPAÇÃO</h3>

      <p class="text-gray-700 text-xs text-justify">
        2.1. São elegíveis automaticamente ao FERAS DE VENDAS os colaboradores
        das equipes de gerência e vendas que tenham metas pré-estipuladas.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        2.2. Para se cadastrar à plataforma, cada participante elegível receberá
        via e-mail um link de ativação. O participante deverá concordar com o
        regulamento do programa e cadastrar um número de telefone e senha.
        Somente a partir desse momento a navegação será liberada.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">3. PERÍODO</h3>

      <p class="text-gray-700 text-xs text-justify">
        3.1. O Programa de Incentivo Feras de Vendas será realizado no período
        de 05 de fevereiro de 2024 à 13 de dezembro de 2024, podendo ser
        encerrado ou prorrogado a qualquer momento por decisão do MERCADÃO DOS
        ÓCULOS.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">
        4. MECÂNICAS E APURAÇÃO DE RESULTADOS
      </h3>

      <p class="text-gray-700 text-xs text-justify">
        4.1. As mecânicas serão do tipo: PROGRESSO, FOCOS MENSAIS, QUIZ E
        RANKING considerando as metas pré-estabelecidas e comunicadas pelo
        MERCADÃO DOS ÓCULOS a cada período de campanha.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        4.2. Periodicamente, durante o período da campanha, a apuração parcial
        dos resultados será publicada na plataforma. Uma vez a meta sendo
        atingida no acumulado do período, os participantes poderão ganhar pontos
        na plataforma.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        4.3. As mecânicas estarão publicadas na campanha nas abas METAS e FOCOS
        com as devidas descrições e indicação do tipo de premiação (pontos ou
        premiação especial).
      </p>

      <h3 class="text-center text-black mt-4 font-bold">
        5. APURAÇÃO/CARREGAMENTO DE PONTOS
      </h3>

      <p class="text-gray-700 text-xs text-justify">
        5.1. A pontuação para efeito de resgate de premiações será
        disponibilizada na plataforma no mês de divulgação do resultado final de
        cada campanha. O carregamento dos pontos e todo o histórico poderá ser
        visualizado no site por meio da aba EXTRATO.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        5.2. No caso de dúvidas quanto ao batimento das metas, não carregamentos
        dos pontos, cadastro, resgate de prêmios, mecânicas, sugestões,
        regulamento e outros, o participante deverá acessar o ÍCONE DE
        INTERROGAÇÃO ao lado direito da tela da plataforma, clicar em abrir
        chamado, selecionar o assunto e enviar a sua pergunta, ou entrar em
        contato com o SUPORTE DA CAMPANHA DE INCENTIVO VIA WHATSAPP, acessando o
        mesmo por meio do ícone também disponível na plataforma.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">6. PREMIAÇÃO</h3>

      <p class="text-gray-700 text-xs text-justify">
        6.1. Todos os participantes que atingirem a meta de acordo com os
        objetivos préestabelecidos pelo MERCADÃO DOS ÓCULOS, em cada uma das
        mecânicas, ganharão pontos considerando os critérios da campanha e o
        perfil do usuário. Os pontos publicados na plataforma poderão ser
        trocados por premiações no catálogo de prêmios do site da campanha,
        composto por: Catálogos de Produtos Físicos e Cartões Presente.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        6.2. As relações das metas, assim como as pontuações atribuídas e
        periodicidade, poderão ser alteradas a critério do MERCADÃO DOS ÓCULOS e
        estas alterações serão levadas previamente ao conhecimento dos
        participantes através dos canais de comunicação da Campanha.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">7. CAÇA-PIX</h3>

      <p class="text-gray-700 text-xs text-justify">
        7.1. Ao efetuar uma venda utilizando o meio de pagamento MDO Pay,
        obrigatoriamente com valor mínimo de R$ 750,00 (setecentos e cinquenta
        reais) em no mínimo 10 (dez) parcelas em cartão de crédito, o vendedor
        e/ou gerente, ora participantes, deverão registrar o número do CV gerado
        no cupom fiscal na ilustração do Caça-Pix disponível no site
        https://mdopix.com.br/, seguir as instruções fornecidas e então girar o
        slot.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        7.2. Para participar da campanha promocional, os participantes devem
        possuir uma ou mais "fichas" de chances para ativar o slot de sorteio. O
        slot sorteia prêmios que poderá ser em dinheiro ou bônus, como bilhetes
        dourados e diamantes, com a possibilidade de não ser sorteado nenhum
        prêmio. Os prêmios em PIX concedem uma chance de "sorte turbinada", que
        pode automaticamente multiplicar o valor recebido por 2 (duas), 3 (três)
        ou 5 (cinco) vezes, ou ainda conceder bilhetes dourados adicionais, a
        critério do sistema.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        7.2.1. Na possibilidade do participante ser contemplado com prêmio em
        dinheiro, o valor será pago via Pix, cuja chave pix deverá ser informada
        no ato da contemplação, além de pontos em quantidades variáveis para
        resgate no catálogo de prêmios do Feras de Vendas.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        7.3. A cada validação de CV, o participante acumula 1 (um) 'diamante' e
        uma chance de girar o slot. A cada 5 (cinco) diamantes acumulados, o
        participante ganha um bilhete dourado para participar do Jackpot, sendo
        possível acumular para os meses seguintes. Além disso, há a
        possibilidade de ganhar bônus do slot ou na roleta turbinada, sem
        limitação para o acúmulo dos bilhetes.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        7.3.1. Os participantes acumulam uma ficha e um diamante por cada vez
        que seguem estas regras, sem a necessidade de girar o slot
        imediatamente. Contudo, ao final da campanha promocional anual, todas as
        fichas e diamantes serão reiniciados para o próximo ano.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        7.4. Uma vez por mês, um único participante de toda a Rede Mercadão dos
        Óculos será premiado através de sorteio do Jackpot. Os participantes
        acumulam bilhetes dourados entre os dias 01 (um) e 30 (trinta) de cada
        mês e ao término desse período é aberta a sessão de Jackpot. O bilhete
        premiado na sessão, concede ao participante o valor total disponível no
        Jackpot no qual será superior às demais premiações. Em caso de não ter
        um sorteado, o valor acumulado será transferido para o mês subsequente.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        7.5. Os números do CV da venda estarão vinculados ao CNPJ da unidade
        franqueada em que o participante representa e poderão ser utilizados uma
        única vez como “ficha” para utilização no Caça-Pix, conforme este
        Regulamento.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">
        8. TRANSFERÊNCIA/UTILIZAÇÃO DE PONTOS
      </h3>

      <p class="text-gray-700 text-xs text-justify">
        8.1. Os pontos obtidos durante o Programa de Incentivo FERAS DE VEDAS
        deverão ser utilizados para resgate no próprio catálogo de prêmios da
        plataforma.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        8.2. As premiações em pontos são individuais e intransferíveis, não
        sendo permitida sua transferência para outros participantes e troca por
        dinheiro em espécie ou similares.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">
        9. RESGATE DE PREMIAÇÃO
      </h3>

      <p class="text-gray-700 text-xs text-justify">
        9.1. Para Resgate de Produtos e Cartões-Presente Físicos
      </p>

      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.1. Os resgates deverão ser realizados exclusivamente através da
        plataforma. Os prêmios que podem ser resgatados são limitados ao
        catálogo disponível na aba PREMIAÇÃO na plataforma e ao saldo existente
        na conta do usuário no momento do resgate.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.2. As premiações serão entregues no endereço cadastrado no momento
        do resgate. Não é possível alterar o endereço de entrega do prêmio após
        o resgate efetuado.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.3. Apenas o colaborador elegível poderá efetuar o resgate via
        plataforma. No momento do resgate, será calculado o valor de frete com
        base no cep inserido correspondente ao endereço de cadastro e este valor
        será debitado do saldo de pontos do participante.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.4. Algumas regiões não são atendidas pelos Correios e
        transportadoras e somente depois do resgate, o participante será
        comunicado como será providenciada a entrega ou necessidade de
        cancelamento do pedido. Para isso, é necessário acompanhar o tracking de
        entrega ou entrar em contato com o SUPORTE para obter esclarecimentos,
        caso ultrapasse da data prevista de entrega sem recebimento do prêmio.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.5. O prazo para entrega dos produtos e cartões físicos é de acordo
        com a data informada como “Prazo de Entrega” no momento do resgate. A
        entrega é realizada de segunda a sexta-feira e em horário comercial. Não
        há agendamentos ou entrega via caixa postal.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.6. Em locais onde há portarias residenciais ou comerciais, o prêmio
        é entregue ao porteiro/ recepcionista ou responsável pelo local no
        momento da entrega. Ao receber o prêmio, o recebedor assina o protocolo
        de entrega com o número de documento. A partir deste momento é de
        responsabilidade do participante retirar o seu prêmio na portaria.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.7. No caso de não localização do prêmio, o participante deve entrar
        em contato com o SUPORTE DA CAMPANHA através da abertura de chamado pelo
        ÍCONE DE INTERROGAÇÃO ao lado direito da plataforma, reportando a
        ocorrência e solicitando o protocolo de entrega com data e dados do
        recebedor do pedido dentro de 25 dias corridos da data do resgate.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.8. O fornecedor terá até 5 dias úteis para envio do protocolo de
        entrega digitalizado. Assim que o SUPORTE enviar o protocolo de entrega
        ao participante, este terá até 48 horas para solicitar acareação se
        necessário, ou seja, no caso de não reconhecer as informações constantes
        no protocolo de entrega.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.9. IMPORTANTE: somente é possível solicitar acareação ao fornecedor
        no prazo de 30 dias da entrega do prêmio, ou seja, da data que consta em
        sistema da transportadora como “Produto Entregue”, por isso é importante
        acompanhar o tracking de entrega. O participante poderá solicitar
        auxílio ao SUPORTE DA CAMPANHA.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        (*) para entregas realizadas pelos Correios, não é possível realizar
        acareação, uma vez que este prestador não oferece esta condição em
        contrato. Neste caso, o conhecimento de entrega do prestador com as
        informações de endereço, assinatura e número do documento do recebedor,
        registrados no ato da entrega são considerados comprovação efetiva da
        entrega. Se não for feito nenhum contato dentro deste prazo o MERCADÃO
        DOS ÓCULOS não se responsabilizará pelo recebimento do prêmio e os
        pontos do resgate não serão devolvidos.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.10. Para resgate de produtos, são realizadas 3 tentativas para
        entregar o prêmio. Em caso de insucesso depois da terceira tentativa, o
        produto retornará ao Centro de Distribuição para reenvio ou
        cancelamento. Havendo impossibilidade de reenvio, em último caso, os
        pontos serão estornados. É considerado insucesso de entrega quando o
        endereço fornecido estiver errado, ou quando a quantidade máxima de
        tentativas de entrega for feita e/ou não houver um maior de 18 anos para
        recebimento e assinatura de AR.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.11. Em caso de entregas via Correios, depois da segunda tentativa e
        insucesso de entrega, por motivo de destinatário ausente, o produto
        ficará por um período na Agência de Correios mais próximo ao endereço do
        participante (aproximadamente 15 dias). Caso não ocorra a retirada do
        prêmio dentro deste período, o pedido retornará ao Centro de
        Distribuição do fornecedor para possível reenvio ou cancelamento do
        pedido com estorno dos pontos.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.12. Caso ocorra extravio de carga, será informado e refeito o pedido
        respeitando novamente o prazo de entrega. Caso o prêmio não esteja mais
        disponível, será realizado o cancelamento do produto com estorno dos
        respectivos pontos.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.13. Para resgate de Cartões-Presente Físicos, atentar-se às
        descrições do item no momento do resgate (formato de utilização, data de
        validade do cartão e locais aceitos). A data de validade do plástico do
        cartão não corresponde à data de validade do crédito do cartão, essa é
        contabilizada a partir da data do resgate com expiração de acordo com a
        validade exibida no descritivo do modelo do cartão na plataforma.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.14. Para resgate de cartão-presente físico, não é possível
        cancelamento ou troca por outra modalidade após a confirmação do
        resgate.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.15. Após recebimento do cartão, o premiado poderá solicitar auxílio
        do SUPORTE DA CAMPANHA nos casos em que houver dificuldades de
        utilização, enviando evidências como prints de erro.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.16. No caso de o premiado conseguir utilizar o cartão com sucesso
        como forma de pagamento e transcorrer problemas na entrega do prêmio
        pela loja parceira ou cancelamento posterior à compra, é de
        responsabilidade do premiado tratar diretamente com a loja onde efetuou
        a compra para solução da ocorrência ou estorno do valor da compra
        cancelada para o cartão-presente.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.1.17. Para o resgate de cartão-presente físico, são realizadas 02
        (duas) tentativas de entrega pelos Correios. Em caso de insucesso depois
        da segunda tentativa, será cobrado do participante, a taxa de reenvio de
        acordo com o valor do cartão (conforme quadro abaixo) via depósito
        bancário para a empresa fornecedora dos cartões. É considerado insucesso
        de entrega quando o endereço fornecido estiver errado ou quando a
        quantidade máxima de tentativas de entrega for feita e/ou não houver uma
        pessoa maior de 18 anos para recebimento e assinatura de AR. Para
        cartões físicos, no caso de não pagamento do frete de reenvio em até 30
        (trinta) dias, o prêmio será descontinuado sem a devolução parcial nem
        total dos pontos.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        (*) IMPORTANTE! Para evitar a expiração do prazo de reenvio do cartão
        (no caso do não recebimento), entre em contato com o SUPORTE DA CAMPANHA
        até 15 (quinze) dias da data do resgate do prêmio na plataforma, assim a
        equipe fará uma análise junto ao fornecedor parceiro e enviará todas as
        informações e orientações necessárias.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        9.2. Para Resgate de Cartão Virtual
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.2.1. Resgate de cartões virtuais não poderão ser cancelados ou
        trocados por outra modalidade após confirmação do resgate.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.2.2. Para resgate de Cartões-Presente Virtual, atentar-se às
        descrições do item no momento do resgate (formato de utilização, data de
        validade do cartão e locais aceitos). A data de validade do cartão é
        disponibilizada na hora.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.2.3. Para resgate de Cartões-Presente Virtual, atentar-se às
        descrições do item no momento do resgate (formato de utilização, data de
        validade do cartão e locais aceitos). A data de validade do cartão é
        disponibilizada na hora do resgate, na “Descrição do Cartão”. O prazo
        para recebimento no e-mail cadastrado é de até 10 (dez) dias úteis. No
        caso do não recebimento dentro do prazo, abrir um chamado pelo ÍCONE DE
        INTERROGAÇÃO ao lado direito da plataforma em até 30 (trinta) dias da
        data de resgate.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.2.4. Após recebimento do cartão, o premiado poderá solicitar auxílio
        do SUPORTE DA CAMPANHA nos casos em que houver dificuldades de
        utilização, enviando evidências como prints de erro.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        9.2.5. No caso de o premiado conseguir utilizar o cartão com sucesso
        como forma de pagamento e transcorrer problemas na entrega do prêmio
        pela loja parceira ou cancelamento posterior à compra, é de
        responsabilidade do premiado tratar diretamente com a loja onde efetuou
        a compra para solução da ocorrência ou estorno do valor da compra
        cancelada para o cartão-presente.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">
        10. DESISTÊNCIA, AVARIA, DEFEITO OU ENTREGA ERRADA DOS PRÊMIOS
      </h3>

      <p class="text-gray-700 text-xs text-justify">10.1. Irregularidades</p>

      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        10.1.1. Se, na ocasião do recebimento da premiação, forem constatadas
        irregularidades no prêmio, como defeito, produto diferente do
        solicitado, embalagem violada ou produto danificado, ou ainda se houver
        desistência por parte do participante, o seguinte procedimento deverá
        ser adotado:
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        10.1.2. Se a irregularidade for constatada no ato do recebimento, o
        participante deverá fazer a negativa de entrega anotando no verso da
        nota fiscal a irregularidade constatada e devolvendo o prêmio
        imediatamente para o entregador na embalagem original e com a nota
        fiscal. Recomenda-se tirar fotos da irregularidade para agilizar e
        facilitar a análise do ocorrido. Após esse procedimento, informar ao
        programa o ocorrido pelo ÍCONE DE INTERROGAÇÃO ao lado direito da
        plataforma, anexando as fotos eventualmente tiradas, no prazo de até 2
        dias úteis do recebimento do prêmio.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        10.1.3. Se a irregularidade for constatada depois da entrega, o
        participante deverá manter o produto sem uso dentro da embalagem
        original, guardar a nota fiscal e anotar o fato no recibo de entrega do
        prêmio, descrevendo a irregularidade constatada, preferencialmente,
        registrando com fotos a irregularidade constatada. Informar ao SUPORTE
        DA CAMPANHA o ocorrido pelo ÍCONE DE INTERROGAÇÃO ao lado direito da
        plataforma, no prazo de até 2 dias úteis do recebimento do prêmio. Feito
        isto, será agendada a data da coleta do prêmio ou orientada a postagem
        reversa pelo Correios. No ato da coleta, para sua segurança, o
        participante deverá exigir que o transportador lhe apresente a Nota
        Fiscal de Coleta, onde constará a descrição do item a ser coletado
        (exceção feita aos Correios, que emitem o código de objeto de coleta,
        composto por 13 dígitos).
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        10.1.4. Caso chegue em sua residência ou e-mail alguma premiação que
        você desconheça o resgate, entre em contato com nossa Central de
        Atendimento ou abra uma ocorrência pelo ÍCONE DE INTERROGAÇÃO ao lado
        direito da plataforma em até 5 dias corridos da data de recebimento,
        para que possamos reaver o prêmio. No caso de utilização do produto ou
        cartão, os respectivos pontos serão debitados de seu saldo. Se for
        identificado resgates utilizando pontuações indevidas (bug sistêmico)
        serão efetuados o bloqueio da entrega dos prêmios e o seu saldo de
        pontos ajustados. No caso 7de recebimento desses prêmios sem aviso
        prévio à Central de Relacionamento ou abertura de ocorrência por meio do
        ÍCONE DE INTERROGAÇÃO ao lado direito da plataforma, o seu saldo será
        ajustado com o débito de pontos referentes a esses resgates.
      </p>

      <p class="text-gray-700 text-xs text-justify">10.2. Arrependimento</p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        10.2.1. Em caso de arrependimento, no ato do recebimento, o participante
        deverá fazer a negativa de entrega, devolvendo o prêmio imediatamente
        para o entregador na embalagem original e com a nota fiscal. Após este
        procedimento, informar imediatamente ao SUPORTE DA CAMPANHA o ocorrido
        por meio ÍCONE DE INTERROGAÇÃO ao lado direito da plataforma. Caso
        nenhum contato seja feito dentro deste prazo pelo ÍCONE DE INTERROGAÇÃO
        ao lado direito da plataforma, o MERCADÃO DOS ÓCULOS não se
        responsabilizará pelo estorno dos pontos.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        10.3. Voltagem indesejada do produto
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        10.3.1. Não serão efetuadas trocas ou cancelamentos de produtos por cor
        ou voltagem indesejada, exceto em casos que seja comprovado que foi
        entregue em cor ou voltagem divergente ao selecionado no momento do
        resgate.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        10.4. Retirada do produto
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        10.4.1. A devolução do produto poderá ser realizada via coleta, ou seja,
        retirada do produto no mesmo endereço de entrega, não permitindo
        alteração do endereço em hipótese alguma, ou via postagem reversa: envio
        do produto devidamente embalado com cópia da Nota Fiscal juntamente com
        o código a ser enviado pelo SUPORTE.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        10.4.2. Um novo produto só será reenviado depois que o devolvido e
        entregue por irregularidade ao Centro de Distribuição do fornecedor. Em
        caso de defeito, o reenvio será feito somente após análise do item
        defeituoso. Em caso de arrependimento, os pontos serão estornados, desde
        que os itens acima tenham sido aplicados. O prazo para disponibilização
        dos pontos é de 15 (quinze)-20 (vinte) dias úteis.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        10.4.3. Caso o contato seja feito após o prazo estipulado (2 dias úteis)
        o participante deverá tratar diretamente com o fabricante do produto.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        10.4.4. Constatados indícios de mau uso, ausência de manual/acessórios e
        DANFE (documento auxiliar da nota fiscal eletrônica), o fornecedor não
        aceitará a solicitação e devolverá o produto ao seu endereço de entrega.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        (*) IMPORTANTE: o fornecedor não prosseguirá com trocas, cancelamentos e
        coletas, se o prazo de reclamação por parte do SUPORTE DA CAMPANHA
        ultrapassar os 7 dias corridos da data de entrega, por isso a
        importância de o premiado respeitar os prazos mencionados acima, pois a
        EQUIPE DE SUPORTE recebe, analisa e direciona aos setores responsáveis
        dos diversos fornecedores parceiros.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">
        11. SUPORTE DA CAMPANHA DE INCENTIVO FERAS DE VENDAS
      </h3>

      <p class="text-gray-700 text-xs text-justify">
        11.1. Para dúvidas e abertura de ocorrências, o participante poderá
        abrir um chamado pela plataforma, acessando o ÍCONE DE INTERROGAÇÃO ao
        lado direito da plataforma ou por meio do contato via WHATSAPP.
      </p>

      <h3 class="text-center text-black mt-4 font-bold">
        12. DISPOSIÇÕES GERAIS
      </h3>

      <p class="text-gray-700 text-xs text-justify">
        12.1. A participação no Programa de Incentivo FERAS DE VENDAS será
        voluntária e facultativa, não havendo qualquer sanção àqueles que optem
        por não participar. Ainda, a participação não implica no recebimento
        e/ou direito à estabilidade empregatícia nem qualquer outro benefício de
        caráter trabalhista. As recompensas são meramente pontuais, restritas a
        esta ação de incentivo e não constituem incorporação salarial de
        qualquer espécie.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.2. As pontuações distribuídas durante o Programa de Incentivo FERAS
        DE VENDAS são individuais e intransferíveis, não sendo permitida sua
        troca por dinheiro em espécie ou similares, devendo ser utilizadas no
        resgate de prêmios no próprio site da campanha.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.3. Em caso de desligamento durante o período do Programa de Incentivo
        FERAS DE VENDAS o participante terá 30 dias, a partir da data da
        comunicação de dispensa, para poder utilizar seus pontos no resgate de
        premiações. Após este período, o seu acesso a Campanha será desabilitado
        e o saldo não transferido não poderá ser utilizado.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.4. A qualquer momento, o MERCADÃO DOS ÓCULOS poderá solicitar aos
        participantes e equipes as comprovações de atingimento das metas
        estabelecidas, através da apresentação de informações ou outros
        documentos que julgue necessário para confirmação do atingimento das
        metas.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.5. Serão automaticamente desclassificados da Campanha os
        participantes que tentarem burlar qualquer um dos itens deste
        Regulamento ou, ainda, utilizarem quaisquer meios ilícitos para obter
        benefícios indevidos para si ou para terceiro.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        12.5.1. Em caso de fraude ou tentativa de fraude, o participante será
        automaticamente excluído da Campanha, independentemente do envio de
        qualquer notificação. Para efeito desse item, considera-se fraude a
        participação através do cadastramento de informações incorretas ou
        falsas, bem como qualquer tentativa de violação das condições previstas
        do Programa de Incentivo FERAS DE VENDAS.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.6. Na hipótese descrita na cláusula acima, o participante beneficiado
        será automaticamente desclassificado do Programa de Incentivo FERAS DE
        VENDAS e terá seu acesso bloqueado.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.7. O MERCADÃO DOS ÓCULOS poderá realizar novas fases do Programa de
        Incentivo FERAS DE VENDAS bem como alterar/incluir as metas, pontuações
        e seus requisitos, mediante comunicação prévia.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.8. O MERCADÃO DOS ÓCULOS poderá encerrar o programa a qualquer
        momento mediante a aviso prévio através dos canais de comunicação.
      </p>
      <p class="text-gray-700 text-xs text-justify pl-4 mb-0">
        12.8.1. Em caso de antecipação do encerramento os participantes serão
        informados sobre o novo prazo para utilização dos pontos remanescentes.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.9. Os participantes autorizam desde já o uso gratuito por período
        indeterminado dos seus nomes, imagens, vozes, e-mails em qualquer tipo
        de mídia, incluindo internet, e peças promocionais para fins de
        divulgação de eventuais premiações recebidas no âmbito do Programa de
        Incentivo FERAS DE VENDAS, sem qualquer ônus para o MERCADÃO DOS ÓCULOS.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.10. A participação no Programa de Incentivo FERAS DE VENDAS implica
        na aceitação total de todos os itens deste Regulamento, que poderá ser
        alterado pelo MERCADÃO DOS ÓCULOS tantas vezes quantas forem
        necessárias.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.11. Quaisquer dúvidas referentes à Campanha ou a este Regulamento
        poderão ser esclarecidas através da EQUIPE DE SUPORTE. O prazo para o
        retorno é de até 24h úteis.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.12. As imagens utilizadas nos materiais de comunicação e site desta
        Campanha são imagens meramente ilustrativas.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.13. Os casos não previstos neste Regulamento serão analisados e
        decididos pelo MERCADÃO DOS ÓCULOS.
      </p>

      <p class="text-gray-700 text-xs text-justify">
        12.14. Fica eleito a Comarca de SÃO PAULO, Estado de SÃO PAULO, para
        todas as questões oriundas deste Regulamento, com exclusão de qualquer
        outro por mais privilegiado que seja.
      </p>
    </div>
  </div>
</div>
<div *ngIf="!data.hiddeCheckbox" mat-dialog-actions class="flex flex-col">
  <mat-checkbox
    color="warn"
    cdkTrapFocus
    class="leading-4"
    [(ngModel)]="approveTerms"
    [ngClass]="shake ? 'shake' : ''"
    >Eu Li e concordo com os termos de privacidade e com o regulamento.
  </mat-checkbox>
  <div class="flex flex-row">
    <button mat-button (click)="onNoClick()">Não agora</button>
    <button class="btn-play" mat-button (click)="onYesClick()">
      PARTICIPAR
    </button>
  </div>
</div>
<div *ngIf="data.hiddeCheckbox" mat-dialog-actions class="flex flex-col">
  <button mat-button (click)="onNoClick()">Fechar</button>
</div>
