<app-header></app-header>

<app-section-code-rescue></app-section-code-rescue>

<app-section-hunt-pix></app-section-hunt-pix>

<app-section-jackpot></app-section-jackpot>

<app-section-last-winner></app-section-last-winner>

<app-section-terms-and-conditions
  [showSection]="true"></app-section-terms-and-conditions>

<app-bottom-menu></app-bottom-menu>

<app-bg-sound></app-bg-sound>
