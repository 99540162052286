<mat-toolbar class="flex flex-row justify-center items-center z-20">
  <div
    class="toolbar-content flex flex-row justify-between items-center w-full">
    <button
      routerLink="/inicio"
      mat-icon-button
      class="title-section"
      aria-label="Voltar">
      <mat-icon class="title-section">arrow_back</mat-icon>
    </button>
    <span
      class="text-xl block text-center w-full font-bold title-section font-libel"
      >{{ title }}</span
    >

    <div class="flex flex-col justify-center items-center">
      <ng-content></ng-content>
      <small class="title-section -mt-1 mr-2">{{ version }}</small>
    </div>
  </div>
</mat-toolbar>
