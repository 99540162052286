<div class="mt-24 p-4" id="empty-ticket">
  <div class="p-4 flex flex-col justify-around items-center">
    <h2 class="title-section font-libel text-2xl mb-2">
      Seus bilhetes dourados acabaram!
    </h2>

    <div class="blue-2-border rounded-full p-2 hard-red-bg mt-2">
      <div class="w-40 h-40 rounded-full pos-bg"></div>
    </div>

    <span class="title-section mt-4">Mas não fique triste.</span>
    <span class="title-section text-center mb-4"
      >Jogando no Caça Pix você pode resgatar mais
      <strong>bilhetes dourados!</strong>
    </span>

    <button
      routerLink="/inicio"
      class="mt-4 mx-auto sec-button"
      mat-flat-button
      color="primary">
      Resgatar mais códigos
    </button>
    <button
      routerLink="/slot"
      class="mt-4 mx-auto sec-button"
      mat-flat-button
      color="accent">
      Jogar Caça Pix
    </button>
  </div>
</div>
