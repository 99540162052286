<div mat-dialog-content>
  <div class="border-gold-dialog flex flex-col p-4">
    <div class="p-1 bg-transparent border border-solid border-white">
      <div class="bg-white px-4">
        <div
          *ngIf="result.slotPix > 0 && !result.wheelPixMultiplier"
          class="w-full slot-result-inner flex flex-col justify-center items-center">
          <span class="font-libel text-green-500 text-2xl mb-4">Parabéns!</span>
          <span class="text-black leading-4"
            >Você ganhou um <br />
            Pix no valor de:</span
          >
          <span class="font-libel text-4xl text-black">{{
            result.totalPix | currency: 'R$ '
          }}</span>
        </div>

        <div
          *ngIf="result.slotPix > 0 && result.wheelPixMultiplier > 0"
          class="w-full slot-result-inner flex flex-col justify-center items-center">
          <span class="font-libel text-green-500 text-2xl mb-4">Parabéns!</span>
          <small
            class="secondary-text text-gray-600 text-xs text-center leading-4"
            >Você tirou a sorte grande na roleta!</small
          >
          <div class="flex flex-row items-center justify-center mb-4">
            <span class="text-lg font-bold mr-2"
              >{{ result.wheelPixMultiplier }}x</span
            >
            <span class="font-libel text-xl text-black">{{
              result.slotPix | currency: 'R$ '
            }}</span>
          </div>

          <span class="text-black leading-4"
            >Você ganhou um <br />
            Pix no valor de:</span
          >
          <span class="font-libel text-4xl text-green-600">{{
            result.totalPix | currency: 'R$ '
          }}</span>
        </div>

        <div
          *ngIf="result.totalPix === 0"
          class="w-full slot-result-inner flex flex-col justify-center items-center">
          <span class="font-libel text-green-500 text-2xl mb-4">Parabéns!</span>
          <small
            class="secondary-text text-gray-600 text-xs text-center mb-4 leading-4"
            >Você ganhou:</small
          >
        </div>

        <div *ngIf="totalTicket > 0 || totalDiamond > 0">
          <div class="w-full flex flex-row items-center justify-center">
            <span
              *ngIf="result.totalPix > 0"
              class="text-4xl text-gray-600 font-libel"
              >+</span
            >
          </div>

          <div class="flex flex-row items-end justify-around">
            <div
              *ngIf="totalTicket"
              class="flex flex-row items-end justify-center">
              <span class="text-2xl font-libel text-black font-bold mr-1"
                >{{ totalTicket }}x</span
              >
              <img class="w-20" src="/assets/img/ticket.jpg" alt="Ticket" />
            </div>

            <div
              *ngIf="totalDiamond"
              class="flex flex-row items-end justify-center">
              <span
                class="text-2xl font-libel text-black font-bold -mr-2 relative z-10"
                >{{ totalDiamond }}x</span
              >
              <img class="w-16" src="/assets/img/diamond.png" alt="Diamonds" />
            </div>
          </div>
        </div>

        <small
          class="secondary-text text-gray-600 text-xs text-center block mt-4 leading-4">
          {{
            totalDiamond > 0
              ? 'A cada 5 diamantes você ganha 1 bilhete dourado para participar do Jackpot! '
              : ''
          }}
          <br />
          {{
            totalTicket > 0 && !totalDiamond
              ? 'Acumule bilhetes dourados para participar do Jackpot!'
              : ''
          }}
          <br />
          {{
            result.totalPix > 0 ? 'Seu Pix será enviado em até 48 horas.' : ''
          }}
          <br />
          ID: {{ result.id }}
        </small>

        <div class="flex flex-row justify-end p-4">
          <button mat-stroked-button (click)="onNoClick()">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</div>
