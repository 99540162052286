import { SlotSymbolEnum } from '../../../../graphql/generated';

export class SlotSymbols {
  public static POSITION: Record<SlotSymbolEnum, number> = {
    [SlotSymbolEnum.Leaf]: 55, // 109 x
    [SlotSymbolEnum.Diamond]: 109, // 220 x
    [SlotSymbolEnum.Horseshoe]: 163, // 331 161
    [SlotSymbolEnum.Dollar]: 217, //442,x
    [SlotSymbolEnum.Orange]: 271, //553,x
    [SlotSymbolEnum.Bell]: 324, //664,x
    [SlotSymbolEnum.Watermelon]: 377, //775,x
    [SlotSymbolEnum.Heart]: 431, //886,x
    [SlotSymbolEnum.Apple]: 485, //997, x
    [SlotSymbolEnum.Cherry]: 538, //1108, x
    [SlotSymbolEnum.Banana]: 590, //1219, x
    [SlotSymbolEnum.Grape]: 644, //1330, x
    [SlotSymbolEnum.Bar]: 698, //1441, // ! Icone do mercadao x
    [SlotSymbolEnum.Seven]: 752, //1552,
  };

  public static random(): SlotSymbolEnum {
    const symbols = SlotSymbols.all();
    return symbols[Math.floor(Math.random() * symbols.length)];
  }

  static position(r: SlotSymbolEnum): number {
    if (SlotSymbols.POSITION[r] == undefined) {
      throw new Error(`Symbol not found: #${r} in SlotSymbols.POSITION`);
    }

    return SlotSymbols.POSITION[r];
  }

  static all(): Array<SlotSymbolEnum> {
    return Object.keys(SlotSymbols.POSITION) as any;
  }
}
