<div
  class="slot-error w-full p-1 p-4 flex flex-col justify-center items-center">
  <div class="blue-2-border rounded-full p-2 hard-red-bg mt-2">
    <mat-icon class="text-base title-section w-20 h-20"
      >sentiment_neutral
    </mat-icon>
  </div>

  <span class="font-libel text-red-500 text-2xl">Não foi dessa vez!</span>

  <small class="secondary-text text-gray-600 text-center mt-2"
    >Mas não desista, use suas chances para tentar outra vez! Basta clicar
    novamente em <strong>JOGAR AGORA.</strong></small
  >
</div>
