<app-toolbar title="Extrato"></app-toolbar>

<div
  *ngIf="loading"
  class="w-full h-full flex flex-col px-8 pb-2 pt-16 items-center justify-center">
  <app-loading message="Buscando informações"></app-loading>
</div>

<div
  *ngIf="!loading"
  class="w-full h-full flex flex-col px-8 pb-2 pt-16 items-center justify-start">
  <div class="flex flex-row justify-start items-end w-full mb-4">
    <div class="flex flex-col">
      <span class="title-yellow font-libel text-sm"
        >Sua configuração de Pix:</span
      >
      <span class="title-section text-sm"
        >Tipo:
        <b>{{ seller?.pixConfig?.pixKeyKind | formatPixKeyKind }}</b></span
      >
      <span class="title-section text-sm"
        >Chave: <b>{{ seller?.pixConfig?.pixKey }}</b></span
      >
    </div>

    <button
      routerLink="/editar-perfil"
      mat-icon-button
      class="logout-button p-0"
      aria-label="Editar perfil">
      <mat-icon class="w-7 h-7 title-section">edit</mat-icon>
    </button>
  </div>

  <p class="title-yellow font-libel text-sm">
    Acompanhe abaixo os Pix que você ganhou.
  </p>

  <div class="tbl-header mt-2">
    <table class="table-extrat w-full">
      <thead>
        <tr>
          <th class="font-libel">Data</th>
          <th class="font-libel">Descrição</th>
          <th class="font-libel">Situação</th>
          <th class="font-libel">Valor</th>
        </tr>
      </thead>
    </table>
  </div>

  <div class="tbl-content">
    <table class="table-extrat w-full">
      <tbody>
        <tr
          *ngFor="let dep of deposits"
          class="cursor-pointer"
          (click)="opendetailsDialog(dep)">
          <td>{{ dep.createdAt.iso | date: 'dd/MMM' }}</td>
          <td>{{ dep.description }}</td>
          <td>
            <app-deposit-status-label [status]="dep.status">{{
              dep.displayStatus
            }}</app-deposit-status-label>
          </td>
          <td class="font-libel bold">{{ dep.amount | currency: 'R$ ' }}</td>
        </tr>

        <tr *ngIf="deposits.length === 0">
          <td colspan="3">
            <span class="text-center block"
              >Nenhum depósito a ser exibido no momento.</span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
