import { Pipe, PipeTransform } from '@angular/core';
import { PixKeyKindEnum } from '../../../graphql/generated';

@Pipe({
  name: 'formatPixKeyKind',
  standalone: true,
})
export class FormatPixKeyKindPipe implements PipeTransform {
  transform(value: PixKeyKindEnum | undefined): string {
    if (!value) {
      return '';
    }

    switch (value) {
      case PixKeyKindEnum.Cnpj:
        return 'CNPJ';
      case PixKeyKindEnum.Cpf:
        return 'CPF';
      case PixKeyKindEnum.Email:
        return 'E-mail';
      case PixKeyKindEnum.Phone:
        return 'Telefone';
      case PixKeyKindEnum.Random:
        return 'Aleatório';
      default:
        return value;
    }
  }
}
