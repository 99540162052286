<div class="section-jackpot" *ngIf="pageState !== 'NONE'">
  <div class="container mx-auto px-4 pt-4">
    <div class="w-full flex flex-row justify-end items-end -mb-8">
      <button
        mat-mini-fab
        type="button"
        (click)="openInfoDialog()"
        class="info-cv rounded mt-1 ml-1">
        <mat-icon>info</mat-icon>
      </button>
    </div>
    <div class="w-full flex flex-row justify-center mt-4">
      <div class="jackpot-bg">
        <span class="jackpot-value">{{ amount | currency }}</span>
      </div>
    </div>

    <div
      class="w-full flex flex-row justify-between items-start -mt-4 mx-auto max-w-screen-sm">
      <div class="flex flex-col justify-center items-center">
        <div class="bg-ticket">
          <span class="text-ticket uppercase"
            >Você tem <br />
            <strong>{{ tickets }} bilhetes</strong> <br />
            dourados</span
          >
        </div>
      </div>

      <div
        class="flex flex-col justify-start items-center"
        *ngIf="pageState === 'CLOSED'">
        <span class="title-yellow uppercase text-xl text-center">Início: </span>
        <span class="text-2xl font-extrabold title-yellow text-center">
          {{ startDate | date: "dd/MM 'às' HH:mm" }}
        </span>
      </div>

      <div
        class="w-32 flex flex-row justify-end items-center mt-4"
        *ngIf="pageState === 'OPEN'">
        <button
          routerLink="/jackpot"
          class="btn-play w-full"
          mat-button
          color="primary">
          JOGAR AGORA
        </button>
      </div>
    </div>
  </div>
</div>
