<div class="p-4" *ngIf="win">
  <div class="border-gold-dialog w-full flex flex-col p-1">
    <div class="p-1 w-full bg-transparent border border-solid border-white">
      <div class="bg-white w-full p-2">
        <div
          class="w-full slot-result-inner flex flex-col justify-center items-center">
          <span class="font-libel title-yellow uppercase text-base m1-4">
            Último ganhador do Jackpot:
          </span>

          <span class="text-black text-center block leading-4">
            <strong> {{ win.amount | currency: 'R$ ' }}</strong> <br />
            {{ win.name }} - {{ win.account }} <br />
            {{ win.date | date: 'dd/MM' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
