<div class="play-component">
  <app-toolbar title="">
    <app-sound-settings />
  </app-toolbar>

  <!-----------------------
                 PAGE STATE OPEN
            ------------------------>

  <div *ngIf="pageState === 'OPEN'">
    <div class="w-full flex flex-row justify-center pt-16 -mb-28 z-30">
      <div class="jackpot-bg">
        <span class="text-xl jackpot-value">{{ amount | currency }}</span>
      </div>
    </div>

    <div
      id="boxChestAnimation"
      class="w-full overflow-hidden h-96 flex flex-col items-center justify-center">
      <lottie-player
        src="/assets/jackpot.json"
        background="transparent"
        speed="1"
        style="width: 600px; height: 600px; margin-bottom: -20px"
        direction="1"
        mode="normal"
        id="lottiePlayer"
        loop></lottie-player>
    </div>

    <div
      id="boxTicketMachine"
      class="w-full flex flex-col justify-center items-center -mt-20 relative z-10">
      <div
        class="flex flex-row items-start justify-start"
        style="width: 340px; height: 200px; margin-right: 20px">
        <app-ticket-machine style="margin-top: -75px"></app-ticket-machine>
      </div>

      <div class="flex flex-row items-end justify-center w-full h-12 -mt-20">
        <button
          class="btn-play w-48 animate-bounce"
          [ngClass]="{
            'animate-bounce':
              pageState === 'OPEN' && tickets > 0 && !loading && !playing
          }"
          mat-button
          (click)="play()"
          [disabled]="loading || pageState !== 'OPEN'">
          <mat-icon *ngIf="loading" class="animate-spin">refresh</mat-icon>
          <mat-icon *ngIf="!playing && !loading">play_circle</mat-icon>
          <mat-icon *ngIf="playing" class="animate-spin">casino</mat-icon>
          {{ buttonPlayText }}
        </button>
      </div>
    </div>

    <div class="flex flex-row items-center justify-center h-24 w-full px-4">
      <div style="width: 33px !important; height: 33px; font-size: 33px">
        <mat-icon
          *ngIf="lossMessage && showTicketMachine"
          class="text-lg title-section w-10 h-10 mr-1"
          style="width: 33px !important; height: 33px; font-size: 33px">
          sentiment_dissatisfied
        </mat-icon>
      </div>
      <span
        *ngIf="lossMessage && showTicketMachine"
        class="title-section text-center font-libel text-lg"
        >{{ lossMessage }}</span
      >
    </div>

    <app-empty-ticket-message
      *ngIf="!showTicketMachine && !isWinner"></app-empty-ticket-message>

    <div *ngIf="isWinner" class="px-8">
      <app-winner-jackpot-message
        [amount]="amountWinner"></app-winner-jackpot-message>
      <br />
      <div class="flex flex-row justify-center items-center">
        <button
          routerLink="/inicio"
          class="mt-4 mx-auto sec-button"
          mat-flat-button
          color="primary">
          Voltar ao início
        </button>
      </div>
    </div>
  </div>

  <!-----------------------
                PAGE STATE COMPLETE
            ------------------------>

  <div
    *ngIf="pageState === 'COMPLETED'"
    class="mt-28 flex flex-col justify-center px-8">
    <div class="flex flex-col justify-center items-center">
      <div class="blue-2-border rounded-full p-2 hard-red-bg mt-2">
        <div class="w-40 h-40 rounded-full icon-jackpot"></div>
      </div>
    </div>

    <span class="font-libel text-2xl text-center title-section mt-2 mb-2">
      Jackpot resgatado!
    </span>

    <app-section-last-winner></app-section-last-winner>

    <p class="title-section text-center">
      Este prêmio já foi resgatado. Mas não desanime, acompanhe em nossa página
      inicial quando um novo Jackpot estará disponível.
    </p>

    <button
      routerLink="/inicio"
      class="mt-4 mx-auto sec-button"
      mat-flat-button
      color="primary">
      Voltar ao início
    </button>
  </div>
  <!-----------------------
                PAGE STATE CLOSED
            ------------------------>

  <div
    *ngIf="pageState === 'CLOSED'"
    class="pt-28 flex flex-col justify-center px-8">
    <div class="flex flex-col justify-center items-center">
      <div class="blue-2-border rounded-full p-2 red-bg mt-2">
        <div
          class="w-40 h-40 rounded-full icon-jackpot flex flex-col justify-end items-center"
          style="padding-bottom: 35px">
          <span class="font-libel jackpot-value title-yellow">
            {{ amount | currency }}
          </span>
        </div>
      </div>
    </div>

    <span class="font-libel text-2xl text-center title-section mt-2 mb-2">
      O Jackpot ainda não foi liberado.!
    </span>

    <p class="title-section text-center">Disponível a partir de:</p>

    <span class="font-libel text-2xl text-center title-section mt-2 mb-2">
      <strong class="text-3xl">{{
        startDate | date: "dd/MM 'às' HH:mm"
      }}</strong>
    </span>

    <button
      routerLink="/inicio"
      class="mt-4 mx-auto sec-button"
      mat-flat-button
      color="primary">
      Voltar ao início
    </button>
  </div>
</div>

<app-section-terms-and-conditions
  [showSection]="false"></app-section-terms-and-conditions>
