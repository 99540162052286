import { gql } from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  /** Decimal custom scalar type */
  Decimal: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AbilityAction = {
  __typename?: 'AbilityAction';
  key?: Maybe<AbilityActionEnum>;
  label: Scalars['String']['output'];
};

export enum AbilityActionEnum {
  Create = 'create',
  /** CRUD é um atalho para (create, read, update, delete) */
  Crud = 'crud',
  Delete = 'delete',
  /** Cuidado ao utilizar 'manage' pois ele é um atalho para todas as ações. */
  Manage = 'manage',
  /** Modify é um atalho para (create, update, delete) */
  Modify = 'modify',
  Read = 'read',
  /** Ação de restaurar um registro que foi deletado por soft delete. */
  Restore = 'restore',
  Update = 'update'
}

export type AbilityRawRule = {
  __typename?: 'AbilityRawRule';
  /**
   * Nome da fonte de dados que gerou a regra.
   * ex: 'user:me' | 'role:admin'
   */
  _source: Scalars['String']['output'];
  /** Nome da ação que o usuário pode executar. */
  action: Array<AbilityActionEnum>;
  /** Condições que devem ser atendidas para que a regra seja aplicada. */
  conditions?: Maybe<Scalars['JSON']['output']>;
  /**
   * É o inverso da condição (negação).
   * Se verdadeiro, a regra será aplicada se as condições não forem atendidas.
   * Por padrão, é falso.
   */
  inverted?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Mensagem explicando a razão para a regra.
   * Ex: 'Você não pode editar este post porque não é o autor.'
   */
  reason?: Maybe<Scalars['String']['output']>;
  /** Nome do objeto ao qual a regra se aplica. */
  subject: AbilitySubjectEnum;
};

export type AbilityRule = {
  __typename?: 'AbilityRule';
  /** Nome da ação que o usuário pode executar. */
  action: Array<AbilityActionEnum>;
  /** Condições que devem ser atendidas para que a regra seja aplicada. */
  conditions?: Maybe<AbilityRuleCondition>;
  /**
   * É o inverso da condição (negação).
   * Se verdadeiro, a regra será aplicada se as condições não forem atendidas.
   * Por padrão, é falso.
   */
  inverted?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Mensagem explicando a razão para a regra.
   * Ex: 'Você não pode editar este post porque não é o autor.'
   */
  reason?: Maybe<Scalars['String']['output']>;
  /** Nome do objeto ao qual a regra se aplica. */
  subject: AbilitySubjectEnum;
};

export type AbilityRuleCondition = {
  __typename?: 'AbilityRuleCondition';
  /** Apenas registros com os ids informados. */
  ids?: Maybe<Array<Scalars['ID']['output']>>;
  /** Apenas registros relacionado ao user logado. */
  onlyMyUser?: Maybe<Scalars['Boolean']['output']>;
  /** Apenas registros que não foram deletados (deletedAt=null). */
  onlyNotDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export type AbilityRuleConditionInput = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyMyUser?: InputMaybe<Scalars['Boolean']['input']>;
  onlyNotDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AbilityRuleInput = {
  action: Array<AbilityActionEnum>;
  conditions?: InputMaybe<AbilityRuleConditionInput>;
  reason?: InputMaybe<Scalars['String']['input']>;
  subject: AbilitySubjectEnum;
};

export type AbilitySubject = {
  __typename?: 'AbilitySubject';
  actions: Array<AbilityAction>;
  key: AbilitySubjectEnum;
  label: Scalars['String']['output'];
};

export enum AbilitySubjectEnum {
  Account = 'Account',
  Campaign = 'Campaign',
  Deposit = 'Deposit',
  Diamond = 'Diamond',
  Draw = 'Draw',
  Jackpot = 'Jackpot',
  Prize = 'Prize',
  Role = 'Role',
  Seller = 'Seller',
  SellerSession = 'SellerSession',
  Spin = 'Spin',
  Ticket = 'Ticket',
  User = 'User',
  /** Cuidado ao utilizar 'all' pois ele é um atalho para todos os objetos. */
  All = 'all'
}

export type Account = {
  __typename?: 'Account';
  accountId: Scalars['Int']['output'];
  active: Scalars['Boolean']['output'];
  city?: Maybe<Scalars['String']['output']>;
  cnpj: Scalars['String']['output'];
  createdAt: CustomDate;
  erp: Scalars['String']['output'];
  location?: Maybe<Array<Scalars['Float']['output']>>;
  name: Scalars['String']['output'];
  parentGroup: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: CustomDate;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AccountEdgesResponse = {
  __typename?: 'AccountEdgesResponse';
  edges: Array<Account>;
  pagination?: Maybe<Pagination>;
};

export type AppAddNsuMdoPayResponse = {
  __typename?: 'AppAddNsuMdoPayResponse';
  gift: SpinGift;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
};

export type AppAuthSellerSessionResponse = {
  __typename?: 'AppAuthSellerSessionResponse';
  /**
   * Mesmo tokenAPI concatenando Bearer
   * Forma pratica de copiar token para colar no header do playground
   */
  Authorization: Scalars['String']['output'];
  /** JWT access API */
  accessToken: Scalars['String']['output'];
  /** Tempo para expirar o token */
  expiresAt: CustomDate;
};

export type AppDeposit = {
  __typename?: 'AppDeposit';
  amount: Scalars['Float']['output'];
  createdAt: CustomDate;
  description: Scalars['String']['output'];
  displayStatus: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pixConfig: DepositPixConfig;
  receipt: Scalars['String']['output'];
  receiptFile?: Maybe<Scalars['String']['output']>;
  receiptUri?: Maybe<Scalars['String']['output']>;
  status: DepositStatusEnum;
};

export type AppDepositEdgesResponse = {
  __typename?: 'AppDepositEdgesResponse';
  edges: Array<AppDeposit>;
  pagination?: Maybe<Pagination>;
};

export type AppJackpot = {
  __typename?: 'AppJackpot';
  amount: Scalars['Float']['output'];
  endAt: CustomDate;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: JackpotStatusEnum;
  winners?: Maybe<Array<JackpotWinner>>;
};

export type AppJackpotResponse = {
  __typename?: 'AppJackpotResponse';
  current?: Maybe<AppJackpot>;
  prev?: Maybe<AppJackpot>;
};

export type AppSignUpSellerSessionInput = {
  /** CPF do vendedor */
  cpf: Scalars['String']['input'];
  /** Telefone do vendedor */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type AppSpinJackpotResponse = {
  __typename?: 'AppSpinJackpotResponse';
  amountPix?: Maybe<Scalars['Float']['output']>;
  jackpot: AppJackpot;
  message: Scalars['String']['output'];
  won: Scalars['Boolean']['output'];
};

export type AppSpinSlotResponse = {
  __typename?: 'AppSpinSlotResponse';
  id?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  slot: Array<SlotSymbolEnum>;
  slotDiamond?: Maybe<Scalars['Int']['output']>;
  slotPix?: Maybe<Scalars['Float']['output']>;
  slotTicket?: Maybe<Scalars['Int']['output']>;
  totalDiamond: Scalars['Int']['output'];
  totalPix: Scalars['Float']['output'];
  totalTicket: Scalars['Int']['output'];
  wheel?: Maybe<WheelSymbolEnum>;
  wheelPixMultiplier?: Maybe<Scalars['Int']['output']>;
  wheelTicket?: Maybe<Scalars['Int']['output']>;
};

export type AppStartSellerSessionResponse = {
  __typename?: 'AppStartSellerSessionResponse';
  expiresAt: CustomDate;
  link: Scalars['String']['output'];
  message: Scalars['String']['output'];
  session: Scalars['ID']['output'];
};

export type Campaign = {
  __typename?: 'Campaign';
  accounts?: Maybe<Array<Account>>;
  createdAt: CustomDate;
  description?: Maybe<Scalars['String']['output']>;
  endAt: CustomDate;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ranking: Scalars['Int']['output'];
  rateLoss: Scalars['Float']['output'];
  sellers?: Maybe<Array<Seller>>;
  startAt: CustomDate;
  status: CampaignStatusEnum;
  updatedAt: CustomDate;
};

export type CampaignEdgesResponse = {
  __typename?: 'CampaignEdgesResponse';
  edges: Array<Campaign>;
  pagination?: Maybe<Pagination>;
};

export type CampaignPrize = {
  __typename?: 'CampaignPrize';
  odd: Scalars['Float']['output'];
  prize: Scalars['ID']['output'];
  prizeName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  totalDiamond: Scalars['Int']['output'];
  totalPix: Scalars['Float']['output'];
  totalTicket: Scalars['Int']['output'];
};

export type CampaignPrizesResponse = {
  __typename?: 'CampaignPrizesResponse';
  prizes: Array<CampaignPrize>;
  probabilityLoss: Scalars['Float']['output'];
  probabilityWon: Scalars['Float']['output'];
  probabilityWonWithoutPix: Scalars['Float']['output'];
  rateLoss: Scalars['Float']['output'];
  totalDiamond: Scalars['Int']['output'];
  totalPix: Scalars['Float']['output'];
  totalTicket: Scalars['Int']['output'];
};

export type CampaignResponse = {
  __typename?: 'CampaignResponse';
  data: Campaign;
  message: Scalars['String']['output'];
};

export type CampaignResumeDraws = {
  __typename?: 'CampaignResumeDraws';
  prize: Scalars['ID']['output'];
  prizeName: Scalars['String']['output'];
  quantities: Array<CampaignResumeRange>;
  totalPix: Scalars['Float']['output'];
};

export type CampaignResumeDrawsResponse = {
  __typename?: 'CampaignResumeDrawsResponse';
  headers: Array<Scalars['String']['output']>;
  prizes: Array<CampaignResumeDraws>;
  quantities: Array<CampaignResumeRange>;
  totals: Array<Scalars['Float']['output']>;
};

export type CampaignResumeRange = {
  __typename?: 'CampaignResumeRange';
  available: Scalars['Int']['output'];
  draw: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CampaignStatusEnum {
  Disabled = 'Disabled',
  Draft = 'Draft',
  Enabled = 'Enabled',
  Finished = 'Finished'
}

export type ConditionAccountInput = {
  accountId?: InputMaybe<Scalars['Int']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  cnpj?: InputMaybe<Scalars['String']['input']>;
  erp?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentGroup?: InputMaybe<Scalars['String']['input']>;
};

export type ConditionCampaignInput = {
  accounts?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdAt?: InputMaybe<ConditionDateInput>;
  endAt?: InputMaybe<ConditionDateInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ranking?: InputMaybe<ConditionIntInput>;
  rateLoss?: InputMaybe<ConditionFloatInput>;
  sellers?: InputMaybe<Array<Scalars['ID']['input']>>;
  startAt?: InputMaybe<ConditionDateInput>;
  status?: InputMaybe<Array<InputMaybe<CampaignStatusEnum>>>;
  updatedAt?: InputMaybe<ConditionDateInput>;
};

export type ConditionDateInput = {
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  ne?: InputMaybe<Scalars['Date']['input']>;
};

export type ConditionDepositInput = {
  amount?: InputMaybe<ConditionFloatInput>;
  createdAt?: InputMaybe<ConditionDateInput>;
  draw?: InputMaybe<Scalars['ID']['input']>;
  jackpot?: InputMaybe<Scalars['ID']['input']>;
  payerE2eId?: InputMaybe<Scalars['String']['input']>;
  payerId?: InputMaybe<Scalars['String']['input']>;
  payerKey?: InputMaybe<Scalars['String']['input']>;
  seller?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<DepositStatusEnum>>;
  updatedAt?: InputMaybe<ConditionDateInput>;
};

export type ConditionDrawInput = {
  account?: InputMaybe<ConditionIntInput>;
  campaign?: InputMaybe<Scalars['ID']['input']>;
  createdAt?: InputMaybe<ConditionDateInput>;
  drawnAt?: InputMaybe<ConditionDateInput>;
  level?: InputMaybe<ConditionIntInput>;
  lote?: InputMaybe<Scalars['String']['input']>;
  odd?: InputMaybe<ConditionFloatInput>;
  prize?: InputMaybe<Scalars['ID']['input']>;
  seller?: InputMaybe<Scalars['ID']['input']>;
  slot?: InputMaybe<SlotSymbolEnum>;
  slotDiamond?: InputMaybe<ConditionIntInput>;
  slotPix?: InputMaybe<ConditionFloatInput>;
  slotTicket?: InputMaybe<ConditionIntInput>;
  startAt?: InputMaybe<ConditionDateInput>;
  status?: InputMaybe<Array<DrawStatusEnum>>;
  totalDiamond?: InputMaybe<ConditionIntInput>;
  totalPix?: InputMaybe<ConditionFloatInput>;
  totalTicket?: InputMaybe<ConditionIntInput>;
  updatedAt?: InputMaybe<ConditionDateInput>;
  wheel?: InputMaybe<WheelUniqueSymbolEnum>;
  wheelPixMultiplier?: InputMaybe<ConditionIntInput>;
  wheelTicket?: InputMaybe<ConditionIntInput>;
};

export type ConditionFloatInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
};

export type ConditionIntInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export type ConditionJackpotInput = {
  amount?: InputMaybe<ConditionFloatInput>;
  endAt?: InputMaybe<ConditionDateInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<ConditionDateInput>;
  status?: InputMaybe<Array<JackpotStatusEnum>>;
};

export type ConditionPrizeInput = {
  createdAt?: InputMaybe<ConditionDateInput>;
  level?: InputMaybe<ConditionIntInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  slot?: InputMaybe<Array<SlotSymbolEnum>>;
  slotDiamond?: InputMaybe<ConditionIntInput>;
  slotPix?: InputMaybe<ConditionFloatInput>;
  slotTicket?: InputMaybe<ConditionIntInput>;
  status?: InputMaybe<Array<PrizeStatusEnum>>;
  totalPix?: InputMaybe<ConditionFloatInput>;
  updatedAt?: InputMaybe<ConditionDateInput>;
  wheel?: InputMaybe<Array<WheelUniqueSymbolEnum>>;
  wheelPixMultiplier?: InputMaybe<ConditionIntInput>;
  wheelTicket?: InputMaybe<ConditionIntInput>;
};

export type ConditionRoleInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<AbilitySubjectEnum>;
};

export type ConditionSellerInput = {
  account?: InputMaybe<Scalars['Int']['input']>;
  cpf?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<ConditionDateInput>;
  deletedAt?: InputMaybe<ConditionDateInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<SellerStatusEnum>>;
  updatedAt?: InputMaybe<ConditionDateInput>;
};

export type ConditionSpinInput = {
  account?: InputMaybe<Scalars['Int']['input']>;
  cnpj?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<ConditionDateInput>;
  drawnAt?: InputMaybe<ConditionDateInput>;
  level?: InputMaybe<Scalars['Int']['input']>;
  origin?: InputMaybe<SpinOriginEnum>;
  prizeType?: InputMaybe<PrizeTypeEnum>;
  seller?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<SpinStatusEnum>;
  updatedAt?: InputMaybe<ConditionDateInput>;
};

export type ConditionUserInput = {
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<UserStatusEnum>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmDepositInput = {
  observation?: InputMaybe<Scalars['String']['input']>;
  receipt?: InputMaybe<Scalars['String']['input']>;
  receiptFile: Scalars['String']['input'];
  receiptUri: Scalars['String']['input'];
};

export type CreateCampaignInput = {
  accounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  endAt: Scalars['Date']['input'];
  name: Scalars['String']['input'];
  ranking: Scalars['Int']['input'];
  rateLoss: Scalars['Float']['input'];
  sellers?: InputMaybe<Array<Scalars['ID']['input']>>;
  startAt: Scalars['Date']['input'];
  status: CampaignStatusEnum;
};

export type CreateDrawInput = {
  campaign: Scalars['ID']['input'];
  lotes: Array<CreateDrawLoteInput>;
  odd: Scalars['Float']['input'];
  prize: Scalars['ID']['input'];
};

export type CreateDrawLoteInput = {
  quantity: Scalars['Int']['input'];
  startAt: Scalars['Date']['input'];
};

export type CreateJackpotInput = {
  amount: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  startAt?: InputMaybe<Scalars['Date']['input']>;
};

export type CreatePrizeInput = {
  level: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  slot: Array<SlotSymbolEnum>;
  slotDiamond?: InputMaybe<Scalars['Int']['input']>;
  slotPix?: InputMaybe<Scalars['Float']['input']>;
  slotTicket?: InputMaybe<Scalars['Int']['input']>;
  suggestedOdd: Scalars['Float']['input'];
  wheel?: InputMaybe<WheelUniqueSymbolEnum>;
};

export type CreateRoleInput = {
  abilityRules: Array<AbilityRuleInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateSellerInput = {
  account?: InputMaybe<Scalars['Int']['input']>;
  cpf: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  pixConfig?: InputMaybe<PixConfigInput>;
};

export type CreateUserInput = {
  password: Scalars['String']['input'];
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  username: Scalars['String']['input'];
};

export type CustomDate = {
  __typename?: 'CustomDate';
  /** Formats a string to the ISO8601 standard. */
  iso?: Maybe<Scalars['String']['output']>;
  /** String matches the RFC 2822 Date time format */
  string?: Maybe<Scalars['String']['output']>;
  /** Integer value representing the number of milliseconds since the Unix Epoch (Jan 1 1970 12AM UTC). */
  timestamp?: Maybe<Scalars['Float']['output']>;
  /** This function returns the real offset from UTC, not the reverse offset */
  timezone?: Maybe<Scalars['String']['output']>;
  /**
   * If you know the format of an input string, you can use that to parse a date.
   * Read more: [momentjs format date](https://momentjs.com/docs/#/parsing/string-format)
   */
  transform?: Maybe<Scalars['String']['output']>;
  /** Unix timestamp (seconds since the Unix Epoch) */
  unix?: Maybe<Scalars['Float']['output']>;
  /** UTC time instead of local time */
  utc?: Maybe<Scalars['String']['output']>;
};


export type CustomDateTimezoneArgs = {
  offset: Scalars['String']['input'];
};


export type CustomDateTransformArgs = {
  format: Scalars['String']['input'];
};

export type CustomNumber = {
  __typename?: 'CustomNumber';
  fixed?: Maybe<Scalars['String']['output']>;
  float?: Maybe<Scalars['Float']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  int?: Maybe<Scalars['Int']['output']>;
  string?: Maybe<Scalars['String']['output']>;
};


export type CustomNumberFixedArgs = {
  digits: Scalars['Int']['input'];
};


export type CustomNumberFormatArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  digits?: InputMaybe<Scalars['Int']['input']>;
};

export type Deposit = {
  __typename?: 'Deposit';
  amount: Scalars['Float']['output'];
  createdAt: CustomDate;
  description: Scalars['String']['output'];
  displayStatus: Scalars['String']['output'];
  draw?: Maybe<Scalars['ID']['output']>;
  events: Array<SpinEvent>;
  id: Scalars['ID']['output'];
  jackpot?: Maybe<Scalars['ID']['output']>;
  observation?: Maybe<Scalars['String']['output']>;
  payerE2eId?: Maybe<Scalars['String']['output']>;
  payerId?: Maybe<Scalars['String']['output']>;
  payerKey?: Maybe<Scalars['String']['output']>;
  pixConfig: DepositPixConfig;
  receipt: Scalars['String']['output'];
  receiptFile?: Maybe<Scalars['String']['output']>;
  receiptUri?: Maybe<Scalars['String']['output']>;
  seller: Seller;
  spin?: Maybe<Spin>;
  status: DepositStatusEnum;
  updatedAt: CustomDate;
};

export type DepositEdgesResponse = {
  __typename?: 'DepositEdgesResponse';
  edges: Array<Deposit>;
  pagination?: Maybe<Pagination>;
};

export type DepositPixConfig = {
  __typename?: 'DepositPixConfig';
  pixKey: Scalars['String']['output'];
  pixKeyKind: PixKeyKindEnum;
};

export type DepositResponse = {
  __typename?: 'DepositResponse';
  data: Deposit;
  message: Scalars['String']['output'];
};

export enum DepositStatusEnum {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
  Processing = 'Processing',
  WaitingConfirmation = 'WaitingConfirmation'
}

export type Diamond = {
  __typename?: 'Diamond';
  account?: Maybe<Scalars['Int']['output']>;
  createdAt: CustomDate;
  id: Scalars['ID']['output'];
  origin: DiamondOriginEnum;
  seller: Scalars['ID']['output'];
  status: DiamondStatusEnum;
  updatedAt: CustomDate;
};

export enum DiamondOriginEnum {
  Draw = 'Draw',
  Gift = 'Gift',
  SaleMdoPay = 'SaleMdoPay'
}

export enum DiamondStatusEnum {
  Available = 'Available',
  Burned = 'Burned',
  Used = 'Used'
}

export type Draw = {
  __typename?: 'Draw';
  account?: Maybe<Account>;
  campaign: Campaign;
  createdAt: CustomDate;
  drawnAt?: Maybe<CustomDate>;
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  lote: Scalars['String']['output'];
  odd: Scalars['Float']['output'];
  prize: Scalars['ID']['output'];
  prizeName: Scalars['String']['output'];
  seller?: Maybe<Seller>;
  slot: Array<SlotSymbolEnum>;
  slotDiamond?: Maybe<Scalars['Int']['output']>;
  slotPix?: Maybe<Scalars['Float']['output']>;
  slotTicket?: Maybe<Scalars['Int']['output']>;
  startAt: CustomDate;
  status: DrawStatusEnum;
  totalDiamond: Scalars['Int']['output'];
  totalPix: Scalars['Float']['output'];
  totalTicket: Scalars['Int']['output'];
  updatedAt: CustomDate;
  wheel?: Maybe<WheelUniqueSymbolEnum>;
  wheelPixMultiplier?: Maybe<Scalars['Int']['output']>;
  wheelTicket?: Maybe<Scalars['Int']['output']>;
};

export type DrawEdgesResponse = {
  __typename?: 'DrawEdgesResponse';
  edges: Array<Draw>;
  pagination?: Maybe<Pagination>;
};

export type DrawResponse = {
  __typename?: 'DrawResponse';
  data: Draw;
  message: Scalars['String']['output'];
};

export enum DrawStatusEnum {
  Available = 'Available',
  Drawn = 'Drawn'
}

export enum FilterScopeEnum {
  OnlyActive = 'OnlyActive',
  OnlyTrashed = 'OnlyTrashed',
  WithTrashed = 'WithTrashed'
}

export type InfoApi = {
  __typename?: 'InfoAPI';
  commit: Scalars['String']['output'];
  date?: Maybe<Scalars['String']['output']>;
  environment: Scalars['String']['output'];
  image: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shortCommit: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type Jackpot = {
  __typename?: 'Jackpot';
  amount: Scalars['Float']['output'];
  countDraw?: Maybe<JackpotCountDraw>;
  createdAt: CustomDate;
  description?: Maybe<Scalars['String']['output']>;
  endAt: CustomDate;
  events: Array<SpinEvent>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startAt: CustomDate;
  status: JackpotStatusEnum;
  updatedAt: CustomDate;
  winners?: Maybe<Array<JackpotWinner>>;
};

export type JackpotCountDraw = {
  __typename?: 'JackpotCountDraw';
  /** Quantidade tickets no momento do sorteio */
  count: Scalars['Int']['output'];
  /** Numero de tentativas */
  current: Scalars['Int']['output'];
  /** Numero premiado */
  drawn: Scalars['Int']['output'];
};

export type JackpotEdgesResponse = {
  __typename?: 'JackpotEdgesResponse';
  edges: Array<Jackpot>;
  pagination?: Maybe<Pagination>;
};

export type JackpotResponse = {
  __typename?: 'JackpotResponse';
  data: Jackpot;
  message: Scalars['String']['output'];
};

export enum JackpotStatusEnum {
  /** Jackpot desativado/cancelado */
  Disabled = 'Disabled',
  /** Jackpot sera exibido no APP */
  Enabled = 'Enabled',
  /** Jackpot premio algum vendedor */
  Finished = 'Finished',
  /** Jackpot esta permitindo testar a sorte */
  Rewarding = 'Rewarding'
}

export type JackpotWinner = {
  __typename?: 'JackpotWinner';
  amount: Scalars['Float']['output'];
  rank: Scalars['Int']['output'];
  seller: Seller;
};

export type LoginInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  /**
   * Mesmo tokenAPI concatenando Bearer
   * Forma pratica de copiar token para colar no header do playground
   */
  Authorization: Scalars['String']['output'];
  /** JWT token de acesso API */
  accessToken: Scalars['String']['output'];
  /** Tempo para expirar o token de acesso */
  expiresIn: Scalars['Float']['output'];
  /** Tempo para expirar o refresh token */
  refreshExpiresIn: Scalars['Float']['output'];
  /** O token de atualização é usado para gerar um novo token de acesso */
  refreshToken: Scalars['String']['output'];
};

export type MintDiamondsInput = {
  origin: DiamondOriginEnum;
  quantity: Scalars['Int']['input'];
  seller: Scalars['ID']['input'];
};

export type MintSpinsInput = {
  level?: InputMaybe<Scalars['Int']['input']>;
  origin: SpinOriginEnum;
  quantity: Scalars['Int']['input'];
  seller: Scalars['ID']['input'];
};

export type MintTicketsInput = {
  origin: TicketOriginEnum;
  quantity: Scalars['Int']['input'];
  seller: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  appAddNsuMdoPay: AppAddNsuMdoPayResponse;
  appSignTermsMySeller: SellerResponse;
  /**
   * Public: Cadastra um vendedor e inicia uma sessão
   * Se o vendedor já estiver cadastrado, inicia a sessão
   * Caso seja enviado um telefone diferente do atual do vendedor, é disparado um erro
   */
  appSignUpSellerSession: AppStartSellerSessionResponse;
  appSpinJackpot: AppSpinJackpotResponse;
  appSpinSlot: AppSpinSlotResponse;
  /**
   * Public: O App recebe um ID de sessão então deve iniciar a sessão
   * Cada ID tem um tempo de expiração e só pode ser usado uma vez
   */
  appStartSellerSession: AppAuthSellerSessionResponse;
  appUpdateMySeller: SellerResponse;
  confirmDeposit: SimpleResponse;
  createCampaign: CampaignResponse;
  createDraw: SimpleResponse;
  createJackpot: JackpotResponse;
  createPrize: PrizeResponse;
  createRole: RoleResponse;
  createSeller: SellerResponse;
  /** Admin: Inicia uma sessão de vendedor, já registrado */
  createSellerSession: AppStartSellerSessionResponse;
  createUser: UserResponse;
  login: LoginResponse;
  logout: SimpleResponse;
  mintDiamonds: SimpleResponse;
  mintSpins: SimpleResponse;
  mintTickets: SimpleResponse;
  ping: SimpleResponse;
  refreshToken: RefreshTokenResponse;
  removeCampaign: CampaignResponse;
  removeDraw: DrawResponse;
  removeJackpot: JackpotResponse;
  removePrize: PrizeResponse;
  removeRole: RoleResponse;
  removeSeller: SellerResponse;
  removeUser: UserResponse;
  restoreSeller: SellerResponse;
  restoreUser: UserResponse;
  retryDeposit: SimpleResponse;
  unlinkAccountBySeller: SellerResponse;
  updateCampaign: CampaignResponse;
  updateJackpot: JackpotResponse;
  updateMyUser: UserResponse;
  updateRole: RoleResponse;
  updateSeller: SellerResponse;
  updateStatusJackpot: JackpotResponse;
  updateUser: UserResponse;
  /** Quando enviar (rules=null) sera apagada as regras extras do usuário. */
  updateUserExtraRules: SimpleResponse;
};


export type MutationAppAddNsuMdoPayArgs = {
  cnpj: Scalars['String']['input'];
  nsu: Scalars['String']['input'];
};


export type MutationAppSignTermsMySellerArgs = {
  version: Scalars['Int']['input'];
};


export type MutationAppSignUpSellerSessionArgs = {
  input: AppSignUpSellerSessionInput;
};


export type MutationAppStartSellerSessionArgs = {
  session: Scalars['ID']['input'];
};


export type MutationAppUpdateMySellerArgs = {
  input: UpdateMySellerInput;
};


export type MutationConfirmDepositArgs = {
  id: Scalars['ID']['input'];
  input: ConfirmDepositInput;
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


export type MutationCreateDrawArgs = {
  input: CreateDrawInput;
};


export type MutationCreateJackpotArgs = {
  input: CreateJackpotInput;
};


export type MutationCreatePrizeArgs = {
  input: CreatePrizeInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateSellerArgs = {
  input: CreateSellerInput;
};


export type MutationCreateSellerSessionArgs = {
  seller: Scalars['ID']['input'];
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLogoutArgs = {
  token: Scalars['String']['input'];
};


export type MutationMintDiamondsArgs = {
  input: MintDiamondsInput;
};


export type MutationMintSpinsArgs = {
  input: MintSpinsInput;
};


export type MutationMintTicketsArgs = {
  input: MintTicketsInput;
};


export type MutationPingArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationRemoveCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveDrawArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveJackpotArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemovePrizeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveRoleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveSellerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreSellerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRestoreUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRetryDepositArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnlinkAccountBySellerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateCampaignArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCampaignInput;
};


export type MutationUpdateJackpotArgs = {
  id: Scalars['ID']['input'];
  input: UpdateJackpotInput;
};


export type MutationUpdateMyUserArgs = {
  input: UpdateMyUserInput;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateRoleInput;
};


export type MutationUpdateSellerArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSellerInput;
};


export type MutationUpdateStatusJackpotArgs = {
  drawn?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  status: JackpotStatusEnum;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserInput;
};


export type MutationUpdateUserExtraRulesArgs = {
  abilityRules?: InputMaybe<Array<AbilityRuleInput>>;
  user: Scalars['ID']['input'];
};

export type MyUserResponse = {
  __typename?: 'MyUserResponse';
  abilityRules: Array<AbilityRawRule>;
  roles: Array<Scalars['String']['output']>;
  user: User;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  lastPage: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PixConfig = {
  __typename?: 'PixConfig';
  createdAt: CustomDate;
  pixKey: Scalars['String']['output'];
  pixKeyKind: PixKeyKindEnum;
  updatedAt: CustomDate;
};

export type PixConfigInput = {
  pixKey: Scalars['String']['input'];
  pixKeyKind: PixKeyKindEnum;
};

export enum PixKeyKindEnum {
  Cnpj = 'CNPJ',
  Cpf = 'CPF',
  Email = 'Email',
  Phone = 'Phone',
  Random = 'Random'
}

export type Prize = {
  __typename?: 'Prize';
  createdAt: CustomDate;
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slot: Array<SlotSymbolEnum>;
  slotDiamond?: Maybe<Scalars['Int']['output']>;
  slotPix?: Maybe<Scalars['Float']['output']>;
  slotTicket?: Maybe<Scalars['Int']['output']>;
  status: PrizeStatusEnum;
  suggestedOdd: Scalars['Float']['output'];
  totalPix: Scalars['Float']['output'];
  updatedAt: CustomDate;
  wheel?: Maybe<WheelUniqueSymbolEnum>;
  wheelPixMultiplier?: Maybe<Scalars['Int']['output']>;
  wheelTicket?: Maybe<Scalars['Int']['output']>;
};

export type PrizeEdgesResponse = {
  __typename?: 'PrizeEdgesResponse';
  edges: Array<Prize>;
  pagination?: Maybe<Pagination>;
};

export type PrizeResponse = {
  __typename?: 'PrizeResponse';
  data: Prize;
  message: Scalars['String']['output'];
};

export enum PrizeStatusEnum {
  Disabled = 'Disabled',
  Enabled = 'Enabled'
}

export enum PrizeTypeEnum {
  Loss = 'Loss',
  WinBonus = 'WinBonus',
  WinPix = 'WinPix',
  WinPixAndBonus = 'WinPixAndBonus',
  WinPixMultiplier = 'WinPixMultiplier'
}

export type Query = {
  __typename?: 'Query';
  abilities: Array<Maybe<AbilitySubject>>;
  account: Account;
  accounts: AccountEdgesResponse;
  appDeposits: AppDepositEdgesResponse;
  appJackpots: AppJackpotResponse;
  appMySeller: Seller;
  campaign: Campaign;
  campaignPrizes: CampaignPrizesResponse;
  campaignResumeDraws: CampaignResumeDrawsResponse;
  campaigns: CampaignEdgesResponse;
  clearCache: Scalars['String']['output'];
  deposit: Deposit;
  deposits: DepositEdgesResponse;
  draw: Draw;
  draws: DrawEdgesResponse;
  infoApi: InfoApi;
  jackpot: Jackpot;
  jackpots: JackpotEdgesResponse;
  myIp: Scalars['String']['output'];
  myUser: MyUserResponse;
  prize: Prize;
  prizes: PrizeEdgesResponse;
  role: Role;
  roles: RoleEdgesResponse;
  seller: Seller;
  sellers: SellerEdgesResponse;
  spin: Spin;
  spins: SpinEdgesResponse;
  user: User;
  users: UserEdgesResponse;
};


export type QueryAccountArgs = {
  id: Scalars['Int']['input'];
};


export type QueryAccountsArgs = {
  params?: InputMaybe<SearchAccountsInput>;
};


export type QueryAppDepositsArgs = {
  params?: InputMaybe<SearchDepositsInput>;
};


export type QueryCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCampaignPrizesArgs = {
  id: Scalars['ID']['input'];
  onlyAvailable?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCampaignResumeDrawsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCampaignsArgs = {
  params?: InputMaybe<SearchCampaignsInput>;
};


export type QueryDepositArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDepositsArgs = {
  params?: InputMaybe<SearchDepositsInput>;
};


export type QueryDrawArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDrawsArgs = {
  params?: InputMaybe<SearchDrawsInput>;
};


export type QueryJackpotArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJackpotsArgs = {
  params?: InputMaybe<SearchJackpotsInput>;
};


export type QueryPrizeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPrizesArgs = {
  params?: InputMaybe<SearchPrizesInput>;
};


export type QueryRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRolesArgs = {
  params?: InputMaybe<SearchRolesInput>;
};


export type QuerySellerArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySellersArgs = {
  params?: InputMaybe<SearchSellersInput>;
};


export type QuerySpinArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySpinsArgs = {
  params?: InputMaybe<SearchSpinsInput>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  params?: InputMaybe<SearchUsersInput>;
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  /**
   * Mesmo tokenAPI concatenando Bearer
   * Forma pratica de copiar token para colar no header do playground
   */
  Authorization: Scalars['String']['output'];
  /** JWT token de acesso API */
  accessToken: Scalars['String']['output'];
  /** Tempo para expirar o token de acesso */
  expiresIn: Scalars['Float']['output'];
};

export enum ReportGroupDateEnum {
  DayOfMonth = 'DayOfMonth',
  Month = 'Month',
  Week = 'Week',
  Year = 'Year'
}

export type Role = {
  __typename?: 'Role';
  /**
   * Regras de acesso.
   * Consulte a documentação para mais detalhes: [casl.js.org](https://casl.js.org/v6/en/guide/define-rules)
   */
  abilityRules: Array<AbilityRule>;
  createdAt: CustomDate;
  /** Descrição do nível de acesso. */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Nome do nível de acesso. */
  name: Scalars['String']['output'];
  updatedAt: CustomDate;
};

export type RoleEdgesResponse = {
  __typename?: 'RoleEdgesResponse';
  edges: Array<Role>;
  pagination?: Maybe<Pagination>;
};

export type RoleResponse = {
  __typename?: 'RoleResponse';
  data: Role;
  message: Scalars['String']['output'];
};

export type SaleMdoPay = {
  __typename?: 'SaleMdoPay';
  acquirer?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Float']['output'];
  date: CustomDate;
  installment?: Maybe<Scalars['Int']['output']>;
  logId: Scalars['ID']['output'];
  nsu: Scalars['String']['output'];
  operation: SaleMdoPayOperationEnum;
  provider?: Maybe<Scalars['String']['output']>;
};

export enum SaleMdoPayOperationEnum {
  Credit = 'Credit',
  Debit = 'Debit'
}

export type SearchAccountsInput = {
  and?: InputMaybe<ConditionAccountInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortAccountInput>;
};

export type SearchCampaignsInput = {
  and?: InputMaybe<ConditionCampaignInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortCampaignInput>;
};

export type SearchDepositsInput = {
  and?: InputMaybe<ConditionDepositInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortDepositInput>;
};

export type SearchDrawsInput = {
  and?: InputMaybe<ConditionDrawInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortDrawInput>;
};

export type SearchJackpotsInput = {
  and?: InputMaybe<ConditionJackpotInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortJackpotInput>;
};

export type SearchPrizesInput = {
  and?: InputMaybe<ConditionPrizeInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortPrizeInput>;
};

export type SearchRolesInput = {
  and?: InputMaybe<ConditionRoleInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortRoleInput>;
};

export type SearchSellersInput = {
  and?: InputMaybe<ConditionSellerInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<FilterScopeEnum>;
  sort?: InputMaybe<SortSellerInput>;
};

export type SearchSpinsInput = {
  and?: InputMaybe<ConditionSpinInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<FilterScopeEnum>;
  sort?: InputMaybe<SortSpinInput>;
};

export type SearchUsersInput = {
  and?: InputMaybe<ConditionUserInput>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<FilterScopeEnum>;
  sort?: InputMaybe<SortUserInput>;
};

export type Seller = {
  __typename?: 'Seller';
  account?: Maybe<Account>;
  balance: SellerBalance;
  cpf: Scalars['String']['output'];
  createdAt: CustomDate;
  deletedAt?: Maybe<CustomDate>;
  displayCpf: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  displayPhone: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  events: Array<SpinEvent>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  pixConfig?: Maybe<PixConfig>;
  status: SellerStatusEnum;
  termSignature?: Maybe<SellerTermSignature>;
  updatedAt: CustomDate;
};

export type SellerBalance = {
  __typename?: 'SellerBalance';
  diamonds: Scalars['Int']['output'];
  spins: Scalars['Int']['output'];
  tickets: Scalars['Int']['output'];
};

export type SellerEdgesResponse = {
  __typename?: 'SellerEdgesResponse';
  edges: Array<Seller>;
  pagination?: Maybe<Pagination>;
};

export type SellerResponse = {
  __typename?: 'SellerResponse';
  data: Seller;
  message: Scalars['String']['output'];
};

export enum SellerSessionFromEnum {
  Admin = 'Admin',
  App = 'App',
  Partner = 'Partner',
  Test = 'Test'
}

export enum SellerStatusEnum {
  Active = 'Active',
  Disabled = 'Disabled',
  Pending = 'Pending'
}

export type SellerTermSignature = {
  __typename?: 'SellerTermSignature';
  signedAt: CustomDate;
  version: Scalars['Int']['output'];
};

export type SimpleResponse = {
  __typename?: 'SimpleResponse';
  message: Scalars['String']['output'];
};

export enum SlotSymbolEnum {
  Apple = 'APPLE',
  Banana = 'BANANA',
  Bar = 'BAR',
  Bell = 'BELL',
  Cherry = 'CHERRY',
  Diamond = 'DIAMOND',
  Dollar = 'DOLLAR',
  Grape = 'GRAPE',
  Heart = 'HEART',
  Horseshoe = 'HORSESHOE',
  Leaf = 'LEAF',
  Orange = 'ORANGE',
  Seven = 'SEVEN',
  Watermelon = 'WATERMELON'
}

export type SortAccountInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  erp?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  parentGroup?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortCampaignInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  description?: InputMaybe<SortOrderEnum>;
  endAt?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  ranking?: InputMaybe<SortOrderEnum>;
  rateLoss?: InputMaybe<SortOrderEnum>;
  startAt?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortDepositInput = {
  amount?: InputMaybe<SortOrderEnum>;
  createdAt?: InputMaybe<SortOrderEnum>;
  payerE2eId?: InputMaybe<SortOrderEnum>;
  payerId?: InputMaybe<SortOrderEnum>;
  payerKey?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortDrawInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  drawnAt?: InputMaybe<SortOrderEnum>;
  level?: InputMaybe<SortOrderEnum>;
  lote?: InputMaybe<SortOrderEnum>;
  odd?: InputMaybe<SortOrderEnum>;
  prizeName?: InputMaybe<SortOrderEnum>;
  slotDiamond?: InputMaybe<SortOrderEnum>;
  slotPix?: InputMaybe<SortOrderEnum>;
  slotTicket?: InputMaybe<SortOrderEnum>;
  startAt?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  totalDiamond?: InputMaybe<SortOrderEnum>;
  totalPix?: InputMaybe<SortOrderEnum>;
  totalTicket?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
  wheel?: InputMaybe<SortOrderEnum>;
  wheelPixMultiplier?: InputMaybe<SortOrderEnum>;
  wheelTicket?: InputMaybe<SortOrderEnum>;
};

export type SortJackpotInput = {
  amount?: InputMaybe<SortOrderEnum>;
  createdAt?: InputMaybe<SortOrderEnum>;
  endAt?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  startAt?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export enum SortOrderEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SortPrizeInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  level?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  slot?: InputMaybe<SortOrderEnum>;
  slotDiamond?: InputMaybe<SortOrderEnum>;
  slotPix?: InputMaybe<SortOrderEnum>;
  slotTicket?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  totalPix?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
  wheel?: InputMaybe<SortOrderEnum>;
  wheelPixMultiplier?: InputMaybe<SortOrderEnum>;
  wheelTicket?: InputMaybe<SortOrderEnum>;
};

export type SortRoleInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortSellerInput = {
  account?: InputMaybe<SortOrderEnum>;
  cpf?: InputMaybe<SortOrderEnum>;
  createdAt?: InputMaybe<SortOrderEnum>;
  deletedAt?: InputMaybe<SortOrderEnum>;
  email?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
  phone?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortSpinInput = {
  code?: InputMaybe<SortOrderEnum>;
  createdAt?: InputMaybe<SortOrderEnum>;
  drawnAt?: InputMaybe<SortOrderEnum>;
  level?: InputMaybe<SortOrderEnum>;
  origin?: InputMaybe<SortOrderEnum>;
  prizeType?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
};

export type SortUserInput = {
  createdAt?: InputMaybe<SortOrderEnum>;
  deletedAt?: InputMaybe<SortOrderEnum>;
  status?: InputMaybe<SortOrderEnum>;
  updatedAt?: InputMaybe<SortOrderEnum>;
  username?: InputMaybe<SortOrderEnum>;
};

export type Spin = {
  __typename?: 'Spin';
  account?: Maybe<Scalars['Int']['output']>;
  cnpj?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt: CustomDate;
  drawnAt?: Maybe<CustomDate>;
  events: Array<SpinEvent>;
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  origin: SpinOriginEnum;
  prizeType?: Maybe<PrizeTypeEnum>;
  saleMdoPay?: Maybe<SaleMdoPay>;
  seller: Scalars['ID']['output'];
  status: SpinStatusEnum;
  updatedAt: CustomDate;
};

export type SpinEdgesResponse = {
  __typename?: 'SpinEdgesResponse';
  edges: Array<Spin>;
  pagination?: Maybe<Pagination>;
};

export type SpinEvent = {
  __typename?: 'SpinEvent';
  createdAt: CustomDate;
  description: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SpinGift = {
  __typename?: 'SpinGift';
  diamonds: Scalars['Int']['output'];
  spins: Scalars['Int']['output'];
};

export enum SpinOriginEnum {
  Bonus = 'Bonus',
  Gift = 'Gift',
  SaleMdoPay = 'SaleMdoPay'
}

export type SpinResponse = {
  __typename?: 'SpinResponse';
  data: Spin;
  message: Scalars['String']['output'];
};

export enum SpinStatusEnum {
  Loss = 'Loss',
  Played = 'Played',
  Waiting = 'Waiting',
  Won = 'Won'
}

export type Subscription = {
  __typename?: 'Subscription';
  pong: SimpleResponse;
};


export type SubscriptionPongArgs = {
  minLength?: InputMaybe<Scalars['Int']['input']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  account?: Maybe<Scalars['Int']['output']>;
  createdAt: CustomDate;
  id: Scalars['ID']['output'];
  jackpot?: Maybe<Scalars['ID']['output']>;
  origin: TicketOriginEnum;
  seller: Scalars['ID']['output'];
  status: TicketStatusEnum;
  updatedAt: CustomDate;
};

export enum TicketOriginEnum {
  BurnDiamond = 'BurnDiamond',
  Draw = 'Draw',
  Gift = 'Gift'
}

export enum TicketStatusEnum {
  Available = 'Available',
  Burned = 'Burned',
  Used = 'Used'
}

export type UpdateCampaignInput = {
  accounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ranking?: InputMaybe<Scalars['Int']['input']>;
  rateLoss?: InputMaybe<Scalars['Float']['input']>;
  sellers?: InputMaybe<Array<Scalars['ID']['input']>>;
  startAt?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<CampaignStatusEnum>;
};

export type UpdateJackpotInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateMySellerInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pixConfig?: InputMaybe<PixConfigInput>;
};

export type UpdateMyUserInput = {
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoleInput = {
  abilityRules?: InputMaybe<Array<AbilityRuleInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSellerInput = {
  account?: InputMaybe<Scalars['Int']['input']>;
  cpf?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pixConfig?: InputMaybe<PixConfigInput>;
  status?: InputMaybe<SellerStatusEnum>;
};

export type UpdateUserInput = {
  password?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<UserStatusEnum>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  abilityExtraRules?: Maybe<Array<AbilityRule>>;
  createdAt: CustomDate;
  deletedAt?: Maybe<CustomDate>;
  id: Scalars['ID']['output'];
  roles: Array<Scalars['String']['output']>;
  status: UserStatusEnum;
  updatedAt: CustomDate;
  /** The login of the user */
  username: Scalars['String']['output'];
};

export type UserEdgesResponse = {
  __typename?: 'UserEdgesResponse';
  edges: Array<User>;
  pagination?: Maybe<Pagination>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  id: Scalars['ID']['output'];
  username: Scalars['String']['output'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  data: User;
  message: Scalars['String']['output'];
};

export enum UserStatusEnum {
  Disabled = 'Disabled',
  Enabled = 'Enabled'
}

export enum WheelSymbolEnum {
  LossA = 'LOSS_A',
  LossB = 'LOSS_B',
  Pix_2XA = 'PIX_2X_A',
  Pix_2XB = 'PIX_2X_B',
  Pix_2XC = 'PIX_2X_C',
  Pix_3XA = 'PIX_3X_A',
  Pix_5XA = 'PIX_5X_A',
  TicketGold_1XA = 'TICKET_GOLD_1X_A',
  TicketGold_1XB = 'TICKET_GOLD_1X_B',
  TicketGold_2XA = 'TICKET_GOLD_2X_A',
  TicketGold_2XB = 'TICKET_GOLD_2X_B',
  TicketGold_3XA = 'TICKET_GOLD_3X_A'
}

export enum WheelUniqueSymbolEnum {
  Loss = 'LOSS',
  Pix_2X = 'PIX_2X',
  Pix_3X = 'PIX_3X',
  Pix_5X = 'PIX_5X',
  TicketGold_1X = 'TICKET_GOLD_1X',
  TicketGold_2X = 'TICKET_GOLD_2X',
  TicketGold_3X = 'TICKET_GOLD_3X'
}
