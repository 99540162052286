<h1 mat-dialog-title class="text-green-600">Como participar</h1>
<div mat-dialog-content>
  <div class="flex flex-col items-center px-4">
    <p class="text-gray-700 text-xs text-justify mb-1" cdkTrapFocus>
      Para utilizar o Caça PIX MDO Pay, basta realizar vendas transacionadas
      pela maquininha MDO Pay no valor mínimo de R$ 750,00 parcelado em 10x ou
      mais e adicionar o código CV na maquininha.
    </p>
    <p class="text-gray-700 text-xs text-justify">
      *Atenção: Apenas os CV's de vendas realizadas após o dia
      <b>01/02/2024</b> serão considerados válidos para participação.
    </p>
    <p class="text-gray-700 text-xs text-justify">
      Veja abaixo nas imagems onde você pode encontrar o código CV.
    </p>
    <div class="flex flex-col sm:flex-row space-y-2 sm:space-x-2 sm:space-y-0">
      <img src="/assets/img/nfs.jpg" alt="Onde encontrar o código CV" />
    </div>
  </div>

  <div class="flex justify-center items-center pt-2 flex-col">
    <button mat-button (click)="dialogRef.close()">Fechar</button>
  </div>
</div>
