<div class="w-full h-full flex flex-col px-8 py-4 items-center justify-center">
  <div class="flex flex-col justify-center items-center">
    <div class="blue-2-border rounded-full p-2 hard-red-bg mt-2">
      <div class="w-40 h-40 rounded-full pos-bg"></div>
    </div>
    <mat-spinner
      diameter="182"
      style="margin-top: -182px"
      strokeWidth="5"></mat-spinner>
  </div>

  <span class="title-section font-libel text-xl mt-4">{{ message }}</span>
</div>
