import { BehaviorSubject, catchError, tap } from 'rxjs';
import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import {
  Account,
  AppJackpot,
  Seller,
  SellerBalance,
} from '../../../../graphql/generated';

type AppSetting = {
  soundOn: boolean;
  soundBG: boolean; // musica de fundo da home
};

const initialSetting: AppSetting = {
  soundOn: true,
  soundBG: true,
};

const initialBalance: SellerBalance = {
  spins: 0,
  tickets: 0,
  diamonds: 0,
};

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private settings = new BehaviorSubject<AppSetting>(initialSetting);
  private seller = new BehaviorSubject<Seller | null>(null);
  private account = new BehaviorSubject<Account | null>(null);
  private balance = new BehaviorSubject<SellerBalance>(initialBalance);
  private currentJackpot = new BehaviorSubject<AppJackpot | null>(null);
  private prevJackpot = new BehaviorSubject<AppJackpot | null>(null);

  public readonly settings$ = this.settings.asObservable();
  public readonly seller$ = this.seller.asObservable();
  public readonly account$ = this.account.asObservable();
  public readonly balance$ = this.balance.asObservable();
  public readonly currentJackpot$ = this.currentJackpot.asObservable();
  public readonly prevJackpot$ = this.prevJackpot.asObservable();

  constructor(
    private readonly _api: ApiService,
    private readonly _auth: AuthService
  ) {}

  get sellerValue(): Seller | null {
    return this.seller.getValue();
  }

  get currentJackpotValue(): AppJackpot | null {
    return this.currentJackpot.getValue();
  }

  get settingsValue(): AppSetting {
    return this.settings.getValue();
  }

  get balanceValue(): SellerBalance {
    return this.balance.getValue();
  }

  setSeller(data: Seller | undefined) {
    this.seller.next(data || null);

    if (data?.account) {
      this.account.next(data.account);
    }

    if (data?.balance) {
      this.balance.next(data.balance);
    }
  }

  setCurrentJackpot(data: AppJackpot | null | undefined) {
    this.currentJackpot.next(data || null);
  }

  setPrevJackpot(data: AppJackpot | null | undefined) {
    this.prevJackpot.next(data || null);
  }

  loadData() {
    return this._api.loadData().pipe(
      tap(
        data => {
          if (!data) {
            console.error('Error load data', data);
            return this.logout();
          }

          this.setSeller(data.seller);
          this.setCurrentJackpot(data.currentJackpot);
          this.setPrevJackpot(data.prevJackpot);
        },
        catchError(e => {
          console.log('Error load data', e);
          this.logout();
          return e;
        })
      )
    );
  }

  loadSettings() {
    const settings = localStorage.getItem('mdopix_settings');

    if (settings) {
      const current = this.settings.getValue();
      const storageSettings = JSON.parse(settings);
      this.settings.next({ ...current, ...storageSettings });
    }
  }

  setSettings(data: Partial<AppSetting>) {
    const current = this.settings.getValue();
    const newSettings = { ...current, ...data };

    this.settings.next(newSettings);
    localStorage.setItem('mdopix_settings', JSON.stringify(newSettings));
  }

  setPartialSeller(data: Partial<Seller>) {
    const current = this.seller.getValue();

    if (current) {
      this.seller.next({ ...current, ...data });
    }
  }

  resetData() {
    this.seller.next(null);
    this.account.next(null);
    this.balance.next(initialBalance);
  }

  logout() {
    this.resetData();
    this._auth.logout();
  }

  incrementBalance(param: Partial<SellerBalance>) {
    const current: any = { ...this.balance.getValue() };
    const keys = Array.from<keyof SellerBalance>(Object.keys(param) as any);

    keys.forEach(key => {
      current[key] = (current[key] || 0) + (param[key] || 0);
    });

    // a cada 5 diamantes ganhos, o usuário ganha 1 ticket
    if (keys.includes('diamonds')) {
      const tickets = Math.floor(current.diamonds / 5);
      current.tickets += tickets;
      current.diamonds = current.diamonds % 5;
    }

    this.balance.next(current);
  }
}
