<h2 mat-dialog-title class="font-libel">Informe o CNPJ</h2>
<div class="p-4 content-dialog">
  <mat-dialog-content>
    <p class="title-section">
      Por favor informe o CNPJ da loja a qual este CV pertence.
    </p>
    <small class="title-section"
      >Nós só vamos pedir esta informação na primeira vez que você resgatar um
      código CV.</small
    >

    <mat-form-field cdkTrapFocus class="w-full">
      <mat-label>CNPJ</mat-label>
      <input mask="00.000.000/0000-00" type="tel" matInput [(ngModel)]="cnpj" />
    </mat-form-field>

    <mat-accordion>
      <mat-expansion-panel hideToggle class="bg-orange-200">
        <mat-expansion-panel-header>
          <mat-panel-title> Como encontrar o CNPJ?</mat-panel-title>
        </mat-expansion-panel-header>
        <p class="text-xs">
          Você pode encontrar o CNPJ no próprio recibo de compras de onde está
          tirando o CV, veja na imagem abaixo:
        </p>
        <div class="w-full flex flex-row justify-center items-center">
          <img
            src="/assets/img/comprovante-cnpj.jpg"
            alt="Onde encontrar o CNPJ" />
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      class="title-section"
      style="color: #fff"
      mat-button
      (click)="dialogRef.close(null)">
      Fechar
    </button>
    <button
      class="gold-button"
      mat-button
      [mat-dialog-close]="cnpj"
      cdkFocusInitial>
      Continuar
    </button>
  </mat-dialog-actions>
</div>
