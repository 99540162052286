import { delay, Subject, takeUntil } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AppStateService } from '../../core/services/app-state.service';
import { SoundService } from '../../core/services/sound.service';

@Component({
  selector: 'app-bg-sound',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  styles: [
    `
      :host {
        position: absolute;
        z-index: 50000;
        top: 125px;
        right: 10px;

        @media screen and (min-width: 768px) {
          top: 16px;
        }
      }
    `,
  ],
  templateUrl: './bg-sound.component.html',
})
export class BgSoundComponent implements OnInit, OnDestroy {
  public soundBG = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly state: AppStateService,
    private readonly soundService: SoundService
  ) {}

  ngOnInit(): void {
    this.soundService.loadAudioBG().then(() => {
      this.state.settings$
        .pipe(takeUntil(this.unsubscribe$), delay(3000))
        .subscribe(settings => {
          this.soundBG = settings.soundBG;

          if (this.soundBG) {
            this.soundService.playSound();
          } else {
            this.soundService.stopSound();
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleSound() {
    this.soundBG = !this.soundBG;
    this.state.setSettings({ soundBG: this.soundBG });
  }
}
