import { Subject, takeUntil } from 'rxjs';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { AppStateService } from '../../core/services/app-state.service';
import { SellerBalance } from '../../../../graphql/generated';
import { ChancesTagComponent } from '../chances-tag/chances-tag.component';
import { CoinIconComponent } from '../coin-icon/coin-icon.component';

@Component({
  selector: 'app-section-hunt-pix',
  standalone: true,
  imports: [
    CommonModule,
    CoinIconComponent,
    MatButtonModule,
    RouterLink,
    ChancesTagComponent,
  ],
  templateUrl: './section-hunt-pix.component.html',
  styleUrls: ['./section-hunt-pix.component.scss'],
})
export class SectionHuntPixComponent implements OnInit, OnDestroy {
  public balance: SellerBalance | null = null;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private stateService: AppStateService) {}

  ngOnInit(): void {
    this.stateService.balance$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(balance => (this.balance = balance));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
