<div
  *ngIf="showSection"
  class="section-terms-and-conditions w-full p-4 flex flex-row justify-center items-center pb-24">
  <button
    (click)="openTermAndConditionsDialog(true)"
    class="text-sm title-yellow"
    color="primary"
    mat-button>
    Termos de Privacidade e Regulamento
  </button>
</div>
