<div class="mt-12 p-4" id="unavailable">
  <div class="p-4 flex flex-col justify-around items-center">
    <div class="blue-2-border rounded-full p-2 hard-red-bg mt-2">
      <div class="w-40 h-40 rounded-full pos-bg"></div>
    </div>

    <h2 class="title-section font-libel text-2xl mb-2">
      Suas chances acabaram!
    </h2>
    <span class="title-section mt-4">Mas não fique triste.</span>
    <span class="title-section text-center mb-4"
      >Basta resgatar mais códigos CV para ganhar novas chances de jogar no
      <strong>Caça Pix MDOPay.</strong></span
    >

    <button class="gold-button" mat-button type="button" routerLink="/inicio">
      Voltar ao início
    </button>
  </div>
</div>
