import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderTMControl } from './ticket-machine.control';

@Component({
  selector: 'app-ticket-machine',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ticket-machine.component.html',
  styleUrls: ['./ticket-machine.component.scss'],
})
export class TicketMachineComponent implements OnInit {
  private readonly loaderTMControl = new LoaderTMControl();

  ngOnInit(): void {
    this.loaderTMControl.init();
  }
}
