<div class="login-page w-full h-full flex flex-col items-center justify-center">
  <div
    *ngIf="loading"
    class="w-full flex flex-col items-center justify-center p-4">
    <mat-spinner></mat-spinner>
    <span class="text-sm mt-2 title-section">Aguarde</span>
  </div>

  <div
    *ngIf="errorMessage"
    class="w-full flex flex-col items-center justify-center p-16">
    <span class="title-section text-2xl text-center mb-4 font-bold">Erro!</span>
    <span class="title-section text-xl text-center">{{ errorMessage }}</span>

    <div class="flex flex-row justify-center items-center">
      <button
        routerLink="/login"
        class="mt-4 mx-auto sec-button"
        mat-flat-button
        color="primary">
        Login
      </button>
    </div>
  </div>
</div>
