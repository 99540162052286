import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { ToolbarComponent } from '../components/toolbar/toolbar.component';
import { LogoComponent } from '../components/logo/logo.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { AppStateService } from '../core/services/app-state.service';
import { ApiService } from '../core/services/api.service';
import { PixKeyKindEnum, Seller } from '../../../graphql/generated';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-edit-profile',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LogoComponent,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,

    LoadingComponent,
    NgxMaskDirective,
    ToolbarComponent,
  ],
  providers: [provideNgxMask()],
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit, OnDestroy {
  public version = environment.clientVersion;
  public loading = false;
  public form: FormGroup;
  public seller: Seller | null = null;
  public pixKeyMask = '';
  public pixKeyPrefix: string;
  protected readonly PixKeyKindEnum = PixKeyKindEnum;

  private sellerSubscription: Subscription;

  constructor(
    private _router: Router,
    private stateService: AppStateService,
    private api: ApiService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.sellerSubscription = this.stateService.seller$.subscribe(seller => {
      this.seller = seller;
      if (this.seller?.pixConfig?.pixKeyKind) {
        this.changeMask(this.seller.pixConfig.pixKeyKind);
      }

      this.buildForm();
    });
  }

  ngOnDestroy() {
    this.sellerSubscription.unsubscribe();
  }

  private buildForm() {
    this.form = new FormGroup({
      name: new FormControl(this.seller?.name || '', [Validators.required]),
      phone: new FormControl(this.seller?.phone || '', [Validators.required]),
      pixConfig: new FormGroup({
        pixKeyKind: new FormControl(this.seller?.pixConfig?.pixKeyKind || '', [
          Validators.required,
        ]),
        pixKey: new FormControl(this.seller?.pixConfig?.pixKey || '', [
          Validators.required,
        ]),
      }),
    });

    if (this.seller?.pixConfig?.pixKeyKind) {
      const kindControl = this.form.get('pixConfig.pixKeyKind') as FormControl;
      kindControl.disable();
    }
    if (this.seller?.pixConfig?.pixKey) {
      const keyControl = this.form.get('pixConfig.pixKey') as FormControl;
      keyControl.disable();
    }
  }

  signIn() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    const input = this.form.getRawValue();

    this.api.updateSeller(input).subscribe({
      next: resp => {
        this.loading = false;

        if (resp) {
          this.stateService.resetData();
          this._router.navigate(['/inicio']);
        }
      },
      error: error => {
        this.loading = false;
        let message = error.message;

        const info = error?.graphQLErrors?.[0];
        if (
          info?.extensions?.code === 'validation_failed' &&
          info?.extensions?.validation?.length > 0
        ) {
          // TODO melhorar a exibição de erros
          info?.extensions?.validation?.forEach((item: any) => {
            console.log('Warning validation:', item);
            message += ' / ' + item.message;
          });
        }

        this._snackBar.open(message, '', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
    });
  }

  changePixType(event: MatSelectChange) {
    const groupPix = this.form.get('pixConfig') as FormGroup;
    const keyControl = this.form.get('pixConfig.pixKey') as FormControl;

    if (event.value === PixKeyKindEnum.Cpf && this.seller?.cpf) {
      this.changeMask(PixKeyKindEnum.Cpf);
      groupPix?.get('pixKey')?.setValue(this.seller.cpf);
      keyControl.disable();
    } else if (event.value === PixKeyKindEnum.Phone) {
      keyControl.enable();
      this.changeMask(PixKeyKindEnum.Phone);
      const phoneValue = this.form.get('phone')?.value;

      if (phoneValue) {
        groupPix?.get('pixKey')?.setValue(phoneValue || '');
      } else if (this.seller?.phone) {
        groupPix?.get('pixKey')?.setValue(this.seller.phone);
      } else {
        groupPix?.get('pixKey')?.setValue('');
      }
    } else if (event.value === PixKeyKindEnum.Email) {
      keyControl.enable();
      this.changeMask(PixKeyKindEnum.Email);
      groupPix?.get('pixKey')?.setValue(this.seller?.email || '');
    } else {
      keyControl.enable();
      this.changeMask(PixKeyKindEnum.Random);
      groupPix?.get('pixKey')?.setValue('');
    }
  }

  changeMask(kind: PixKeyKindEnum) {
    switch (kind) {
      case PixKeyKindEnum.Cnpj: {
        this.pixKeyMask = '00.000.000/0000-00';
        this.pixKeyPrefix = '';
        break;
      }
      case PixKeyKindEnum.Cpf: {
        this.pixKeyMask = '000.000.000-00';
        this.pixKeyPrefix = '';
        break;
      }
      case PixKeyKindEnum.Phone: {
        this.pixKeyMask = '(00) 00000 0000';
        this.pixKeyPrefix = '+55';
        break;
      }
      default:
      case PixKeyKindEnum.Email:
      case PixKeyKindEnum.Random: {
        this.pixKeyMask = '';
        this.pixKeyPrefix = '';
        break;
      }
    }

    return undefined;
  }

  logout() {
    this.stateService.logout();
  }
}
