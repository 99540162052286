declare const $: any;

/**
 * @class Slot
 * @constructor
 */
export class Slot {
  public speed: number = 0; //speed of the slot at any point of time
  public step: number; //speed will increase at this rate
  public si?: number; //holds setInterval object for the given slot
  public el: string; //dom element of the slot
  public maxSpeed: number; //max speed this slot can have
  public pos: number | null = null; //final position of the slot

  public onCompleted: () => void = () => {};

  constructor(el: string, max: number, step: number) {
    this.step = step;
    this.el = el;
    this.maxSpeed = max;

    $(el).pan({
      fps: 30,
      dir: 'down',
    });

    $(el).spStop();
  }

  start() {
    $(this.el).addClass('motion');
    $(this.el).spStart();

    this.si = window.setInterval(() => {
      if (this.speed < this.maxSpeed) {
        this.speed += this.step;
        $(this.el).spSpeed(this.speed);
      }
    }, 100);
  }

  private clearSi() {
    if (this.si) clearInterval(this.si);
  }

  stop(r: number) {
    const limit = 30;

    this.clearSi();

    this.si = window.setInterval(() => {
      if (this.speed > limit) {
        this.speed -= this.step;
        $(this.el).spSpeed(this.speed);
      }

      if (this.speed <= limit) {
        // _this.finalPos(_this.el);
        this.finalPos(r);
        $(this.el).spSpeed(0);
        $(this.el).spStop();
        this.clearSi();
        $(this.el).removeClass('motion');
        this.speed = 0;
      }
    }, 100);
  }

  finalPos(fpos: number) {
    const el = this.el;
    // let pos = $(el).css('background-position'); //for some unknown reason, this does not work in IE
    const bgPos = '0 ' + fpos + 'px';

    $(el).animate(
      {
        backgroundPosition: bgPos,
      },
      {
        duration: 0,
        complete: () => {
          this.onCompleted();
        },
      }
    );
  }

  reset() {
    const el_id = $(this.el).attr('id');
    $._spritely.instances[el_id].t = 0;
    $(this.el).css('background-position', '0px 4px');
    this.speed = 0;
  }
}
