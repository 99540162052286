<form
  [formGroup]="form"
  (ngSubmit)="signIn()"
  class="w-full h-full flex flex-col px-8 py-4 items-center justify-center"
  *ngIf="!loading">
  <app-logo sizeClass="w-40"></app-logo>

  <span class="title-section font-libel text-2xl mt-2 mb-4"
    >Bem vindo ao MDOPay CaçaPix</span
  >

  <span class="title-section text-sm text-center mb-1"
    >Preencha os campos abaixo para acessar.</span
  >

  <mat-form-field>
    <mat-label>CPF</mat-label>
    <input mask="000.000.000-00" formControlName="cpf" type="tel" matInput />
    <mat-error *ngIf="form.get('cpf')?.invalid"
      >Informe um CPF válido.
    </mat-error>
  </mat-form-field>

  <mat-form-field class="mt-2">
    <mat-label>WhatsApp</mat-label>
    <input
      prefix="+55"
      mask="(00) 00000 0000"
      formControlName="phone"
      type="tel"
      matInput />
    <mat-error *ngIf="form.get('phone')?.invalid"
      >Informe um número de WhatsApp.
    </mat-error>
  </mat-form-field>

  <button
    class="gold-button w-full rounded-3xl text-xl leading-9 font-libel"
    mat-button
    type="submit">
    Entrar
  </button>
</form>

<app-loading *ngIf="loading" message="Aguarde, validando..."></app-loading>
