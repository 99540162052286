import {
  TicketMachineControlType,
  LoaderControlTMType,
} from './ticket-machine.type';

declare const ticketMachineControl: TicketMachineControlType;
declare const loaderTMControl: LoaderControlTMType;

export class TicketMachineControl {
  start() {
    ticketMachineControl.start();
  }

  setTickets(tickets: number) {
    ticketMachineControl.setTickets(tickets);
  }
}

export class LoaderTMControl {
  init() {
    loaderTMControl.init();
  }
}
