import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

import {
  Seller,
  SellerResponse,
  AppSignUpSellerSessionInput,
  AppStartSellerSessionResponse,
  AppAuthSellerSessionResponse,
  UpdateMySellerInput,
  AppAddNsuMdoPayResponse,
  AppJackpot,
  AppJackpotResponse,
  AppSpinJackpotResponse,
  AppSpinSlotResponse,
  AppDepositEdgesResponse,
  AppDeposit,
} from '../../../../graphql/generated';
import { SlotResult } from '../../play/types';
import { FRAGMENT_JACKPOT, FRAGMENT_SELLER } from './fragments';

type DataResponse = {
  seller: Seller;
  currentJackpot: AppJackpot | undefined;
  prevJackpot: AppJackpot | undefined;
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(public readonly apollo: Apollo) {}

  public signUp(
    cpf: string,
    phone: string
  ): Observable<AppStartSellerSessionResponse | undefined> {
    const MUTATION_SIGN_UP = gql`
      mutation startSession($input: AppSignUpSellerSessionInput!) {
        appSignUpSellerSession(input: $input) {
          message
          session
          link
          expiresAt {
            iso
          }
        }
      }
    `;

    const input: AppSignUpSellerSessionInput = { cpf, phone };

    return this.apollo
      .mutate<{
        appSignUpSellerSession: AppStartSellerSessionResponse;
      }>({ mutation: MUTATION_SIGN_UP, variables: { input } })
      .pipe(map(result => result.data?.appSignUpSellerSession));
  }

  startSession(
    session: string
  ): Observable<AppAuthSellerSessionResponse | undefined> {
    const MUTATION_START_SESSION = gql`
      mutation startSession($session: ID!) {
        appStartSellerSession(session: $session) {
          accessToken
          expiresAt {
            iso
            timestamp
          }
        }
      }
    `;

    return this.apollo
      .mutate<{
        appStartSellerSession: AppAuthSellerSessionResponse;
      }>({ mutation: MUTATION_START_SESSION, variables: { session } })
      .pipe(map(result => result.data?.appStartSellerSession));
  }

  acceptTerms(version: number): Observable<SellerResponse | undefined> {
    const MUTATION_SIGN_TERMS_MY_SELLER = gql`
      mutation aceptTerms($version: Int!) {
        appSignTermsMySeller(version: $version) {
          message
          data {
            termSignature {
              signedAt {
                iso
              }
              version
            }
          }
        }
      }
    `;

    return this.apollo
      .mutate<{
        appSignTermsMySeller: SellerResponse;
      }>({ mutation: MUTATION_SIGN_TERMS_MY_SELLER, variables: { version } })
      .pipe(map(result => result.data?.appSignTermsMySeller));
  }

  updateSeller(
    input: UpdateMySellerInput
  ): Observable<SellerResponse | undefined> {
    const MUTATION_SIGN_TERMS_MY_SELLER = gql`
      mutation update($input: UpdateMySellerInput!) {
        appUpdateMySeller(input: $input) {
          message
        }
      }
    `;

    return this.apollo
      .mutate<{
        appUpdateMySeller: SellerResponse;
      }>({ mutation: MUTATION_SIGN_TERMS_MY_SELLER, variables: { input } })
      .pipe(map(result => result.data?.appUpdateMySeller));
  }

  loadData(): Observable<DataResponse> {
    const QUERY_DATA = gql`
      query HomeData {
        appMySeller {
          ...SellerFrag
        }
        appJackpots {
          current {
            ...AppJackpotFrag
          }
          prev {
            ...AppJackpotFrag
            winners {
              rank
              seller {
                id
                displayName
                account {
                  name
                }
              }
              amount
            }
          }
        }
      }

      ${FRAGMENT_SELLER}
      ${FRAGMENT_JACKPOT}
    `;

    return this.apollo
      .query<{
        appMySeller: Seller;
        appJackpots: AppJackpotResponse;
      }>({ query: QUERY_DATA })
      .pipe(
        map(result => {
          return {
            seller: result.data?.appMySeller,
            prevJackpot: result.data?.appJackpots.prev,
            currentJackpot: result.data?.appJackpots.current,
          } as any;
        })
      );
  }

  mySeller(): Observable<Seller | undefined> {
    const QUERY_MY_SELLER = gql`
      query MySession {
        appMySeller {
          ...SellerFrag
        }
      }

      ${FRAGMENT_SELLER}
    `;

    return this.apollo
      .query<{
        appMySeller: Seller;
      }>({ query: QUERY_MY_SELLER })
      .pipe(map(result => result.data?.appMySeller));
  }

  rescueNsu(
    nsu: string,
    cnpj: string
  ): Observable<AppAddNsuMdoPayResponse | undefined> {
    const MUTATION_ADD_NSU_MDOPAY = gql`
      mutation update($nsu: String!, $cnpj: String!) {
        appAddNsuMdoPay(nsu: $nsu, cnpj: $cnpj) {
          message
          gift {
            diamonds
            spins
          }
        }
      }
    `;

    return this.apollo
      .mutate<{
        appAddNsuMdoPay: AppAddNsuMdoPayResponse;
      }>({ mutation: MUTATION_ADD_NSU_MDOPAY, variables: { nsu, cnpj } })
      .pipe(map(result => result.data?.appAddNsuMdoPay));
  }

  spinJackpot(): Observable<AppSpinJackpotResponse | undefined> {
    const MUTATION_JACKPOT = gql`
      mutation SpinJackpot {
        appSpinJackpot {
          message
          amountPix
          won
        }
      }
    `;

    // simular resposta fake
    // return of({
    //   message: 'ok',
    //   amountPix: 150.4,
    //   won: true,
    // } as AppSpinJackpotResponse);

    return this.apollo
      .mutate<{
        appSpinJackpot: AppSpinJackpotResponse;
      }>({ mutation: MUTATION_JACKPOT })
      .pipe(map(result => result.data?.appSpinJackpot));
  }

  spinSlot(): Observable<SlotResult> {
    const MUTATION_SLOT = gql`
      mutation SpinSlot {
        appSpinSlot {
          message
          label
          slot
          slotDiamond
          slotPix
          slotTicket
          id

          totalDiamond
          totalPix
          totalTicket

          wheel
          wheelPixMultiplier
          wheelTicket
        }
      }
    `;

    return this.apollo
      .mutate<{
        appSpinSlot: AppSpinSlotResponse;
      }>({ mutation: MUTATION_SLOT })
      .pipe(
        map(result => result.data?.appSpinSlot),
        map(result => {
          if (!result) {
            throw new Error('No result slot');
          }

          return {
            ...result,
            slot: result.slot,

            slotPix: result.slotPix,
            slotDiamond: result.slotDiamond,
            slotTicket: result.slotTicket,

            totalPix: result.totalPix || 0,
            totalDiamond: result.totalDiamond || 0,
            totalTicket: result.totalTicket || 0,

            wheel: result.wheel,
            wheelPixMultiplier: result.wheelPixMultiplier,
            wheelTicket: result.wheelTicket,
          } as SlotResult;
        })
      );
  }

  loadDeposits(): Observable<Array<AppDeposit>> {
    const QUERY_DEPOSITS = gql`
      query deposits {
        appDeposits(params: { sort: { createdAt: Desc } }) {
          edges {
            id
            description
            receiptUri
            amount
            pixConfig {
              pixKey
              pixKeyKind
            }
            status
            displayStatus
            createdAt {
              iso
              transform(format: "DD/MM/YYYY hh:mm")
            }
          }
        }
      }
    `;

    return this.apollo
      .query<{
        appDeposits: AppDepositEdgesResponse;
      }>({ query: QUERY_DEPOSITS })
      .pipe(map(result => result.data?.appDeposits.edges));
  }
}
