import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
  selector: 'app-terms-and-condition-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule,
    A11yModule,
  ],
  templateUrl: './terms-and-condition-dialog.component.html',
  styleUrls: ['./terms-and-condition-dialog.component.scss'],
})
export class TermsAndConditionDialogComponent {
  public shake = false;
  public approveTerms = false;

  constructor(
    public dialogRef: MatDialogRef<TermsAndConditionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { hiddeCheckbox: boolean }
  ) {}

  onNoClick() {
    this.dialogRef.close(false);
  }

  onYesClick() {
    if (this.approveTerms) {
      this.dialogRef.close(true);
    } else {
      this.shake = true;
      setTimeout(() => {
        this.shake = false;
      }, 1000);
    }
  }
}
