<div
  *ngIf="enableDemoMode"
  class="fixed z-30 bg-red-500 text-center text-white w-full">
  MODO DE TESTE ATIVO
</div>

<div
  *ngIf="enableDemoMode"
  class="hidden print:block fixed z-40 bg-red-500 text-center text-white w-full"
  style="top: 30%">
  MODO DE TESTE ATIVO
</div>

<router-outlet></router-outlet>
