import { WheelSymbolEnum } from '../../../../graphql/generated';

export class WheelSymbols {
  public static SYMBOLS: Array<WheelSymbolEnum> = [
    WheelSymbolEnum.Pix_2XA,
    WheelSymbolEnum.TicketGold_1XA,
    WheelSymbolEnum.LossA,
    WheelSymbolEnum.Pix_3XA,
    WheelSymbolEnum.TicketGold_2XA,
    WheelSymbolEnum.Pix_2XB,
    WheelSymbolEnum.TicketGold_2XB,
    WheelSymbolEnum.Pix_5XA,
    WheelSymbolEnum.TicketGold_1XB,
    WheelSymbolEnum.Pix_2XC,
    WheelSymbolEnum.TicketGold_3XA,
    WheelSymbolEnum.LossB,
  ];

  public static POSITION: Map<WheelSymbolEnum, number> = new Map();

  public static random(): WheelSymbolEnum {
    return WheelSymbols.SYMBOLS[
      Math.floor(Math.random() * WheelSymbols.SYMBOLS.length)
    ];
  }

  static position(r: WheelSymbolEnum): number {
    return WheelSymbols.POSITION.get(r) || 0;
  }
}

for (let i = 0; i < WheelSymbols.SYMBOLS.length; i++) {
  WheelSymbols.POSITION.set(WheelSymbols.SYMBOLS[i], i);
}
