import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepositStatusEnum } from '../../../../graphql/generated';

@Component({
  selector: 'app-deposit-status-label',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './deposit-status-label.component.html',
  styleUrls: ['./deposit-status-label.component.scss'],
})
export class DepositStatusLabelComponent implements OnInit {
  @Input({ required: true }) status: DepositStatusEnum;
  public badgeColorClass =
    'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300';

  ngOnInit(): void {
    switch (this.status) {
      case DepositStatusEnum.Completed:
        this.badgeColorClass =
          'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300';
        break;
      case DepositStatusEnum.Failed:
        this.badgeColorClass =
          'dark:bg-red-900 dark:text-red-300 bg-red-100 text-red-800 ';
        break;
      case DepositStatusEnum.WaitingConfirmation:
        this.badgeColorClass =
          'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300';
        break;
      case DepositStatusEnum.Processing:
        this.badgeColorClass =
          'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300';
        break;
      default:
      case DepositStatusEnum.Pending:
        this.badgeColorClass =
          'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300';
        break;
    }
  }
}
