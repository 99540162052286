import { map, Observable, timer } from 'rxjs';

import { SlotSymbolEnum, WheelSymbolEnum } from '../../../graphql/generated';
import { SlotResult } from './types';

const result00: SlotResult = {
  slot: [SlotSymbolEnum.Leaf, SlotSymbolEnum.Orange, SlotSymbolEnum.Banana],
  slotPix: 0,
  wheel: null,
  wheelPixMultiplier: 0,
  wheelTicket: 0,
  totalPix: 0,
  totalTicket: 0,
  totalDiamond: 0,
  id: 'DEMO-result00',
};

const result100_2x: SlotResult = {
  slot: [SlotSymbolEnum.Diamond, SlotSymbolEnum.Diamond, SlotSymbolEnum.Banana],
  slotPix: 100,
  wheel: WheelSymbolEnum.Pix_2XA,
  wheelPixMultiplier: 2,
  wheelTicket: 0,
  totalPix: 200,
  totalTicket: 0,
  totalDiamond: 0,
  id: 'DEMO-result100_2x',
};

const result10: SlotResult = {
  slot: [SlotSymbolEnum.Bar, SlotSymbolEnum.Bar, SlotSymbolEnum.Bar],
  slotPix: 10,
  wheel: WheelSymbolEnum.LossA,
  wheelPixMultiplier: 0,
  wheelTicket: 0,
  totalPix: 10,
  totalTicket: 0,
  totalDiamond: 0,
  id: 'DEMO-result10',
};

const result20_5t: SlotResult = {
  slot: [SlotSymbolEnum.Dollar, SlotSymbolEnum.Dollar, SlotSymbolEnum.Dollar],
  slotPix: 0.25,
  slotDiamond: 0,
  slotTicket: 0,
  wheel: WheelSymbolEnum.TicketGold_3XA,
  wheelPixMultiplier: 0,
  wheelTicket: 3,
  totalPix: 20,
  totalTicket: 3,
  totalDiamond: 0,
  id: 'DEMO-result20_5t',
};

const result_only_diamond: SlotResult = {
  slot: [SlotSymbolEnum.Diamond, SlotSymbolEnum.Dollar, SlotSymbolEnum.Orange],
  slotDiamond: 2,
  slotPix: 0,
  wheel: null,
  wheelPixMultiplier: 0,
  wheelTicket: 0,
  totalPix: 0,
  totalTicket: 0,
  totalDiamond: 2,
  id: 'DEMO-result_only_diamond',
};

const result_only_ticket: SlotResult = {
  slot: [SlotSymbolEnum.Seven, SlotSymbolEnum.Seven, SlotSymbolEnum.Seven],
  slotTicket: 1,
  slotPix: 0,
  wheel: null,
  wheelPixMultiplier: 0,
  wheelTicket: 0,
  totalPix: 0,
  totalTicket: 1,
  totalDiamond: 0,
  id: 'DEMO-result_only_ticket',
};

const result_complete: SlotResult = {
  slot: [SlotSymbolEnum.Cherry, SlotSymbolEnum.Cherry, SlotSymbolEnum.Cherry],
  slotTicket: 1,
  slotDiamond: 2,
  slotPix: 0,
  wheel: null,
  wheelPixMultiplier: 0,
  wheelTicket: 0,
  totalPix: 0,
  totalTicket: 1,
  totalDiamond: 2,
  id: 'DEMO-result_complete',
};

const resultMai: SlotResult = {
  message: 'Uhuuuu, você ganhou!',
  label: 'Pix R$ 20,00 + 1x',
  slot: [SlotSymbolEnum.Seven, SlotSymbolEnum.Seven, SlotSymbolEnum.Seven],
  slotPix: 1,
  wheel: null,
  totalPix: 1,
  totalDiamond: 0,
  totalTicket: 0,
  id: 'DEMO-resultMai',
} as any;

const resultLos02: SlotResult = {
  message: 'Não foi dessa vez, tente novamente!',
  slot: ['WATERMELON', 'ORANGE', 'SEVEN'],
  totalPix: 0,
  totalDiamond: 0,
  totalTicket: 0,
  id: 'DEMO-resultLos02',
} as any;

export const fakeResults = [
  result10,
  result_only_ticket,
  result_only_diamond,
  result_complete,
  result00,
  result100_2x,
  result20_5t,
  resultMai,
  resultLos02,
];

export const validateFakeResults = () => {
  fakeResults.forEach((result, index) => {
    result.totalPix = result.slotPix * (result.wheelPixMultiplier || 1);
    result.totalTicket = (result.slotTicket || 0) + (result.wheelTicket || 0);
    result.totalDiamond = result.slotDiamond || 0;

    if (result.slotPix > 0 && (result.slotTicket || result.slotDiamond)) {
      console.error(
        'Error: Fake SlotPix and SlotTicket or SlotDiamond are not exclusive',
        result,
        { index }
      );
    }
    if (
      !result.slotPix &&
      (result.wheel || result.wheelPixMultiplier || result.wheelTicket)
    ) {
      console.error(
        'Error: Fake Slot without SlotPix and Wheel or WheelPixMultiplier or WheelTicket',
        result,
        { index }
      );
    }

    if (result.wheel && !result.slotPix) {
      console.error('Error: Fake Wheel without SlotPix', result, { index });
    }
  });
};

export const spinSlotFakeRequest: () => Observable<SlotResult> = () =>
  timer(1000).pipe(
    map(() => fakeResults.pop()),
    map(result => {
      if (!result) {
        throw new Error('No more fake results');
      }
      return result;
    })
  );

export const spinSlotFakeWithErrorRequest: () => Observable<SlotResult> = () =>
  timer(1000).pipe(
    map(() => {
      throw new Error('Fake error request API');
    })
  );
