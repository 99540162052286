import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { SlotResult } from '../../play/types';
import { LoadingComponent } from '../loading/loading.component';
import { CoinIconComponent } from '../coin-icon/coin-icon.component';

@Component({
  selector: 'app-play-result-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    LoadingComponent,
    CoinIconComponent,
  ],
  templateUrl: './play-result-dialog.component.html',
  styleUrls: ['./play-result-dialog.component.scss'],
})
export class PlayResultDialogComponent implements OnInit {
  public totalTicket = 0;
  public totalDiamond = 0;

  public resultState: 'ERROR' | 'ONLY_PIX' | 'PIX_WITH_MULTIPLIER' = 'ONLY_PIX';

  constructor(
    public dialogRef: MatDialogRef<PlayResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public result: SlotResult
  ) {}

  ngOnInit(): void {
    if (!this.result) {
      this.resultState = 'ERROR';
      return;
    }

    this.totalDiamond = this.result.slotDiamond || 0;
    this.totalTicket =
      (this.result.slotTicket || 0) + (this.result.wheelTicket || 0);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
