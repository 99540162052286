import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApolloError } from '@apollo/client/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoadingComponent } from '../loading/loading.component';
import { CoinIconComponent } from '../coin-icon/coin-icon.component';
import { AppStateService } from '../../core/services/app-state.service';
import { ApiService } from '../../core/services/api.service';
import { SpinGift } from '../../../../graphql/generated';

@Component({
  selector: 'app-code-rescue-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    LoadingComponent,
    CoinIconComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './code-rescue-dialog.component.html',
  styleUrls: ['./code-rescue-dialog.component.scss'],
})
export class CodeRescueDialogComponent implements OnInit {
  public loading = false;
  public title = 'Resgatar código.';
  public state: 'LOADING' | 'SUCCESS' | 'ERROR' = 'LOADING';
  public giftMessage = '';
  public spinGift: SpinGift = {
    spins: 0,
    diamonds: 0,
  };

  constructor(
    private apiService: ApiService,
    private stateService: AppStateService,
    public dialogRef: MatDialogRef<CodeRescueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { code: string; cnpj: string }
  ) {}

  ngOnInit(): void {
    if (!this.data) {
      return;
    }

    this.state = 'LOADING';
    this.title = 'Aguarde, validando seu código.';

    this.apiService.rescueNsu(this.data.code, this.data.cnpj).subscribe({
      next: result => {
        console.log(result);
        if (result) {
          this.spinGift = result.gift;
          this.giftMessage = result.message;
          this.state = 'SUCCESS';
          this.stateService.incrementBalance({
            spins: result.gift.spins,
            diamonds: result.gift.diamonds,
          });
        } else {
          this.state = 'ERROR';
          this.giftMessage = 'Erro ao resgatar código. Tente novamente.';
        }
      },
      error: error => {
        this.state = 'ERROR';
        const aError = new ApolloError(error);
        const graphQLError = aError.graphQLErrors[0];
        const errorValidation = graphQLError?.extensions?.[
          'validation'
        ] as Array<any>;
        this.giftMessage =
          errorValidation?.[0]?.message ||
          graphQLError?.message ||
          error.message;
      },
    });
  }

  onNoClick(): void {
    if (this.loading) {
      return;
    }

    this.dialogRef.close();
  }
}
