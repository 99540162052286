import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static STORAGE_KEY_TOKEN = 'mdopix_access_token';
  static STORAGE_KEY_EXPIRES = 'mdopix_expires_in';

  constructor(
    private readonly apiService: ApiService,
    private _router: Router
  ) {}

  public signUp(cpf: string, phone: string): Observable<string | undefined> {
    return this.apiService
      .signUp(cpf, phone)
      .pipe(map(response => response?.session));
  }

  startSession(session: string): Observable<boolean> {
    return this.apiService.startSession(session).pipe(
      map(response => {
        if (response?.accessToken && response.expiresAt?.timestamp) {
          this.setToken(response.accessToken, response.expiresAt?.timestamp);
          return true;
        }

        return false;
      })
    );
  }

  setToken(token: string, expiresIn?: number) {
    localStorage.setItem(AuthService.STORAGE_KEY_TOKEN, token);

    localStorage.setItem(
      AuthService.STORAGE_KEY_EXPIRES,
      expiresIn?.toString() || ''
    );
  }

  logout() {
    localStorage.removeItem(AuthService.STORAGE_KEY_TOKEN);
    localStorage.removeItem(AuthService.STORAGE_KEY_EXPIRES);
    this._router.navigate(['/login']);
  }
}
