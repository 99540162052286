import { Subject, takeUntil } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';

import { TicketsTagComponent } from '../tickets-tag/tickets-tag.component';
import { AppStateService } from '../../core/services/app-state.service';
import { JackpotStatusEnum } from '../../../../graphql/generated';

import { InfoJackpotDialogComponent } from './info-jackpot-dialog/info-jackpot-dialog.component';

@Component({
  selector: 'app-section-jackpot',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    TicketsTagComponent,
    MatButtonModule,
    RouterLink,
  ],
  templateUrl: './section-jackpot.component.html',
  styleUrls: ['./section-jackpot.component.scss'],
})
export class SectionJackpotComponent implements OnInit, OnDestroy {
  public tickets = 0;
  public pageState: 'OPEN' | 'CLOSED' | 'NONE' = 'NONE';
  public amount: number | null = null;
  public startDate: Date | null = null;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private state: AppStateService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.state.currentJackpot$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(jackpot => {
        if (!jackpot) {
          this.pageState = 'NONE';
          return;
        }

        this.pageState =
          jackpot.status === JackpotStatusEnum.Rewarding ? 'OPEN' : 'CLOSED';

        this.amount = jackpot.amount;

        this.startDate = jackpot.endAt.iso ? new Date(jackpot.endAt.iso) : null;
      });

    this.state.balance$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(balance => {
        this.tickets = balance.tickets;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openInfoDialog() {
    this.dialog.open(InfoJackpotDialogComponent, {
      closeOnNavigation: true,
      disableClose: false,
      hasBackdrop: true,
      backdropClass: ['z-50'],
    });
  }
}
