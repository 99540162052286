import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { LogoComponent } from '../components/logo/logo.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { AuthService } from '../core/services/auth.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    CommonModule,
    LogoComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    RouterLink,
    MatProgressSpinnerModule,
    LoadingComponent,
    NgxMaskDirective,
    MatSnackBarModule,
  ],
  providers: [provideNgxMask()],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  public loading = false;
  public form: FormGroup;

  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      cpf: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
    });
  }

  signIn() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this._auth.signUp(this.form.value.cpf, this.form.value.phone).subscribe(
      sessionId => {
        this.loading = false;

        if (sessionId) {
          this._router.navigate(['/start'], {
            queryParams: { session: sessionId },
          });
        }
      },
      error => {
        this.loading = false;

        let message = error.message;

        const info = error?.graphQLErrors?.[0];
        if (
          info?.extensions?.code === 'validation_failed' &&
          info?.extensions?.validation?.length > 0
        ) {
          // TODO melhorar a exibição de erros
          info?.extensions?.validation?.forEach((item: any) => {
            console.log('Warning validation:', item);
            message += ' / ' + item.message;
          });
        }

        this._snackBar.open(message, '', {
          duration: 6000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    );
  }
}
