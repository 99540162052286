<div class="border-gold-dialog flex flex-col p-4">
  <div class="p-1 bg-transparent border border-solid border-white">
    <div class="bg-white">
      <div
        class="w-full slot-result-inner flex flex-col justify-center items-center">
        <span class="font-libel text-green-500 text-2xl mb-4">Parabéns!</span>
        <span class="text-black leading-4"
          >Você ganhou um <br />
          Pix no valor de:</span
        >
        <span class="font-libel text-4xl text-black">
          {{ amount | currency }}
        </span>

        <small
          class="secondary-text text-gray-600 text-xs text-center block mt-4 leading-4 pt-4">
          O Pix será enviado em até 48 horas.
        </small>
      </div>
    </div>
  </div>
</div>
