import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoadingComponent } from '../components/loading/loading.component';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    LoadingComponent,
    MatProgressSpinnerModule,
    MatButtonModule,
    RouterLink,
  ],
  templateUrl: './start-session.component.html',
  styleUrls: ['./start-session.component.scss'],
})
export class StartSessionComponent implements OnInit {
  public errorMessage = '';
  public loading = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    const session = this._activatedRoute.snapshot.queryParamMap.get('session');

    if (!session) {
      this._router.navigate(['/login']);
      return;
    }

    this._auth.startSession(session).subscribe(
      () => {
        this.loading = false;
        this._router.navigate(['/inicio']);
      },
      e => {
        this.loading = false;
        this.errorMessage = e.message;
      }
    );
  }
}
