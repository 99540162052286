import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  private audioContext: AudioContext;
  private audioBuffer: AudioBuffer;
  private audioSource: AudioBufferSourceNode;
  private gainNode: GainNode;

  constructor() {
    this.audioContext = new AudioContext();
    this.gainNode = this.audioContext.createGain();
  }

  async loadAudioBG() {
    await this.loadAudio('static/media/som-slot-bg.mpeg');
    this.setVolume(0.2);
  }

  async loadAudio(url: string) {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    this.audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
  }

  playSound() {
    if (this.audioSource) {
      return;
    }

    this.audioSource = this.audioContext.createBufferSource();
    this.audioSource.buffer = this.audioBuffer;
    this.audioSource.loop = true;
    this.audioSource.connect(this.gainNode);
    this.gainNode.connect(this.audioContext.destination);
    this.audioSource.start();
  }

  stopSound() {
    if (this.audioSource) {
      this.audioSource.stop();
      this.audioSource.disconnect();
    }
  }

  setVolume(volume: number) {
    this.gainNode.gain.value = volume;
  }
}
