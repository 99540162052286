import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-win-slot-pix-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './win-slot-pix-message.component.html',
  styleUrls: ['./win-slot-pix-message.component.scss'],
})
export class WinSlotPixMessageComponent {
  @Input({ required: true }) slotPix!: number;
}
