import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApplicationConfig, inject } from '@angular/core';
import {
  ApolloClientOptions,
  DefaultOptions,
  from,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

import { environment } from '../environments/environment';
import { AuthService } from './core/services/auth.service';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const customHeaders: Record<string, any> = {
  'Apollographql-Client-Name': environment.clientName,
  'Apollographql-Client-Version': environment.clientVersion,
  'Client-Platform': environment.clientPlatform,
  'X-Accept-Language': environment.language,
  'Time-Zone': environment.timeZone,
};

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AuthService.STORAGE_KEY_TOKEN);

  if (token) {
    customHeaders['Seller-Authorization'] = token;
  }

  return {
    headers: {
      ...headers,
      ...customHeaders,
    },
  };
});

export function apolloOptionsFactory(): ApolloClientOptions<any> {
  const httpLink = inject(HttpLink);
  const link = from([
    authLink,
    httpLink.create({ uri: environment.graphqlUrl }),
  ]);

  return {
    link: link,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  };
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
  },
];
