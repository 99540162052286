import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';
import { A11yModule } from '@angular/cdk/a11y';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-inform-cnpj-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    NgxMaskDirective,
    MatButtonModule,
    A11yModule,
    MatExpansionModule,
  ],
  templateUrl: './inform-cnpj-dialog.component.html',
  styleUrls: ['./inform-cnpj-dialog.component.scss'],
  providers: [provideNgxMask()],
})
export class InformCnpjDialogComponent {
  public cnpj: string;

  constructor(public dialogRef: MatDialogRef<InformCnpjDialogComponent>) {}
}
