import { Subject, takeUntil } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AppStateService } from '../../core/services/app-state.service';

@Component({
  selector: 'app-sound-settings',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './sound-settings.component.html',
})
export class SoundSettingsComponent implements OnInit, OnDestroy {
  public soundOn = true;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private readonly state: AppStateService) {}

  ngOnInit(): void {
    this.state.settings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(settings => {
        this.soundOn = settings.soundOn;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleSound() {
    this.soundOn = !this.soundOn;
    this.state.setSettings({ soundOn: this.soundOn });
  }
}
