import { Subject, takeUntil } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';

import { DiamondProgressBarComponent } from '../diamond-progress-bar/diamond-progress-bar.component';
import { AppStateService } from '../../core/services/app-state.service';
import { LogoComponent } from '../logo/logo.component';
import { CoinIconComponent } from '../coin-icon/coin-icon.component';
import {
  Account,
  Seller,
  SellerBalance,
  SellerStatusEnum,
} from '../../../../graphql/generated';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    CoinIconComponent,
    DiamondProgressBarComponent,
    LogoComponent,
    MatButtonModule,
    MatIconModule,
    RouterLink,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public seller: Seller | null = null;
  public account: Account | null = null;
  public balance: SellerBalance | null = null;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private stateService: AppStateService,
    private _router: Router,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    this.stateService.seller$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(seller => {
        this.seller = seller;
        if (seller?.status === SellerStatusEnum.Pending) {
          this._router.navigate(['/editar-perfil']);
        }
      });

    this.stateService.account$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(account => (this.account = account));

    this.stateService.balance$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(balance => (this.balance = balance));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout() {
    this.stateService.logout();
  }
}
