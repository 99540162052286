import { map, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppStateService } from '../services/app-state.service';

@Injectable({
  providedIn: 'root',
})
export class SellerLoadGuard implements CanActivate {
  constructor(private _state: AppStateService) {}

  canActivate(): Observable<boolean> {
    console.log('SellerLoadGuard...');

    this._state.loadSettings();

    const seller = this._state.sellerValue;
    if (seller) {
      console.log('SellerLoadGuard: @CACHED');
      // Se o estado já estiver carregado, retorne true imediatamente
      return of(true);
    }

    console.log('SellerLoadGuard: @FETCH');
    // Se o estado não estiver carregado, faça a consulta
    return this._state.loadData().pipe(map(() => true));
  }
}
