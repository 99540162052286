<!-- CANVAS START-->
<div
  id="canvasHolder"
  class="transition-all"
  style="transition: height 0.5s ease-in-out">
  <canvas
    id="gameCanvas"
    class="transition-all"
    style="transition: height 0.5s ease-in-out"></canvas>
</div>
<!-- CANVAS END-->
