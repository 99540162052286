import { WheelSymbolEnum } from '../../../../graphql/generated';
import { WheelControlType } from './wheel.type';
import { WheelSymbols } from './whell-symbols';

declare const wheelControl: WheelControlType;

export class WheelControl {
  init() {
    console.log('wheelControl.init()');
    wheelControl.initPreload();
  }

  initialPosition() {
    console.log('WheelControl.initialPosition()');
    wheelControl.stop();
  }

  showResult(r: WheelSymbolEnum | null) {
    console.log(`WheelControl.showResult(${r})`);
    if (!r) {
      return;
    }

    const pos = WheelSymbols.position(r);
    wheelControl.setResult(pos);
    wheelControl.startSpinWheel(true);
  }

  setOnComplete(handler: () => void) {
    console.log('WheelControl::setOnComplete()');
    wheelControl.onComplete = handler;
  }

  setSounds(active: boolean) {
    console.log(`WheelControl::setSounds(${active})`, active);
    wheelControl.toggleSounds(active);
  }
}
