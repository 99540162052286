import { Subject, takeUntil } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ToolbarComponent } from '../components/toolbar/toolbar.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { DepositStatusLabelComponent } from '../components/deposit-status-label/deposit-status-label.component';

import {
  AppDeposit,
  Seller,
  SellerStatusEnum,
} from '../../../graphql/generated';
import { ApiService } from '../core/services/api.service';
import { AppStateService } from '../core/services/app-state.service';
import { FormatPixKeyKindPipe } from '../pipes/format-pix-key-kind.pipe';

@Component({
  selector: 'app-extract',
  standalone: true,
  imports: [
    CommonModule,
    ToolbarComponent,
    ReactiveFormsModule,
    LoadingComponent,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    DepositStatusLabelComponent,
    FormatPixKeyKindPipe,
  ],
  templateUrl: './extract.component.html',
  styleUrls: ['./extract.component.scss'],
})
export class ExtractComponent implements OnInit, OnDestroy {
  public loading = false;
  public deposits: Array<AppDeposit> = [];
  public seller: Seller | null = null;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private stateService: AppStateService,
    private _router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.stateService.seller$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(seller => {
        this.seller = seller;
        if (seller?.status === SellerStatusEnum.Pending) {
          this._router.navigate(['/editar-perfil']);
        }
      });

    this._loadDeposits();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _loadDeposits() {
    this.loading = true;

    this.apiService.loadDeposits().subscribe({
      next: deps => {
        this.deposits = deps;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  public opendetailsDialog(deposit: AppDeposit) {
    if (deposit.receiptUri) {
      window.open(deposit.receiptUri, '_blank');
    }
  }
}
