import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SellerBalance } from '../../../../graphql/generated';
import { AppStateService } from '../../core/services/app-state.service';
import { InfoJackpotDialogComponent } from '../section-jackpot/info-jackpot-dialog/info-jackpot-dialog.component';

@Component({
  selector: 'app-diamond-progress-bar',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './diamond-progress-bar.component.html',
  styleUrls: ['./diamond-progress-bar.component.scss'],
})
export class DiamondProgressBarComponent implements OnInit, OnDestroy {
  public balance: SellerBalance = {
    diamonds: 0,
    spins: 0,
    tickets: 0,
  };
  public progressParts = new Array(5);

  private balanceSubscription: Subscription;

  constructor(
    private stateService: AppStateService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.balanceSubscription = this.stateService.balance$.subscribe(balance => {
      if (balance) {
        this.balance = balance;
      }
    });
  }

  ngOnDestroy(): void {
    this.balanceSubscription.unsubscribe();
  }

  openInfoDialog() {
    this.dialog.open(InfoJackpotDialogComponent, {
      closeOnNavigation: true,
      disableClose: false,
      hasBackdrop: true,
      backdropClass: ['z-50'],
    });
  }
}
