import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoinIconComponent } from '../coin-icon/coin-icon.component';

@Component({
  selector: 'app-chances-tag',
  standalone: true,
  imports: [CommonModule, CoinIconComponent],
  templateUrl: './chances-tag.component.html',
  styleUrls: ['./chances-tag.component.scss'],
})
export class ChancesTagComponent {
  @Input({ required: true }) chances!: number;
}
