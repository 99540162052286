import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-loss-slot-message',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './loss-slot-message.component.html',
  styleUrls: ['./loss-slot-message.component.scss'],
})
export class LossSlotMessageComponent {}
