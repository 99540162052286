import { Subject, takeUntil } from 'rxjs';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlotComponent } from '../components/slot/slot.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Event, NavigationStart, Router, RouterLink } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';
import { WheelSymbolEnum } from '../../../graphql/generated';
import { WheelComponent } from '../components/wheel/wheel.component';
import { SlotSymbols } from '../components/slot/slot-symbols';
import { SlotControl } from '../components/slot/slot-control';
import { WheelControl } from '../components/wheel/wheel.control';
import { SectionTermsAndConditionsComponent } from '../components/section-terms-and-conditions/section-terms-and-conditions.component';
import { PlayResultDialogComponent } from '../components/play-result-dialog/play-result-dialog.component';
import { LossSlotMessageComponent } from '../components/loss-slot-message/loss-slot-message.component';
import { WinSlotPixMessageComponent } from '../components/win-slot-pix-message/win-slot-pix-message.component';
import { UnavailableMessageComponent } from '../components/unavailable-message/unavailable-message.component';
import { ChancesTagComponent } from '../components/chances-tag/chances-tag.component';
import { SoundSettingsComponent } from '../components/sound-settings/sound-settings.component';
import { AppStateService } from '../core/services/app-state.service';
import { ApiService } from '../core/services/api.service';
import { spinSlotFakeRequest, validateFakeResults } from './fake-results';
import { SlotResult } from './types';
import { ToolbarComponent } from '../components/toolbar/toolbar.component';
import { A11yModule } from '@angular/cdk/a11y';

declare const confetti: any;

@Component({
  selector: 'app-play',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,

    RouterLink,
    SlotComponent,
    WheelComponent,
    SectionTermsAndConditionsComponent,
    LossSlotMessageComponent,
    WinSlotPixMessageComponent,
    UnavailableMessageComponent,
    ChancesTagComponent,
    SoundSettingsComponent,
    ToolbarComponent,
    A11yModule,
  ],
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss'],
})
export class PlayComponent implements OnInit, OnDestroy {
  public spins = 0;
  public result: SlotResult | null = null;
  public buttonPlayText: string | null = null;
  public showLossMessage = false;
  public pageState:
    | 'UNAVAILABLE'
    | 'READY'
    | 'LOADING'
    | 'SLOT'
    | 'WHEEL'
    | 'FINISH_RESULT' = 'READY';
  public lossMessage = '';

  private readonly unsubscribe$ = new Subject<void>();
  private readonly slotControl = new SlotControl();
  private readonly wheelControl = new WheelControl();

  @ViewChild('scrollBottom') private scrollBottom!: ElementRef;
  @ViewChild('scrollSlot') private scrollSlot!: ElementRef;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private stateService: AppStateService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this._setupGame();

    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: Event) => {
        if (
          event instanceof NavigationStart &&
          event.url !== '/slot' &&
          (this.pageState === 'SLOT' ||
            this.pageState === 'WHEEL' ||
            this.pageState === 'LOADING')
        ) {
          window.location.href = event.url;
        }
      });

    this.stateService.balance$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(balance => {
        // console.log('Update balance spins', balance.spins);
        this.spins = balance.spins;
      });

    this.stateService.settings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(settings => {
        this.slotControl.setSounds(settings.soundOn);
        this.wheelControl.setSounds(settings.soundOn);
      });

    if (this.spins > 0) {
      this._ready();
    } else {
      this.pageState = 'UNAVAILABLE';
    }

    if (environment.enableDemoMode) {
      this._devMode();
      // this._devAutoSpin();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  spin() {
    this.showLossMessage = false;
    this.buttonPlayText = 'CARREGANDO...';
    this.pageState = 'LOADING';
    this.lossMessage = '';

    this.apiService.spinSlot().subscribe({
      next: result => {
        this.buttonPlayText = 'JOGANDO...';
        this.pageState = 'SLOT';
        this.result = result;

        this.slotControl.spinSymbol(...result.slot);
        this.stateService.incrementBalance({
          tickets: result.totalTicket,
          diamonds: result.totalDiamond,
        });
      },
      error: e => {
        console.log('SPIN SLOT ERROR', e);
        this._ready();
        this.snackBar.open(e.message, '', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
      },
    });
  }

  private _setupGame(): void {
    this.slotControl.load();
    this.slotControl.setInitialPos();

    this.slotControl.setSounds(this.stateService.settingsValue.soundOn);
    this.wheelControl.setSounds(this.stateService.settingsValue.soundOn);

    this.slotControl.onInit = () => {
      try {
        confetti.stop();
        this.wheelControl.initialPosition();
      } catch (e) {
        console.log(
          'ERROR on this.confetti.stop or this.wheelControl.initialPosition',
          e
        );
      }

      this._decrementSpin();
    };

    this.slotControl.onCompleted = () => {
      // console.log('@ON COMPLETED', this.result);
      if (!this.result) {
        return;
      }

      if (this.result.wheel) {
        try {
          this._setWheelResult(this.result.wheel);
        } catch (e) {
          console.log('ERROR on this._setWheelResult', e);
        }

        return;
      }

      if (
        !this.result.slotPix &&
        !this.result.slotDiamond &&
        !this.result.slotTicket
      ) {
        this.showLossMessage = true;

        if (this.spins > 1) {
          this.lossMessage =
            'Que pena, não foi desta vez! Você ainda tem chances, tente outra vez!';
        } else if (this.spins === 1) {
          this.lossMessage =
            'Que pena, não foi desta vez! Você ainda tem uma chance. Boa Sorte!';
        } else {
          this.lossMessage = '';
        }

        this.pageState = 'FINISH_RESULT';
        this._okResult();
      } else {
        this._openPlayResult();
      }
    };

    this.wheelControl.setOnComplete(() => {
      this.pageState = 'FINISH_RESULT';
      confetti.stop();
      this._openPlayResult();
    });
  }

  private _ready() {
    this.buttonPlayText = 'JOGAR AGORA!';
    this.showLossMessage = false;
    this.pageState = 'READY';
  }

  /**
   * Permite simular o jackpot em modo de desenvolvimento para testar todos os cenários
   */
  private _devMode() {
    validateFakeResults();
    this.stateService.incrementBalance({ spins: 2 }); // Adiciona +2 bilhetes para teste
    this.apiService.spinSlot = spinSlotFakeRequest; // substitui a chamada real da API, por uma chamada fake
    // this.apiService.spinSlot = spinSlotFakeWithErrorRequest; // substitui a chamada real da API, por uma chamada fake com ERRO
  }

  /**
   * Apenas para desenvolvimento, simula o giro automático do slot, para testas as posições das imagens no slot
   */
  private _devAutoSpin() {
    setTimeout(() => {
      let next = 0;
      setInterval(() => {
        const nextSymbol = SlotSymbols.all()[next];
        console.log('@@@nextSymbol', nextSymbol);
        if (!nextSymbol) {
          console.log('@@@@@@@@@@@@@@@');
          next = 0;
          return;
        }

        next++;
        const r1 = SlotSymbols.position(nextSymbol);
        const r2 = SlotSymbols.position(nextSymbol);
        const r3 = SlotSymbols.position(nextSymbol);

        // const r3 = SlotSymbols.position(SlotSymbolEnum.BAR);
        this.slotControl.setFinalPos(r1, r2, r3);
      }, 2000);

      console.log('@@@@');
    }, 1000);
  }

  private _decrementSpin() {
    this.stateService.incrementBalance({ spins: -1 });
  }

  private _okResult() {
    if (this.spins < 1) {
      this.pageState = 'UNAVAILABLE';
      this._scrollTo('unavailable');
    } else {
      this._ready();
    }
  }

  private _openPlayResult() {
    const dialogRef = this.dialog.open(PlayResultDialogComponent, {
      closeOnNavigation: false,
      disableClose: true,
      hasBackdrop: true,
      panelClass: ['play-result-dialog2', 'no-padding'],
      data: this.result,
    });

    dialogRef.afterClosed().subscribe(() => {
      this._okResult();
      this._hiddenCanvas();
    });
  }

  private _scrollTo(id: string) {
    const wheelRoulette = document.getElementById(id);
    wheelRoulette?.scrollIntoView();
  }

  private _setWheelResult(wheel: WheelSymbolEnum) {
    this.showLossMessage = false;

    setTimeout(() => this._showCanvas(), 500);

    setTimeout(() => {
      this.pageState = 'WHEEL';
      this.wheelControl.showResult(wheel);
    }, 1500);

    confetti.start();
  }

  private _showCanvas() {
    const canvasBox = document.getElementById('sorte-turbinada');
    const canvasWheel = document.getElementById('canvasHolder');

    if (canvasBox) {
      canvasBox.style.visibility = 'visible';
      canvasBox.style.height = 'auto';
    }
    if (canvasWheel) {
      canvasWheel.style.visibility = 'visible';
    }

    this._scrollToBottom();
  }

  private _hiddenCanvas() {
    const canvasBox = document.getElementById('sorte-turbinada');
    const canvasWheel = document.getElementById('canvasHolder');

    if (canvasBox) {
      canvasBox.style.visibility = 'hidden';
      canvasBox.style.height = '0';
    }
    if (canvasWheel) {
      canvasWheel.style.visibility = 'hidden';
    }

    setTimeout(() => {
      if (this.scrollSlot) {
        this.scrollSlot.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  }

  private _scrollToBottom() {
    setTimeout(() => {
      if (this.scrollBottom) {
        this.scrollBottom.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  }
}
