import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-coin-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './coin-icon.component.html',
  styleUrls: ['./coin-icon.component.scss'],
})
export class CoinIconComponent {
  @Input({ required: false }) sizeClass!: string;
}
