<div *ngIf="state === 'LOADING'" mat-dialog-content>
  <div class="p-4">
    <div class="flex flex-col justify-center items-center">
      <div class="blue-2-border rounded-full p-2 hard-red-bg mt-2">
        <div class="w-40 h-40 rounded-full pos-bg"></div>
      </div>
      <mat-spinner
        diameter="182"
        style="margin-top: -182px"
        strokeWidth="5"></mat-spinner>
    </div>
    <p class="font-libel text-2xl text-center block mt-2">
      Aguarde, estamos validando seu código.
    </p>
  </div>
</div>

<div *ngIf="state === 'ERROR'" class="p-4">
  <div mat-dialog-content>
    <div class="flex flex-col justify-center items-center">
      <div class="blue-2-border rounded-full p-2 hard-red-bg mt-2 error">
        <div class="w-40 h-40 rounded-full pos-bg"></div>
      </div>
    </div>
    <p class="font-libel text-2xl text-center block mt-2 text-red-500">
      Ops, houve um erro!
    </p>
    <p class="text-lg leading-5 text-center block text-grey-600">
      {{ giftMessage }}
    </p>
  </div>
  <div mat-dialog-actions align="center">
    <button mat-stroked-button (click)="onNoClick()">Fechar</button>
  </div>
</div>

<div *ngIf="state === 'SUCCESS'" class="p-4">
  <div mat-dialog-content>
    <!--        <div class="flex flex-col justify-center items-center">-->
    <!--            <div class="blue-2-border rounded-full p-2 hard-red-bg mt-2 success">-->
    <!--                <div class="w-40 h-40 rounded-full pos-bg"></div>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="flex flex-col items-center justify-center">
      <span class="text-center font-libel text-green-600 text-2xl"
        >Parabéns!</span
      >

      <span class="text-center text-xl block text-grey-600">{{
        giftMessage
      }}</span>

      <div
        *ngIf="spinGift.spins > 0"
        class="flex flex-row items-center justify-center">
        <span class="text-lg font-bold mr-2">{{ spinGift.spins }}x</span>
        <app-coin-icon sizeClass="w-20"></app-coin-icon>
      </div>
      <div
        *ngIf="spinGift.diamonds > 0"
        class="flex flex-row items-center justify-center">
        <span class="text-lg font-bold mr-2">{{ spinGift.diamonds }}x</span>
        <img class="w-12" src="assets/img/diamond.png" alt="Diamonds" />
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="flex flex-col">
    <button mat-button (click)="onNoClick()">Jogar mais tarde</button>
    <button class="btn-play" mat-button mat-dialog-close="PLAY" cdkFocusInitial>
      JOGAR AGORA
    </button>
  </div>
</div>
