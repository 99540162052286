import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-winner-jackpot-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './winner-jackpot-message.component.html',
  styleUrls: ['./winner-jackpot-message.component.scss'],
})
export class WinnerJackpotMessageComponent {
  @Input() amount: number | null = null;
}
