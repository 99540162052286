import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { A11yModule } from '@angular/cdk/a11y';

@Component({
  selector: 'app-info-jackpot-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule,
    A11yModule,
  ],
  templateUrl: './info-jackpot-dialog.component.html',
  styleUrls: ['./info-jackpot-dialog.component.scss'],
})
export class InfoJackpotDialogComponent {
  constructor(public dialogRef: MatDialogRef<InfoJackpotDialogComponent>) {}
}
