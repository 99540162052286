<mat-toolbar class="fixed bottom-0 bottom-actions">
  <nav class="w-full">
    <ul class="flex flex-row justify-around">
      <li
        routerLink="/extrato"
        class="flex flex-col justify-center items-center">
        <mat-icon>receipt_long</mat-icon>
        <span>Extrato</span>
      </li>
      <li routerLink="/slot" class="flex flex-col justify-center items-center">
        <mat-icon>interests</mat-icon>
        <span>Jogar</span>
      </li>
      <li
        routerLink="/jackpot"
        class="flex flex-col justify-center items-center">
        <mat-icon>confirmation_number</mat-icon>
        <span>Jackpot</span>
      </li>
    </ul>
  </nav>
</mat-toolbar>
