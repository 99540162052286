import { Component, OnInit } from '@angular/core';
import { WheelControl } from './wheel.control';

@Component({
  selector: 'app-wheel',
  templateUrl: './wheel.component.html',
  styleUrls: ['./wheel.component.scss'],
  standalone: true,
})
export class WheelComponent implements OnInit {
  private readonly wheelControl = new WheelControl();

  ngOnInit(): void {
    this.wheelControl.init();
  }
}
