import { Subject, takeUntil } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppStateService } from '../../core/services/app-state.service';

@Component({
  selector: 'app-section-last-winner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './section-last-winner.component.html',
  styleUrls: ['./section-last-winner.component.scss'],
})
export class SectionLastWinnerComponent implements OnInit, OnDestroy {
  public win: {
    amount: number;
    name: string;
    account: string;
    date: Date | null;
  } | null = null;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private state: AppStateService) {}

  ngOnInit(): void {
    this.state.prevJackpot$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(jackpot => {
        if (!jackpot || !jackpot.winners?.length) {
          this.win = null;
          return;
        }

        const winner = jackpot.winners[0];
        this.win = {
          amount: winner.amount || 0,
          name: winner.seller?.displayName || '**',
          account: winner.seller?.account?.name || '**',
          date: jackpot.endAt?.iso ? new Date(jackpot.endAt.iso) : null,
        };
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
