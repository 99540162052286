import meta from '../../package.json';
import { Environment } from './env.type';

export const baseEnvironment: Environment = {
  envName: 'base',
  graphqlUrl: 'https://api.dev.mdopix.com.br/graphql',
  clientName: 'App MdoPix',
  clientVersion: `v${meta.version}`,
  clientPlatform: 'Web',
  language: 'pt-br',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  urlFerasVendas: 'https://ferasdevendasmdo.com.br',
  enableDemoMode: false,
};
