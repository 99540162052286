<div class="flex flex-row justify-end items-center z-10">
  <div
    class="flex flex-row items-center justify-center rounded-full border-2 pr-2 w-24 -mr-8 blue-2-border"
    style="border-color: rgba(44, 6, 4, 0.81); background-color: #f8df12">
    <span class="font-libel bold text-black text-xl mr-1">{{ chances }}</span>
    <span class="font-libel text-black text-xs mr-2">CHANCES</span>
  </div>
  <div
    class="p-1 rounded-full border-2 hard-red-bg blue-2-border"
    style="border-color: rgba(44, 6, 4, 0.81); background-color: #f8df12">
    <app-coin-icon sizeClass="w-8"></app-coin-icon>
  </div>
</div>
