<div class="slot-result border-gold">
  <div
    class="w-full p-1 slot-result-inner p-4 z-10 flex flex-col justify-center items-center">
    <span class="font-libel text-green-500 text-2xl">Parabéns!</span>
    <span>Você ganhou um Pix no valor de:</span>
    <span class="font-libel text-3xl text-green-500">{{
      slotPix | currency
    }}</span>
  </div>
</div>
