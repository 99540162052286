import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

import { AppStateService } from '../../core/services/app-state.service';
import { ApiService } from '../../core/services/api.service';
import { Seller, SellerStatusEnum } from '../../../../graphql/generated';
import { TermsAndConditionDialogComponent } from './terms-and-condition-dialog/terms-and-condition-dialog.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-section-terms-and-conditions',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule],
  templateUrl: './section-terms-and-conditions.component.html',
  styleUrls: ['./section-terms-and-conditions.component.scss'],
})
export class SectionTermsAndConditionsComponent implements OnInit, OnDestroy {
  public seller: Seller | null = null;
  private signatureVersion = 2;

  private sellerSubscription: Subscription;

  @Input({ required: false }) showSection!: boolean;

  constructor(
    private stateService: AppStateService,
    private api: ApiService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.sellerSubscription = this.stateService.seller$.subscribe(seller => {
      this.seller = seller;

      if (
        !seller ||
        seller.status !== SellerStatusEnum.Active ||
        seller.termSignature
      ) {
        return;
      }

      this.openTermAndConditionsDialog(false);
    });
  }

  ngOnDestroy() {
    this.sellerSubscription.unsubscribe();
  }

  public openTermAndConditionsDialog(hiddeCheckbox: boolean) {
    const dialogRef = this.dialog.open(TermsAndConditionDialogComponent, {
      closeOnNavigation: false,
      disableClose: true,
      hasBackdrop: true,
      backdropClass: [
        'fixed',
        'inset-0',
        'bg-aplication',
        'opacity-50',
        'z-50',
      ],
      panelClass: 'terms-and-condition-dialog',
      data: {
        hiddeCheckbox: hiddeCheckbox,
      },
    });

    dialogRef.afterClosed().subscribe(agree => {
      if (hiddeCheckbox) {
        return;
      }

      if (agree) {
        this.saveSellerAgree();
      } else {
        window.location.href = environment.urlFerasVendas;
      }
    });
  }

  private saveSellerAgree() {
    this.api.acceptTerms(this.signatureVersion).subscribe({
      next: resp => {
        this.stateService.setPartialSeller({
          termSignature: resp?.data.termSignature,
        });
      },
    });
  }
}
