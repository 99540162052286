<h1 mat-dialog-title class="text-green-600">Como funciona o Jackpot</h1>
<div mat-dialog-content>
  <div class="flex flex-col items-center px-4">
    <p class="text-gray-700 text-xs text-justify">
      Para participar do sorteio do Jackpot, você precisa acumular bilhetes
      dourados. Cada bilhete dourado dá direito a uma tentativa de ganhar o
      prêmio.
    </p>

    <p class="text-gray-700 text-xs text-justify mb-1" cdkTrapFocus>
      <span><b>Como ganhar bilhetes dourados:</b></span> <br />
      A cada código CV resgatado você ganha 1 diamante. A cada 5 diamantes
      acumulados, você recebe 1 bilhete dourado. Além disso, os bilhetes
      dourados também podem ser ganhos como prêmios no jogo Caça Pix.
    </p>
    <p class="text-gray-700 text-xs text-justify">
      <span><b>Sobre o sorteio:</b></span> <br />
      O Jackpot abre em uma data específica. A partir desse dia, você pode usar
      seus bilhetes dourados para participar. Acompanhe a data de abertura do
      Jackpot em nosso aplicativo. <br />
      O Jackpot terá um único vencedor, que ganhará o valor integral do Jackpot.
    </p>
  </div>

  <div class="flex justify-center items-center pt-2 flex-col">
    <button mat-button (click)="dialogRef.close()">Fechar</button>
  </div>
</div>
