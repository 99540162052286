<div class="flex flex-row items-center">
  <div class="-mr-2">
    <img class="w-12 z-20 relative" src="assets/img/jackpot.png" alt="Jacpot" />
  </div>
  <div class="gol-progress" (click)="openInfoDialog()">
    <div class="progress grid grid-cols-5 w-32">
      <div
        *ngFor="let item of progressParts; index as i"
        class="progress-item flex flex-row items-center justify-center"
        [ngClass]="{ active: i < balance.diamonds }">
        <div class="diamond">
          <img src="assets/img/diamond.png" alt="Diamonds" />
        </div>
      </div>
    </div>
  </div>

  <div class="counter flex flex-row justify-center items-center ml-1">
    <span class="text-xl font-bold">{{ balance.tickets }}</span>
    <div class="ticket">
      <img src="assets/img/ticket_mini.png" alt="Diamonds" />
    </div>
  </div>
</div>
