import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { SectionCodeRescueComponent } from '../components/section-code-rescue/section-code-rescue.component';
import { SectionHuntPixComponent } from '../components/section-hunt-pix/section-hunt-pix.component';
import { SectionJackpotComponent } from '../components/section-jackpot/section-jackpot.component';

import { HeaderComponent } from '../components/header/header.component';
import { BottomMenuComponent } from '../components/bottom-menu/bottom-menu.component';
import { SectionTermsAndConditionsComponent } from '../components/section-terms-and-conditions/section-terms-and-conditions.component';
import { SectionLastWinnerComponent } from '../components/section-last-winner/section-last-winner.component';
import { BgSoundComponent } from '../components/bg-sound/bg-sound.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,

    MatIconModule,
    MatButtonModule,

    SectionCodeRescueComponent,
    SectionHuntPixComponent,
    SectionJackpotComponent,
    SectionTermsAndConditionsComponent,
    HeaderComponent,
    BottomMenuComponent,
    SectionLastWinnerComponent,
    BgSoundComponent,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {}
