import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';

import { CodeRescueDialogComponent } from '../code-rescue-dialog/code-rescue-dialog.component';
import { InfoCvDialogComponent } from './info-cv-dialog/info-cv-dialog.component';
import { AppStateService } from '../../core/services/app-state.service';
import { Account } from '../../../../graphql/generated';
import { InformCnpjDialogComponent } from './inform-cnpj-dialog/inform-cnpj-dialog.component';

@Component({
  selector: 'app-section-code-rescue',
  standalone: true,
  templateUrl: './section-code-rescue.component.html',
  styleUrls: ['./section-code-rescue.component.scss'],
  imports: [
    CommonModule,

    MatInputModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSnackBarModule,

    CodeRescueDialogComponent,
    MatIconModule,
  ],
})
export class SectionCodeRescueComponent implements OnInit {
  public codeInput = new FormControl('');
  public form: FormGroup;
  public account: Account | null = null;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar,
    private stateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      codeInput: new FormControl(''),
    });

    this.stateService.account$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(account => (this.account = account));
  }

  checkSendCode() {
    console.log('SEND CODE: ', this.form.getRawValue().codeInput);
    const code = this.form.getRawValue().codeInput;

    if (!code) {
      this._snackBar.open('Informe o seu código CV.', 'OK', {
        duration: 3000,
      });
      return;
    }

    if (!this.account) {
      this.dialog
        .open(InformCnpjDialogComponent)
        .afterClosed()
        .subscribe({
          next: value => {
            if (value) {
              this._sendCode(code, value, true);
            } else {
              this.form.reset({ codeInput: '' });
            }
          },
        });
    } else {
      this._sendCode(code, this.account.cnpj, false);
    }
  }

  private _sendCode(code: string, cnpj: string, reloadSeller: boolean) {
    const dialogRef = this.dialog.open(CodeRescueDialogComponent, {
      closeOnNavigation: false,
      disableClose: true,
      hasBackdrop: true,
      backdropClass: ['z-50'],
      data: {
        code: code,
        cnpj: cnpj,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.form.reset({ codeInput: '' });

      if (reloadSeller) {
        this._reloadData();
      }

      if (result === 'PLAY') {
        this.router.navigate(['/slot']);
      }
    });
  }

  openInfoCvDialog() {
    this.dialog.open(InfoCvDialogComponent, {
      closeOnNavigation: true,
      disableClose: false,
      hasBackdrop: true,
      backdropClass: ['z-50'],
    });
  }

  private _reloadData() {
    this.stateService.loadData().pipe(takeUntil(this.unsubscribe$)).subscribe();
  }
}
